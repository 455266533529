<template>
    <div class="notify-area">
        <div class="notify-button" @click="showNotificacoes" v-b-toggle.sidebar-right-notificacoes>
            <i class="fa fa-bell-o qtde-notif-sino">
                <b-badge pill class="qtde-notif" v-if="qtdeNotificacoes > 0" variant="warning">{{qtdeNotificacoes}}</b-badge>
            </i>
        </div>

        <b-sidebar class="menu-not-lateral" id="sidebar-right-notificacoes" title="" width=300px right shadow backdrop z-index="100" v-model="showNotificacoes">
           
            <div class="px-3">                     
                
                <b-button-toolbar>  
                    <b-button-group class="mx-1"> 
                        <b-button @click="limparNotificacoes" variant="link" size="sm">Limprar</b-button>
                    </b-button-group>
                </b-button-toolbar>
                <hr>

                <b-row v-if="qtdeNotificacoes > 0" >
                    <b-col>                        
                        <b-list-group>
                            <b-list-group-item 
                                href="#" 
                                @click="verificarTipoNotificacao(c)"
                                v-for="(c, idNotificacao) in notificacoes" 
                                :key="idNotificacao"
                                class="mb-2"
                            > 
                                <div class="container-not-div">
                                    <i :style="`color: ${c.variante}`" class="fa fa-bell-o icone-notificacao"></i>  

                                    <div class="texto-div">
                                        <h5>
                                            <i v-if="c.tipo === 'NFSe'" :style="`color: ${c.variante}`" class="fa fa-file-code-o mr-3"></i>
                                            <i v-if="c.tipo === 'Bol'" style="color: red" class="fa fa-barcode mr-3"></i>
                                            <i v-if="c.tipo === 'ACEITE_BPO'" style="color: red" class="fa fa-pencil-square-o mr-3"></i>

                                            {{ c.titulo }}
                                        </h5>
                                        <p>{{c.mensagem}}</p>
                                        <small class="texto-div-datahora">{{c.dataHora}}</small>
                                        <small class="texto-div-datahora" @click="excluirNotificacao(c.idNotificacao)"> - <strong>EXCLUIR</strong></small>
                                    </div>
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </b-row>                
                
                <b-row v-else>
                    <b-col>
                        <span>Nenhum notificação no momento...</span>
                    </b-col>
                </b-row>          
                
            </div> 
            
        </b-sidebar>

        <b-modal id="modal-aceite-bpo" title="Autorização de Uso de Dados - LGPD e BPO Financeiro" v-model="mostrarModal">
            <div style="max-height: 300px; overflow-y: auto; padding: 10px; border: 1px solid #ddd; border-radius: 4px;">
                <h5>TERMO DE AUTORIZAÇÃO DE USO DE DADOS</h5>
                <br>
                <p>
                    Ao aceitar os termos e condições abaixo, você concorda com a coleta, armazenamento e uso 
                    dos seus dados pessoais e financeiros, incluindo faturas, notas fiscais e informações financeiras, 
                    pela empresa {{ notificacaoSelecionada.conteudo ? notificacaoSelecionada.conteudo.nomeEmpresaBpo : '' }}, 
                    inscrita no CNPJ de nº {{ notificacaoSelecionada.conteudo ? notificacaoSelecionada.conteudo.cnpj : '' }} 
                    denominada como a prestadora de serviços de BPO Financeiro, conforme estabelecido pela Lei Geral de 
                    Proteção de Dados (LGPD - Lei nº 13.709/2018).
                </p>
                <p>
                    <strong>1. Coleta de Dados:</strong> A empresa coletará dados necessários para a prestação dos serviços 
                    de gestão financeira, incluindo, mas não se limitando a, dados de faturas, notas fiscais, transações 
                    financeiras e outras informações relevantes.
                </p>
                <p>
                    <strong>2. Uso dos Dados:</strong> Os dados coletados serão utilizados exclusivamente para fins de gestão 
                    financeira da sua empresa, visando otimizar processos, reduzir custos e melhorar a eficiência operacional.
                </p>
                <p>
                    <strong>3. Compartilhamento de Dados:</strong> Os dados não serão compartilhados com terceiros sem sua 
                    autorização prévia, exceto quando necessário para o cumprimento de obrigações legais ou regulatórias.
                </p>
                <p>
                    <strong>4. Segurança dos Dados:</strong> A empresa adota medidas de segurança técnicas e organizacionais 
                    para proteger seus dados contra acesso não autorizado, alteração, divulgação ou destruição.
                </p>
                <p>
                    <strong>5. Direitos do Titular:</strong> Você tem o direito de acessar, corrigir, excluir ou limitar o uso dos 
                    seus dados pessoais, bem como o direito de revogar o consentimento a qualquer momento, conforme previsto pela LGPD.
                </p>
                <p>
                    <strong>6. Responsabilidades:</strong> A empresa se compromete a utilizar os dados de forma ética e responsável, 
                    garantindo a conformidade com todas as normas e regulamentações aplicáveis.
                </p>
                <p>
                    <strong>7. Alterações nos Termos:</strong> Reservamo-nos o direito de modificar estes termos e condições a 
                    qualquer momento, sendo que quaisquer alterações serão comunicadas previamente.
                </p>
                <p>
                    Ao clicar em "Aceitar", você declara estar ciente e concordar com os termos e condições acima, concedendo à 
                    empresa prestadora de serviços de BPO Financeiro o direito de acessar e utilizar os seus dados para os fins descritos.
                </p>
            </div>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="responderBPO('S')"                            
                    >
                        Aceitar
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="responderBPO('N')"
                    >
                        Rejeitar
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { baseApiUrl } from '@/global'
import axios from 'axios'
import { showError, showSuccess } from '../../global';
const moment = require('moment')

export default {
    name: 'Notifications',
    computed: mapState(['user']),
    data: function() {
        return {
            testes: '',
            retornosNotificados: [],
            notificacoes: [],
            qtdeNotificacoes: 0,
            showNotificacoes: false,
            mostrarModal: false,
            notificacaoSelecionada: {
                mensagem: ''
            }
        }
    },
    methods: {        
        verificarTipoNotificacao(c) {
            if (c.tipo === 'ACEITE_BPO') {
                this.notificacaoSelecionada = c;
                this.mostrarModal = true;
                this.showNotificacoes = false;
            }
        },

        responderBPO (resp) { 
            const n = this.notificacaoSelecionada.conteudo           
            const r = {
                resposta: resp,
                idEmpresaBPO: n.idBpo,
                idFilialCliente: n.idFilCliente
            }
            axios.post(`${baseApiUrl}/bpo/aceite`, r)
            .then(() => {
                showSuccess('Sua resposta foi salva com sucesso!')
                this.mostrarModal = false;
            }) 
            .catch(err => {
                showError(err)
            })
        },

        limparNotificacoes() {
            this.notificacoes = []
            this.qtdeNotificacoes = 0

            localStorage.setItem('__lancamentos_notificacoes', 
                JSON.stringify([])
            )
        },

        excluirNotificacao(id) {
            this.notificacoes = this.notificacoes.filter(
                (notificacao) => notificacao.idNotificacao !== id
            )
            
            this.qtdeNotificacoes = this.notificacoes.length
        },

        notificacoesNFSe() {
            const n = localStorage.getItem('__lancamentos_notificacoes') 
            this.notificacoes = n ? JSON.parse(n) : []
            this.qtdeNotificacoes = this.notificacoes.length

            setInterval(()=>{
                
                axios.get(`${baseApiUrl}/gerarnfse/verificar`, {
                    params: {
                        consultaIndividual: 'N'
                    }
                })
                .then(async res => {
                    if (res.data.length > 0) {
                        const notif = [ ...res.data ]
                        this.criarNotificacao(notif, 'NFSe', false)                        
                    }
                }) 
                .catch(err => {
                    this.criarNotificacao(err, 'NFSe', true)
                })

            }, (60 * 1000) * 1)

        },

        criarNotificacao(notify, tipo, e) {

            if (e) {
                this.notificacoes.unshift({
                    tipo: tipo,
                    variante: '#d6345d',
                    mensagem: notify,
                    dataHora: moment().format('DD/MM/yy HH:mm:ss')
                }) 

            } else {

                for (let item of notify) {
                    if (!this.notificacoes.some(e => e.idNotificacao === item.idNotificacao && e.statusAtual === item.statusAtual)) {  
                        item.dataHora= moment().format('DD/MM/yy HH:mm:ss') 
                        switch (item.tipo) {
                            case 'Bol':
                                item.titulo = 'Boleto'
                                break;
                            case 'NFSe':
                                item.titulo = 'Nota Fiscal (Serviço)'
                                break;
                            case 'ACEITE_BPO':
                                item.titulo = 'Aceite de BPO'
                                break;                        
                            default:
                                item.titulo = 'Geral'
                                break;
                        }  
                        this.notificacoes.unshift(item)  
                    }
                }
            }

            this.qtdeNotificacoes = this.notificacoes.length

            localStorage.setItem('__lancamentos_notificacoes', 
                JSON.stringify(this.notificacoes)
            )
        },

        executarRotinas() {
            setInterval(()=>{    
                // Verificar Status dos Boletos
                axios['get'](`${baseApiUrl}/gerarboleto/verificar`)

                // Buscar notificações
                this.buscarNotificacoesBD()

            }, (60 * 1000) * 1)

        },

        buscarNotificacoesBD() {
            axios.get(`${baseApiUrl}/consulta/notificacoes`)
            .then(async res => {
                if (res.data.length > 0) {
                    const notif = [ ...res.data ]
                    this.criarNotificacao(notif, 'GERAL', false)                        
                }
            }) 
            .catch(err => {
                this.criarNotificacao(err, 'GERAL', true)
            })
        },
    },
    mounted() {
        this.notificacoesNFSe(),     
        this.executarRotinas()       
    }
}
</script>

<style>
    .notify-area {
        align-self: initial;
        height: 100%;        
    }

    .notify-area:hover {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: pointer;
    }

    .notify-button {
        display: flex;
        font-size: 1.2rem;
        align-items: center;
        padding: 27px 20px;
    }  

    .qtde-notif {
        font-size: 0.7rem !important;
        margin-left: -5px !important;
    }

    .menu-not-lateral { 
        z-index: 200 !important;
        display: flex !important;
        position:  absolute !important;
        overflow-y: hidden !important;
    }    

    .b-sidebar-body {
        overflow-x: hidden !important;
    }

    .b-sidebar-body::-webkit-scrollbar {
        width: 4px; 
    }
    .b-sidebar-body::-webkit-scrollbar:hover {
        width: 10px; 
    }

    .b-sidebar-body::-webkit-scrollbar-track {
        background: transparent; 
        padding: 2px;
    }

    .b-sidebar-body::-webkit-scrollbar-thumb {
        background-color: #6ca7ff;
        border-radius: 10px;
    }

    .icone-notificacao {
        font-size: 5rem !important;
    }
   
    
    .container-not-div {
        margin: 0;
        position: relative;
    }

    .icone-notificacao {
        margin: -15px -25px 0 0 ;
        position: absolute;
        top: 0;
        right: 0;
        rotate: 40deg;
        color: #2a71db15 !important;
    }

    .container-not-div .texto-div {
        position: relative;
    }

    .texto-div-datahora {
        font-size: 0.7rem;
        color: #6e6e6e !important;
    }

    
</style>
