<template>
    <div class="cadastro-contratos">  

        <div class="acoesTopo">             
            <b-button variant="danger" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                @click="reset">
                    <i class="fa fa-times button-icons-acoes"></i>
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Excluir'"
                @click="desejaExcluirContratos">
                    <i class="fa fa-trash button-icons-acoes"></i>
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                @click="save">
                    <i class="fa fa-check button-icons-acoes"></i>
            </b-button>                        
            <b-button variant="success" class="botoesAcao" v-if="mode === 'new'" v-b-tooltip.hover.bottomleft="'Opções de Contratos'"
                @click="opcoesContratos">
                    <i class="fa fa-cogs button-icons-acoes"></i>
            </b-button>  
            <b-dropdown  v-if="mode === 'new'" class="mr-2" style="margin-top: -70px; height: 45px;" variant="primary" v-b-tooltip.hover.bottomleft="'Relatórios'">
                <template #button-content>
                    <i class="fa fa-file-text-o button-icons-acoes" style="margin: 0 10px 0 10px;"></i>
                </template>
                <b-dropdown-item href="#" @click="novoRelatorioComissao">Relatório de Comissões</b-dropdown-item>
            </b-dropdown>
            <b-dropdown  v-if="mode === 'new'" class="mr-2" style="margin-top: -70px; height: 45px;" variant="primary" v-b-tooltip.hover.bottomleft="'Novo'">
                <template #button-content>
                    <i class="fa fa-plus button-icons-acoes" style="margin: 0 10px 0 10px;"></i>
                </template>
                <b-dropdown-item href="#" @click="novoContrato">Novo Contrato</b-dropdown-item>
                <b-dropdown-item href="#" @click="novoGrupoContrato">Novo Grupo de Contrato</b-dropdown-item>
            </b-dropdown>
        </div>

        

        <div class="containerRoll" v-if="show">
            <b-card class="cadastros-card-pesquisa sticky-card-cont">
                <b-row >
                    <b-col md="4" sm="12" class="radio-consulta">
                        <b-form-group label-for="localizar">
                            <p class="entre-datas">Nome, Nº Contrato, CPF ou CNPJ</p> 
                            <b-form-input id="localizar" type="search"
                                size="sm"
                                :formatter="upperCase"
                                v-model="consulta.parcial"
                                style="height: 35px !important;"
                                placeholder="Informe Nome, Nº Contrato, CPF ou CNPJ"
                            />
                        </b-form-group> 

                        <b-form-group label-for="conta-filial-sel">
                            <p class="entre-datas">Filial</p> 
                            <b-form-select size="sm" id="conta-filial-sel" v-model="consulta.filial" :options="filiais"/>
                        </b-form-group>                                        

                    </b-col>  

                    <b-col md="3" sm="12" class="radio-consulta">
                        <b-form-group>
                            <p class="entre-datas">Competência</p> 
                            <datepicker
                                style="width: 100%;"
                                v-model="intervaloComp"
                                type="date"
                                range 
                                format="DD/MM/YYYY"   
                                placeholder="Selecionar intervalo de datas"
                            ></datepicker>
                        </b-form-group>

                        <b-form-group label-for="conta-filial-sel">
                            <p class="entre-datas">Receita</p> 
                            <b-form-select size="sm" id="conta-filial-sel" v-model="consulta.idPlanoConta" :options="planoContas"/>
                        </b-form-group> 
                    </b-col>

                    <b-col md="3" sm="12" class="radio-consulta">
                        <b-form-group>
                            <p class="entre-datas">Encerramento</p> 
                            <datepicker
                                style="width: 100%;"
                                v-model="intervaloVenc"
                                type="date"
                                range 
                                format="DD/MM/YYYY"   
                                placeholder="Selecionar intervalo de datas"
                            ></datepicker>
                        </b-form-group>

                        <b-form-checkbox-group
                            style="margin-top: 5px"
                            id="checkbox-status"
                            v-model="consulta.status"
                            :options="consulta.opcoes"
                            name="chk-status"
                        ></b-form-checkbox-group>  
                    </b-col>

                    <b-col md="2" sm="12" class="radio-consulta d-flex justify-content-end">
                        <b-button 
                            class="botao-consulta" 
                            variant="outline-info" 
                            size="lg"
                            @click="novaConsulta"
                        >
                            <i class="fa fa-search button-icons"></i>
                        </b-button>
                    </b-col>               

                </b-row>

            </b-card>
            <b-overlay :show="showOverContratos" rounded="sm" spinner-small>
                <b-table 
                    v-show="show"
                    hover 
                    striped 
                    :items="contratos" 
                    :fields="fields"
                    sort-icon-left
                    :select-mode="'multi'"
                    @row-selected="onRowSelected"                                
                    ref="selectableTableContratos"                            
                    selectable
                    responsive
                    small
                    style="min-height: 300px"
                >  
                    <template #head(selected) v-if="contratos.length > 0">                                    
                        <template v-if="contratos.length === selecteds.length">
                            <div style="margin-bottom: -5px">
                                <span  aria-hidden="true" @click="clearSelected"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check-square-o"></i></span>
                            </div>
                        </template>
                        <template v-if="contratos.length !== selecteds.length"> 
                            <div style="margin-bottom: -5px">
                                <span aria-hidden="true" @click="selectAllRows"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-square-o"></i></span>
                            </div>
                        </template>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true"><i style="font-size: 25px; color: #5d5cfc" class="fa fa-check"></i></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                        </template>
                    </template>

                    <template v-slot:cell(status)="data">
                        <div style="font-size: 1.2rem" class="d-flex justify-content-end">
                            <b-badge class="ml-2" v-if="data.item.status == 'A'" variant="success" v-b-tooltip.hover.right="'Aberto'">{{data.item.status}}</b-badge>
                            <b-badge class="ml-2" v-if="data.item.status == 'F'" variant="warning" v-b-tooltip.hover.right="'Faturado'">{{data.item.status}}</b-badge>
                            <b-badge class="ml-2" v-if="data.item.status == 'E'" variant="secondary" v-b-tooltip.hover.right="'Encerrado'">{{data.item.status}}</b-badge>
                            <b-badge class="ml-2" v-if="data.item.status == 'C'" variant="danger" v-b-tooltip.hover.right="'Cancelado'">{{data.item.status}}</b-badge>
                        </div>
                    </template>

                    <template v-slot:cell(encerramento)="data">
                        <div>
                            {{data.item.encerramento}}
                            <b-badge class="ml-1" pill variant="secondary" v-if="data.item.restante > 30">{{data.item.restante}} {{data.item.diaDias}}</b-badge>
                            <b-badge class="ml-1" pill variant="warning" v-if="data.item.restante <= 30 && data.item.restante >= 7">{{data.item.restante}} {{data.item.diaDias}}</b-badge>
                            <b-badge class="ml-1" pill variant="danger" v-if="data.item.restante < 7">{{data.item.restante}} {{data.item.diaDias}}</b-badge>
                        </div>
                    </template>

                    <template v-slot:cell(actions)="data">
                        <div  class="d-flex justify-content-end">
                            <b-button-toolbar>
                                <b-dropdown right class="mx-1" size="sm">                       
                                    <b-dropdown-item href="#" @click="loadContrato(data.item)"><i class="fa fa-pencil mr-1"></i> Editar</b-dropdown-item>
                                    <b-dropdown-item v-if="data.item.e_aditivo !== 'S'" href="#" @click="aditivoContrato(data.item)"><i class="fa fa-plus-circle mr-1"></i> Novo Aditivo</b-dropdown-item>
                                    <b-dropdown-item href="#" @click="loadModelos(data.item)"><i class="fa fa-print mr-1"></i> Imprimir</b-dropdown-item>                           
                                    <hr>
                                    <p style="font-size: 0.9rem; margin: -10px 0 0 15px">Alterar para:</p>
                                    <b-dropdown-item href="#" @click="alerarStatus(data.item, 'A')"><div style="color: #5dc289">Ativo</div></b-dropdown-item>
                                    <b-dropdown-item href="#" @click="alerarStatus(data.item, 'F')"><div style="color: #ffcc30">Faturado</div></b-dropdown-item>
                                    <b-dropdown-item href="#" @click="alerarStatus(data.item, 'C')"><div style="color: #f93b69">Cancelado</div></b-dropdown-item>
                                </b-dropdown>
                            </b-button-toolbar>                   
                        </div>

                    </template>
                </b-table>
                <!-- <hr>
                <b-pagination v-show="show" align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination> -->
            </b-overlay>
        </div>  
        
        <b-overlay :show="showOver" rounded="sm">
            <b-tabs content-class="mt-3" v-if="!show">
                <div class="div-topo-b"></div>
                <b-tab class="pl-4 pr-4" title="Contrato" active>
                    <b-form>
                        <input id="contratos-id" type="hidden" v-model="contrato.id_servico" />

                        <b-row> 
                            <b-col md="1" sm="12" v-if="contrato.num_contrato">
                                <b-form-group label="Nº" label-for="num-contrato"> 
                                    <p style="font-size: 1.2rem"><strong>{{contrato.num_contrato}}-{{contrato.sequencia}}</strong></p>
                                </b-form-group>
                            </b-col> 

                            <b-col md="2" sm="12">
                                <b-form-group label="Tipo" label-for="num-tipo"> 
                                    <p style="font-size: 1.2rem"><strong>{{contrato.e_aditivo === 'S' ? 'ADITIVO' : 'CONTRATO'}}</strong></p>
                                </b-form-group>
                            </b-col> 
                                                    
                            <b-col md="2" sm="12">
                                <b-form-group label="Data" label-for="data-contrato">
                                    <b-form-input id="data-contrato" type="date"
                                        v-model="contrato.data"
                                        :readonly="mode === 'remove'" required/>
                                </b-form-group>
                            </b-col> 

                            <b-col md="4" sm="12"> 
                                <b-form-group label="Pessoa" label-for="contrato-pessoa">
                                    <vue-single-select
                                        id="contrato-pessoa"
                                        placeholder="Procurar"
                                        v-model="pessoaSelecionada"
                                        :options="pessoas"
                                        option-key="value"
                                        option-label="text"
                                    ></vue-single-select>
                                </b-form-group>
                            </b-col>      
                            <b-col md="3" sm="12"> 
                                <b-form-group label="Grupo Contrato" label-for="contrato-grupo">
                                    <vue-single-select
                                        id="contrato-grupo"
                                        placeholder="Procurar"
                                        v-model="grupoSelecionado"
                                        :options="gruposContratos"
                                        option-key="value"
                                        option-label="text"
                                    ></vue-single-select>
                                </b-form-group>
                            </b-col>      
                        </b-row>

                        <b-tabs content-class="mt-3" small>
                            <b-tab title="Negociação" active>   
                                <b-row> 
                                    <b-col md="4" sm="12">
                                        <b-form-group label="Plano de Contas" label-for="contrato-plano-conta">
                                            <vue-single-select
                                                id="contrato-plano-conta"
                                                placeholder="Procurar"
                                                v-model="planoSelecionado"
                                                :options="planoContas"
                                                option-key="value"
                                                option-label="text"
                                                :get-option-description="label"
                                                :get-option-value="value"
                                            ></vue-single-select>
                                        </b-form-group>
                                    </b-col>  

                                    <b-col md="3" sm="12">
                                        <b-form-group label="Forma" label-for="contrato-forma">
                                            <vue-single-select
                                                id="contrato-forma"
                                                placeholder="Procurar"
                                                v-model="formaSelecionada"
                                                :options="formas"
                                                option-key="value"
                                                option-label="text"
                                                :get-option-description="label"
                                                :get-option-value="value"
                                            ></vue-single-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="2" sm="12">
                                        <b-form-group label="Periodicidade" label-for="periodicidade-contrato"> 
                                            <b-form-input id="periodicidade-contrato" type="number"
                                                v-model="contrato.periodicidade"
                                                :readonly="mode === 'remove'" required/>
                                        </b-form-group>
                                    </b-col>                             
                                </b-row>

                                <b-row>
                                    <b-col md="3" sm="12">
                                        <b-form-group label="Primeiro Vencimento" label-for="pri-vencimento-contrato">
                                            <b-form-input id="pri-venc-contrato" type="date"
                                                v-model="contrato.primeiro_vencimento"
                                                :readonly="mode === 'remove'" required/>
                                        </b-form-group>
                                    </b-col> 
                                    
                                    <b-col md="3" sm="12">
                                        <b-form-group label="Encerramento" label-for="encerramento-contrato">
                                            <b-form-input id="encerramento-contrato" type="date"
                                                v-model="contrato.encerramento"
                                                :readonly="mode === 'remove'" required/>
                                        </b-form-group>
                                    </b-col> 
                                                            
                                    <b-col md="3" sm="12">
                                        <b-form-group label="Qtde. Parcelas" label-for="q-parcelas-contrato"> 
                                            <b-form-input id="q-parcelas-contrato" type="number"
                                                v-model="contrato.qtde_parcelas"
                                                :readonly="mode === 'remove'" required/>
                                        </b-form-group>
                                    </b-col> 

                                    <b-col md="3" sm="12">
                                        <b-form-group label="Desc. até o Vencto" label-for="desc-ate-venc-contrato">
                                            <money id="desc-ate-venc-contrato" v-model="contrato.desc_ate_vencto" v-bind="percent" required class="form-control"/>                                        
                                        </b-form-group>
                                    </b-col>
                                </b-row> 
                            </b-tab>                 
                        </b-tabs>
                        <br> 

                        <b-tabs content-class="mt-3" small>
                            <b-tab title="Detalhes" active> 
                                <b-row>  
                                    <b-col md="4" sm="12" lg="4"> 
                                        <b-form-group
                                        label="Observações"
                                        label-for="contrato-obs"
                                        class="mb-0"
                                        >
                                            <b-form-textarea
                                                id="contrato-obs"
                                                v-model="contrato.obs"
                                            ></b-form-textarea>
                                        </b-form-group> 
                                    </b-col>
                                    <b-col md="4" sm="12" lg="4"> 
                                        <b-form-group
                                        label="Instruções"
                                        label-for="contrato-instrucoes"
                                        class="mb-0"
                                        >
                                            <b-form-textarea
                                                id="contrato-instrucoes"
                                                v-model="contrato.instrucoes"
                                            ></b-form-textarea>
                                        </b-form-group> 
                                    </b-col>                
                                
                                    <b-col md="4" sm="12" lg="4">  
                                        <b-form-group
                                        label="Observações Fiscais"
                                        label-for="contrato-obs-fiscais"
                                        class="mb-0"
                                        >
                                            <b-form-textarea
                                                id="contrato-obs-fiscais"
                                                v-model="contrato.obs_fiscais"
                                            ></b-form-textarea>
                                        </b-form-group> 
                                    </b-col>                
                                </b-row> 
                                
                                <b-row>
                                    <b-col md="12" sm="12" class="d-flex">
                                        <b-form-checkbox 
                                            value="S" 
                                            unchecked-value="N" 
                                            id="contrato-boleto" 
                                            checked 
                                            v-show="mode === 'save'" 
                                            v-model="contrato.e_cobranca_boleto" 
                                            class="mr-3" 
                                            switch
                                        >
                                            Cobrança via boleto
                                        </b-form-checkbox>

                                        <b-form-checkbox 
                                            value="S" 
                                            unchecked-value="N" 
                                            id="contrato-ult-dia" 
                                            checked 
                                            v-show="mode === 'save'" 
                                            v-model="contrato.e_vencto_ultimo_dia_mes" 
                                            class="mr-3" 
                                            switch
                                        >
                                            Vencto. último dia do mês
                                        </b-form-checkbox>

                                        <b-form-checkbox 
                                            value="S" 
                                            unchecked-value="N" 
                                            id="contrato-prepago" 
                                            checked 
                                            v-show="mode === 'save'" 
                                            v-model="contrato.e_prepago" 
                                            class="mr-3" 
                                            switch
                                        >
                                            Pré-pago
                                        </b-form-checkbox>

                                        <b-form-checkbox 
                                            value="S" 
                                            unchecked-value="N" 
                                            id="contrato-fat-auto" 
                                            checked 
                                            v-show="mode === 'save'" 
                                            v-model="contrato.e_auto_faturamento" 
                                            class="mr-3" 
                                            switch
                                        >
                                            Faturamento Automático
                                        </b-form-checkbox>

                                    </b-col> 
                                </b-row>                             
                                <b-row v-if="contrato.e_auto_faturamento === 'S'">
                                    <b-col md="6" sm="12">
                                        <b-card>
                                            <b-form-group label="NFS-e Automática?">
                                                <b-form-radio-group
                                                    id="radio-auto-nfse"
                                                    v-model="contrato.e_auto_nf"
                                                    :options="optionsAutoNFSe"
                                                    name="radio-options-nfse"
                                                ></b-form-radio-group>
                                            </b-form-group>
                                        </b-card>
                                    </b-col> 

                                    <b-col md="6" sm="12" >
                                        <b-card>
                                            <b-form-group label="Boleto Automático?">
                                                <b-form-radio-group
                                                    id="radio-auto-bole"
                                                    v-model="contrato.e_auto_boleto"
                                                    :options="optionsAutoBol"
                                                    name="radio-options-bol"
                                                ></b-form-radio-group>
                                            </b-form-group>

                                            <b-form-group v-if="contrato.e_auto_boleto === 'PV'" label="Qtde de dias" label-for="q-dias-antes"> 
                                                <b-form-input id="q-dias-antes" type="number" v-model="contrato.emitir_bol_dias" />
                                            </b-form-group>
                                        </b-card>
                                    </b-col> 
                                </b-row>                             
                            </b-tab>
                        </b-tabs>
                        <br>
                        <br>

                        <b-row class="cadastros-opcoes-row pl-3" align-h="around">                            
                            <b-col md="12" sm="12">
                                <h5><strong>Serviços</strong> 
                                    <b-button class="ml-2" size="sm" variant="outline-primary" @click="addServico">
                                        <i class="fa fa-plus mr-1"></i> Adicionar
                                    </b-button>
                                </h5>
                                
                                <b-table v-if="contrato.itens.length > 0" class="mt-2" hover striped :items="contrato.itens" :fields="fieldsContratoItens" borderless small>  
                                    <template v-slot:cell(actions)="data">
                                        <b-button size="sm" variant="warning" @click="loadServico(data.item)" class="mr-2">
                                            <i class="fa fa-pencil"></i>
                                        </b-button>
                                        <b-button size="sm" variant="danger" @click="desejaRemover(data.item)">
                                            <i class="fa fa-trash"></i>
                                        </b-button>
                                    </template>
                                </b-table>
                                <h6 v-else>Nenhum serviço adicionado...</h6>
                            </b-col>                          
                        </b-row>

                        <br>
                        <b-row>
                            <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                                <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                                <b-popover target="popover-target-1" triggers="hover" placement="top">
                                    <template #title>Alterações</template>
                                    <b>Usuário Cadastro:</b> {{contrato.usu_cad}} <br>
                                    <b>Data de Cadastro:</b> {{contrato.data_cad}} <br>
                                    <b>Usuário Alteração:</b> {{contrato.usu_alt}} <br>
                                    <b>Data de Alteração:</b> {{contrato.data_alt}}
                                </b-popover>                        
                            </b-col>

                        </b-row>

                    </b-form>
                </b-tab>

                <b-tab class="pl-4 pr-4" title="Comissionamento">
                    <b-row>
                        <b-col md="4" sm="12">
                            <b-form-group label="Valor Base do Contrato" label-for="conta-bancaria">
                                <money                                     
                                    v-model="contrato.valor_base_contrato" 
                                    v-bind="moneyItens" 
                                    class="form-control"
                                    style="font-size: 1.5rem"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-group label="Valor Base das Comissões" label-for="conta-bancaria">                                
                                <b-overlay :show="showOverBaseComiss" rounded="sm" spinner-small>
                                    <template #overlay>
                                        <div class="d-flex align-items-center">
                                            <b-spinner class="mr-2" variant="primary" small label="Spinning"></b-spinner>
                                            Calculando...
                                        </div>
                                    </template>
                                    
                                    <money
                                        v-model="contrato.valor_base_comissao"
                                        v-bind="moneyItens"
                                        class="form-control"
                                        :disabled="contrato.e_base_descontado_impostos !== 'S'"
                                        style="font-size: 1.5rem"
                                    />
                                </b-overlay>
                            </b-form-group>

                            <b-form-checkbox 
                                value="S" 
                                unchecked-value="N" 
                                id="pessoa-ativo" 
                                checked 
                                v-show="mode === 'save'" 
                                v-model="contrato.e_base_descontado_impostos" 
                                class="mt-3 mb-3" 
                                switch
                            > Descontar impostos</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="8" sm="12">
                            <b-row class="cadastros-opcoes-row pl-3" align-h="around">                            
                                <b-col md="12" sm="12">
                                    <h5><strong>Comissões</strong> 
                                        <b-button class="ml-2" size="sm" variant="outline-primary" @click="addComissao">
                                            <i class="fa fa-plus mr-1"></i> Adicionar
                                        </b-button>
                                    </h5>
                                    
                                    <b-table v-if="contrato.comissoes.length > 0" class="mt-2" hover striped :items="contrato.comissoes" :fields="fieldsContratoComissoes" borderless small>  
                                        <template v-slot:cell(actions)="data">
                                            <div style="max-width: 52px; display: flex">
                                                <b-button size="sm" variant="warning" @click="loadComissao(data.item)" class="mr-2">
                                                    <i class="fa fa-pencil"></i>
                                                </b-button>
                                                <b-button size="sm" variant="danger" @click="desejaRemoverComissao(data.item)">
                                                    <i class="fa fa-trash"></i>
                                                </b-button>
                                            </div>
                                        </template>
                                        <template v-slot:cell(taxa_valor_comissao)="data">
                                            {{
                                                data.item.taxa_comissao > 0 ? data.item.taxa_comissao.toLocaleString('pt-br', {maximumFractionDigits: 2})+'%' : '--'
                                            }}
                                        </template>
                                    </b-table>
                                    <h6 v-else>Nenhuma comissão adicionada...</h6>
                                </b-col>                          
                            </b-row>
                        </b-col>
                    </b-row> 
                </b-tab>
            </b-tabs>
        </b-overlay>   

        <b-card v-show="!show" style="margin-top: 10px">
            <div class="acoesRodape">  
                <b-button variant="danger" class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" v-b-tooltip.hover.bottomleft="'Cancelar'"
                    @click="reset">
                        <i class="fa fa-times button-icons-acoes"></i>
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" v-b-tooltip.hover.bottomleft="'Salvar'"
                    @click="save">
                        <i class="fa fa-check button-icons-acoes"></i>
                </b-button> 
            </div>
        </b-card>

        <b-modal id="modal-cancela-contrato" title="Excluir Contratos" v-model="modalExcluirContrato"  size="lg" >
            <p >
                Deseja realmente <strong>Excluir</strong> os contratos selecionados? <br>
                <strong>Esta ação não pode ser desfeita!</strong><br>
            </p>

            <b-table style="height: 200px" :items="contratosSelecExcluir" :fields="fieldsExcluir" responsive striped small hover borderless sort-icon-left></b-table> 

            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="excluiContratos()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-cancela-contrato')"
                    >
                        Não
                    </b-button>
                </div>
            </template>
            <p>*Contratos faturados não serão excluídos</p>
        </b-modal> 

        <b-modal size="lg" id="modal-imprime-contrato" title="Imprimir Contrato" v-model="modalImprimeContrato">
            <b-row>
                <b-col md="12" sm="12">
                    <b-input-group                    
                        class="mb-3"
                        prepend="Modelo"
                    >
                        <b-form-select id="modelo-impresso" v-model="idmodelo" :options="modelos" />
                        <b-input-group-append>
                            <b-button 
                                pill 
                                class="ml-1"
                                size="sm"
                                variant="outline-secondary"
                                @click="editaModelo = !editaModelo"
                            >
                                <i v-if="!editaModelo" class="fa fa-pencil button-icons"></i>
                                <i v-if="editaModelo" class="fa fa-floppy-o button-icons"></i> 
                                {{editaModelo ? 'Salvar' : 'Editar'}}
                            </b-button>

                            <b-button 
                                pill 
                                class="ml-1"
                                size="sm"
                                variant="outline-secondary"
                                :disabled="editaModelo" 
                                @click="salvarPdf()"
                            >
                                <i class="fa fa-print button-icons"></i> Imprimir
                            </b-button>

                            <b-button 
                                pill 
                                class="ml-1"
                                size="sm"
                                variant="outline-secondary"
                                @click="exibirEmail(emailContrato)"
                            >
                                <i class="fa fa-envelope-o"></i> E-mail
                            </b-button>

                        </b-input-group-append>
                    </b-input-group>
                </b-col>

            </b-row>
            <hr>
            
            <div v-if="!editaModelo" id="renderPDF" v-html="impresso" class="ql-editor"></div>
            
            <div>
                <!-- <VueEditor v-if="editaModelo" id="editor-este"
                    v-model="impresso" 
                    
                /> -->

                <Editor
                    id="editorTiny"
                    v-if="editaModelo"
                    v-model="impresso"
                    api-key="u92kao7z36g1ljp9rh3pzuxs5p659aefwl916zb0kreuvlnr"
                    :init="editorConfig"
                />
            </div>
            
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>  

        <b-modal size="lg" id="modal-rel_comissao" title="Relatório de Comissões" v-model="relComissao.showModal">
            <b-row>
                <b-col md="12" sm="12">                    
                    <b-input-group                
                        class="mb-3"
                    >
                        <b-form-group style="height: 5px; width: 29%">
                            <datepicker     
                                style="width: 100%"                        
                                v-model="relComissao.intervaloComp"
                                type="date"
                                range 
                                format="DD/MM/YYYY"   
                                placeholder="Selecionar período"
                            ></datepicker>
                        </b-form-group>

                        <b-form-group style="height: 5px; width: 29%; margin: 0 5px 0 10px">
                        
                            <vue-single-select
                                style="font-size: 0.9rem; width: 100%"
                                id="contrato-grupo"
                                placeholder="Selecionar Comissionado"
                                v-model="comissionadoSelecionado"
                                :options="relComissao.comissionados"
                                option-key="value"
                                option-label="text"
                            ></vue-single-select>
                        </b-form-group>
                        
                        <b-input-group-append>
                            <b-button 
                                pill 
                                class="ml-1"
                                size="sm"
                                variant="outline-secondary"
                                @click="loadRelComissoes()"
                            >
                                <i class="fa fa-search button-icons"></i>
                                Visualizar
                            </b-button>

                            <b-button 
                                pill 
                                class="ml-1"
                                size="sm"
                                variant="outline-secondary"
                                @click="printPage"
                            >
                                <i class="fa fa-print button-icons"></i> Imprimir/Salvar
                            </b-button>
                        </b-input-group-append>

                    </b-input-group>
                </b-col>
            </b-row>
            <hr>
            
            <div id="rel-comiss-render-pdf">
                <h2>Relatório de comissões</h2>
                <h5>De {{relComissao.intervaloComp.length == 2 ? relComissao.intervaloComp[0].toLocaleDateString('pt-BR') : '' }} 
                    até {{relComissao.intervaloComp.length == 2 ? relComissao.intervaloComp[1].toLocaleDateString('pt-BR') : '' }}</h5>
                <h5><strong>{{relComissao.comissionado}}</strong></h5>
                <br>
                <b-table 
                    :items="relComissao.relatorio" 
                    :fields="relComissao.fields" 
                    :busy="relComissao.tableBusy"
                    responsive 
                    striped 
                    small 
                    hover 
                    borderless 
                    sort-icon-left
                >                                                    
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Aguarde...</strong>
                        </div>
                    </template>
                </b-table>
            </div>
            
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>  

        <b-modal 
            hideBvModalEvent
            v-model="modalLoadOp"                        
            id="modal-load-op" 
            centered size="lg" 
            ok-only
            ok-disabled
            title="Opções de Contratos"
        >  
            <div style="min-height: 428px">
                <b-tabs content-class="mt-3" fill>
                    <b-tab class="p-3" title="Gerar faturas" active>
                        <b-form-group label-for="contrato-renovar">
                            <b-form-checkbox id="contrato-renovar" v-model="parametrosContratos.limitar" class="mt-3 mb-3" switch>Especificar período</b-form-checkbox>
                        </b-form-group>
                        
                        <b-row>
                            <b-col md="5" sm="12">
                                <b-form-group label-for="data-competencia">
                                    Vencimento Inicial
                                    <b-form-datepicker
                                        id="data-competencia"
                                        v-model="parametrosContratos.dataInicio"
                                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: undefined }"
                                        locale="pt-BR"
                                        :disabled="!parametrosContratos.limitar"
                                        size="sm"
                                    ></b-form-datepicker>
                                </b-form-group>
                            </b-col>

                            <b-col md="5" sm="12">
                                <b-form-group label-for="data-limite">
                                    Vencimento Final
                                    <b-form-datepicker
                                        id="data-limite"
                                        v-model="parametrosContratos.dataFim"
                                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: undefined }"
                                        locale="pt-BR"
                                        :disabled="!parametrosContratos.limitar"
                                        size="sm"
                                    ></b-form-datepicker>
                                </b-form-group>
                            </b-col>

                            <b-col md="2" sm="12">
                                <b-button size="md" variant="success" @click="gerarFaturas()"><i class="fa fa-cogs mr-2" aria-hidden="true"></i>Gerar Faturas</b-button>
                            </b-col> 
                        </b-row>
                        <br>
                        <b-row> 
                            <b-col md="12" sm="12">
                                <b-card no-body>
                                    <b-tabs card style="min-height: 400px"  v-model="tabFat">
                                        <b-tab title="Faturas geradas">
                                            <div v-if="faturas.length > 0">
                                                <b-table :items="faturas" :fields="fieldsFaturas" responsive striped small hover borderless sort-icon-left>                                
                                                    <template #table-busy>
                                                        <div class="text-center text-primary my-2">
                                                        <b-spinner class="align-middle mr-1"></b-spinner>
                                                        <strong>Aguarde...</strong>
                                                        </div>
                                                    </template>
                                                </b-table>
                                                <hr>
                                                <b-pagination 
                                                    align="center" 
                                                    size="md" 
                                                    v-model="page"  
                                                    :total-rows="count" 
                                                    :per-page="limit" 
                                                    first-number 
                                                    last-number>
                                                </b-pagination>                                             
                                            </div>
                                            <div v-else>
                                                <p style="font-size: 1.2rem; padding: 15px">Nenhuma fatura gerada...</p>
                                            </div>
                                            
                                        </b-tab>

                                        <b-tab title="Erros">
                                            <div v-if="naoFaturados.length > 0">
                                                <b-table :items="naoFaturados" :fields="fieldsNaoFaturados" responsive striped hover borderless :busy="isBusyFat" sort-icon-left>                                
                                                    <template #table-busy>
                                                        <div class="text-center text-primary my-2">
                                                        <b-spinner class="align-middle mr-1"></b-spinner>
                                                        <strong>Aguarde...</strong>
                                                        </div>
                                                    </template>
                                                </b-table>
                                                <hr>
                                                <b-pagination 
                                                    align="center" 
                                                    size="md" 
                                                    v-model="page"  
                                                    :total-rows="count" 
                                                    :per-page="limit" 
                                                    first-number 
                                                    last-number>
                                                </b-pagination>
                                            </div>
                                            <div v-else>
                                                <p style="font-size: 1.2rem; padding: 15px">Nenhum contrato com problema...</p>
                                            </div>

                                        </b-tab>
                                    </b-tabs>
                                </b-card>                                
                            </b-col> 
                        </b-row>                     
                        
                    </b-tab> 

                    <b-tab class="p-3" title="Alteração de Contratos em Massa">
                        <b-row>
                            <b-col md="9" sm="12" lg="9"> 

                                <b-row style="margin-bottom: -30px">
                                    <b-col md="12" sm="12">
                                        <b-form-group label-for="contrato-renovar">
                                            <b-form-checkbox id="contrato-renovar" v-model="opcoesRenovacao.e_renovar" class="mt-3 mb-3" switch>Renovar Contratos</b-form-checkbox>
                                        </b-form-group> 
                                    </b-col>
                                </b-row>                             

                                <b-row class="row-altera-contrato">
                                    <b-col md="4" sm="4">
                                        <b-form-group v-if="!opcoesRenovacao.e_data_especifica" label-for="num-meses"> 
                                            Nº de Meses Adicionais
                                            <b-form-input id="num-meses" type="number" v-model="opcoesRenovacao.qtde_meses" :disabled="!opcoesRenovacao.e_renovar"/>                                                
                                        </b-form-group>
                                        <b-form-group v-else label-for="data-espec">
                                            Data de Encerramento
                                            <b-form-input id="data-espec" type="date" v-model="opcoesRenovacao.nova_data_encerramento" :disabled="!opcoesRenovacao.e_renovar"/>
                                        </b-form-group>                                      
                                        
                                    </b-col> 
                                    <b-col md="8" sm="8">
                                        <b-form-checkbox id="contrato-data-espec" v-model="opcoesRenovacao.e_data_especifica" class="mt-3 mb-3 sw-altera-contrato" switch>Expecificar a data de Encerramento</b-form-checkbox>
                                    </b-col> 
                                </b-row>
                                <hr style="margin-top: 40px">

                                <b-row class="row-altera-contrato">
                                    <b-col md="4" sm="4">
                                        <b-form-group label-for="dia-venc-alt"> 
                                            Dia do Vencimento
                                            <b-form-input id="dia-venc-alt" type="number" v-model="opcoesRenovacao.dia_vencimento" :disabled="!opcoesRenovacao.e_alterar_dia"/>                                                
                                        </b-form-group> 
                                    </b-col> 
                                    <b-col md="8" sm="8">
                                        <b-form-checkbox id="contrato-altera-dia" v-model="opcoesRenovacao.e_alterar_dia" class="mt-3 mb-3 sw-altera-contrato" switch>Alterar dia do Vencimento</b-form-checkbox>
                                    </b-col> 
                                </b-row>

                                <b-row class="row-altera-contrato">
                                    <b-col md="4" sm="4">
                                        <b-form-group label-for="orc-alt-status"> 
                                            Status
                                            <b-form-select id="orc-alt-status" 
                                                v-model="opcoesRenovacao.status" 
                                                :options="[{value: 'A', text: 'Ativo'},{value: 'S', text: 'Suspenso'},{value: 'C', text: 'Cancelado'}]" 
                                                :disabled="!opcoesRenovacao.e_alterar_status"                                            
                                            />                                             
                                        </b-form-group> 
                                    </b-col> 
                                    <b-col md="8" sm="8">
                                        <b-form-checkbox id="contrato-altera-status" v-model="opcoesRenovacao.e_alterar_status" class="mt-3 mb-3 sw-altera-contrato" switch>Alterar Status</b-form-checkbox>
                                    </b-col> 
                                </b-row>

                                <b-row class="row-altera-contrato">
                                    <b-col md="4" sm="4">
                                        <b-form-group label-for="cont-alt-reajuste"> 
                                            % Reajuste
                                            <money v-model="opcoesRenovacao.reajuste" v-bind="moneyItens" class="form-control" :disabled="!opcoesRenovacao.e_aplicar_reajuste" />                                              
                                        </b-form-group> 
                                    </b-col> 
                                    <b-col md="8" sm="8">
                                        <b-form-checkbox id="contrato-reajuste" v-model="opcoesRenovacao.e_aplicar_reajuste" class="mt-3 mb-3 sw-altera-contrato" switch>Aplicar reajuste de preços</b-form-checkbox>
                                    </b-col> 
                                </b-row>

                                <b-row class="row-altera-contrato">
                                    <hr>
                                    <b-col md="12" sm="12">
                                        <b-form-checkbox id="cont-alt-e-obs" v-model="opcoesRenovacao.e_alterar_obs" class="mt-3 mb-3" switch>Alterar Observações Fiscais</b-form-checkbox>
                                        
                                        <b-form-radio-group 
                                            id="radio-slots-alt"
                                            v-model="opcoesRenovacao.e_adicionar_substituir"
                                            name="radio-options-slots-alt"
                                        >
                                            <b-form-radio value="A">Acrescentar</b-form-radio>
                                            <b-form-radio value="S">Substituir</b-form-radio>
                                        </b-form-radio-group>

                                        <b-form-group label-for="cont-alt-obs"> 
                                            Observações
                                            <b-form-textarea id="cont-alt-obs" v-model="opcoesRenovacao.obs" :disabled="!opcoesRenovacao.e_alterar_obs"></b-form-textarea>                                             
                                        </b-form-group> 
                                    </b-col> 
                                </b-row>
                            </b-col>

                            <b-col md="3" sm="12" lg="3">
                                <br>
                                <b-button variant="success" @click="atualizarContratos()"><i class="fa fa-cogs mr-2" aria-hidden="true"></i>Aplicar Mudanças</b-button>
                            </b-col>
                        </b-row>
                    </b-tab>
                                       

                </b-tabs>
            </div>
            
            <br> 

            <b-card style="width: 100%" v-if="false">
                <vue-pdf-embed 
                :source="boletoPdf" />
            </b-card>   

            <b-card style="width: 100%">
                <div class="text-center text-primary my-2">
                    <b-spinner v-if="statusOpcoes === 'sucesso'" class="align-middle mr-1"></b-spinner>
                    <strong>{{ mensagemOpcoes }}</strong>
                </div>                  
            </b-card> 
            
            <template #modal-footer>
                <div class="w-100"> 
                </div>
            </template>                  
        </b-modal>

        <b-modal 
            class="a-classe-que-procuro"
            hideBvModalEvent
            v-model="modalAddServico"                        
            id="modal-servicos" 
            centered size="lg" 
            ok-only
            ok-disabled
            title="Serviços (Adição/Edição)"
        >      
            <b-form>        
                <b-row> 
                    <b-col md="6" sm="12">
                        <b-form-group label="Servico" label-for="servico-name-input">
                            <vue-single-select
                                id="servico-name-input"                            
                                placeholder="Procurar"
                                v-model="servicoSelecionado"
                                :options="servicos"
                                option-label="text"
                                option-key="value"
                                :get-option-description="label"
                                :get-option-value="value"
                            ></vue-single-select>
                        </b-form-group> 
                    </b-col>

                    <b-col md="2" sm="12">
                        <b-form-group label="Qtde" label-for="add-servico-qtde"> 
                            <b-form-input id="add-servico-qtde" type="number"
                                v-model="servico.qtde"
                                :readonly="mode === 'remove'" 
                                required
                                @input="calculaItem()"
                            />
                        </b-form-group>
                    </b-col>

                    <b-col md="2" sm="12">
                        <b-form-group label="Unitário" label-for="add-servico-unitario">  
                            <money v-model="servico.unitario" v-bind="moneyItens" class="form-control" required />                                      
                        </b-form-group>
                    </b-col>

                    <b-col md="2" sm="12">
                        <strong>
                            <b-form-group label="Total" label-for="add-servico-total">
                                <money id="add-servico-total" v-model="servico.total" v-bind="moneyItens" readonly class="form-control"/>                                        
                            </b-form-group>
                        </strong>
                    </b-col>
                </b-row> 
                <b-row style="margin-top: -20px">
                    <b-col md="4" sm="12">
                        <b-form-group label="Vendedor" label-for="vendedor-add-servico">
                            <vue-single-select
                                input-id="vendedor-add-servico"
                                placeholder="Procurar"
                                v-model="vendedorSelecionado"
                                :options="vendedores"
                                option-key="value"
                                option-label="text"
                                :get-option-description="label"
                                :get-option-value="value"
                            ></vue-single-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="8" sm="12">
                        <b-form-group
                        label="Detalhes"
                        label-for="add-servico-det"
                        class="mb-0"
                        >
                            <b-form-textarea
                                id="add-servico-det"
                                v-model="servico.detalhes"
                            ></b-form-textarea>
                        </b-form-group> 
                    </b-col>
                </b-row>
            </b-form>
            
            <template #modal-footer>
                <div class="w-100">                               

                    <b-col md="10" sm="10">
                        <b-button variant="primary" v-if="modeAddServico === 'save'"
                            @click="salvarServico">Salvar</b-button>
                        <b-button class="ml-2" @click="resetServico">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                    
        </b-modal>

        <b-modal 
            class="a-classe-que-procuro"
            hideBvModalEvent
            v-model="modalGrupoContrato"                        
            id="modal-grupos-contrato" 
            centered size="md" 
            ok-only
            ok-disabled
            title="Grupo de Contrato (Adição/Edição)"
        >      
            <b-form>        
                <b-row> 
                    <b-col md="12" sm="12">
                        <b-form-group label="Descrição do Grupo" label-for="desc-grupo"> 
                            <b-form-input id="desc-grup" type="text" v-model="contratoGrupo.descricao" required />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row> 
                    <b-table v-if="gruposContratos.length > 0" class="ml-3 mr-3" hover striped :items="gruposContratos" :fields="fieldsGrupoContrato" borderless small>  
                        <template v-slot:cell(actions)="data">
                            <b-button size="sm" variant="warning" @click="loadGrupoContrato(data.item)" class="mr-2">
                                <i class="fa fa-pencil"></i>
                            </b-button>
                            <b-button size="sm" variant="danger" @click="removeGrupoContrato(data.item)">
                                <i class="fa fa-trash"></i>
                            </b-button>
                        </template>
                    </b-table>
                </b-row>
            </b-form>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-col md="10" sm="10">
                        <b-button variant="primary"
                            @click="salvarGrupoContrato">Salvar</b-button>
                        <b-button class="ml-2" @click="modalGrupoContrato = false">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                    
        </b-modal>

        <b-modal 
            class="a-classe-que-procuro"
            hideBvModalEvent
            v-model="modalAddComissao"                        
            id="modal-comissionados" 
            centered size="lg" 
            ok-only
            ok-disabled
            title="Comissão (Adição/Edição)"
        >      
            <b-form>        
                <b-row> 
                    <b-col md="9" sm="12">
                        <b-form-group label-for="comissao-name-input">
                            <template #label>
                                <strong>Comissionamento</strong>
                            </template>
                            <vue-single-select
                                id="comissao-name-input"                            
                                placeholder="Procurar"
                                v-model="comissaoSelecionada"
                                :options="comissoes"
                                option-label="text"
                                option-key="value"
                                :get-option-description="label"
                                :get-option-value="value"
                            ></vue-single-select>
                        </b-form-group> 
                    </b-col>

                    <b-col md="3" sm="12">
                        <strong>
                            <b-form-group :label="labelComissao(comissao.e_porcentagem)" label-for="add-servico-total" v-if="!(comissao.id_comissao_faturamento > 0)">
                                <money v-if="comissao.e_porcentagem === 'S'" id="add-servico-total" v-model="comissao.taxa_comissao" v-bind="percent" class="form-control"/>                                        
                                <money v-else id="add-servico-total" v-model="comissao.valor_comissao" v-bind="moneyItens" class="form-control"/>                                        
                            </b-form-group>
                        </strong>
                    </b-col>
                </b-row> 
                <b-row style="margin-top: -20px">
                    <b-col md="128" sm="12">
                        <b-form-group
                        label-for="add-comiss-det"
                        class="mb-0"
                        >
                            <template #label>
                                <strong>Detalhes</strong>
                            </template>
                            <b-card-text :style="{ whiteSpace: 'pre-line' }">{{ comissao.detalhes_comissao }}</b-card-text>
                        </b-form-group> 
                    </b-col>
                </b-row>
            </b-form>
            
            <template #modal-footer>
                <div class="w-100">                               

                    <b-col md="10" sm="10">
                        <b-button variant="primary"
                            @click="salvarComissao">Salvar</b-button>
                        <b-button class="ml-2" @click="modalAddComissao = false">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                    
        </b-modal>

        <b-modal 
            hideBvModalEvent
            v-model="modalEmail"                        
            id="modal-email" 
            centered size="xl" 
            ok-only
            ok-disabled
            title="E-mail"
        >  
            <b-overlay :show="emailBusy" rounded="lg" opacity="0.6">
                <template #overlay>
                    <div class="d-flex align-items-center">
                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                        <b-spinner type="grow" variant="dark"></b-spinner>
                        <b-spinner small type="grow" variant="secondary"></b-spinner>                        
                    </div>

                    <div class="d-flex align-items-center">
                        <h3>Enviando e-mail, aguarde...</h3>
                    </div>
                </template>

                <b-row> 
                    <b-col md="12" sm="12">
                        <b-form-group label="Destinatário" label-for="email-dest"> 
                            <b-form-input id="email-dest" type="email" v-model="email.destinatario" />
                        </b-form-group>

                        <b-form-group label="Assunto" label-for="email-assunto"> 
                            <b-form-input id="email-assunto" type="text" v-model="email.assunto" />
                        </b-form-group>

                        <VueEditor id="email-editor" v-model="email.texto" />

                        <div v-if="nomeAnexo" class="mt-3">
                            <b-card class="d-inline-block gradient-card" no-body>
                                <b-card-body class="d-flex align-items-center">
                                    <strong>
                                        <i style="font-size: 1.3rem" class="fa fa-paperclip mr-2"></i>
                                        <span style="font-size: 0.8rem">{{ truncatedNomeAnexo }}</span>
                                    </strong>
                                </b-card-body>
                            </b-card>
                        </div>

                    </b-col>               
                </b-row> 
            </b-overlay>
            
            <template #modal-footer>
                <div class="w-100">
                    <b-col md="10" sm="10">
                        <b-button variant="primary"
                            @click="enviarEmail()">Enviar</b-button>
                        <b-button class="ml-2" @click="modalEmail = false">Cancelar</b-button>
                    </b-col>
                </div>
            </template>                  
        </b-modal> 

        <b-modal id="modal-exclui-comissao" title="Excluir Contratos" v-model="modalExcluirComissao">
            <p >
                Deseja realmente <strong>Excluir</strong> a comissão selecionada? <br>
            </p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="removeComissao()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-exclui-comissao')"
                    >
                        Não
                    </b-button>
                </div>
            </template>
        </b-modal>
        
    </div>
</template>

<script>
// import { VueEditor } from "vue2-editor"
import { baseApiUrl, showError, showAlert } from '@/global'
import VueSingleSelect from "vue-single-select";
import Editor from '@tinymce/tinymce-vue'
import {VMoney} from 'v-money'
const moment = require('moment')
let extenso = require('extenso')
import axios from 'axios'
import { showSuccess } from '../../../global'
import html2pdf from "html2pdf.js"
import { mask } from 'vue-the-mask';

import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css'; // Não esqueça de importar o CSS
import 'vue2-datepicker/locale/pt-br';

export default {
    name: 'CadastroContratos',
    components: { Editor, VueSingleSelect, Datepicker },   
    computed: {
        truncatedNomeAnexo() {
            return this.nomeAnexo.length > 20 ? this.nomeAnexo.slice(0, 30) + '...' : this.nomeAnexo;
        },
    },
    directives: {
        money: VMoney,
        mask
    },
    data: function() {
        return {
            selector: '#editorTiny',
            editorConfig: {
                height: 500,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 
                    'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'table', 
                    'paste', 'code', 'wordcount', 'textcolor', 'colorpicker' 
                ],
                toolbar: [
                    'undo redo | formatselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link table | removeformat | code'
                ].join(' '), 
                content_style: 'body { font-family:Arial, Helvetica, sans-serif; font-size:14px; }',
                table_toolbar: 'tableprops tableinsert rowprops columnprops cellprops',
                table_resizable: true,
                menubar: false,
                toolbar_mode: 'sliding',
                language: 'pt_BR',                
            },

            modalGrupoContrato: false,
            contratoGrupo: {
                id_contrato_grupo: '',
                descricao: ''
            },
            fieldsGrupoContrato: [
                {key: 'descricao', label: 'Descrição', sortable: true},
                {key: 'actions', label: '', sortable: false}
            ],

            showOverContratos: false,

            modalAddComissao: false,
            modalExcluirComissao: false,
            comissoes: [],
            comissao: {
                id_pessoa_comissionamento: '',
                id_comissao_faturamento: '',
                taxa_valor_comissao: 0,
                detalhes_comissao: '',
                e_porcentagem: 'S'
            },
            comissaoSelecionada: '',
            debounceTimeout: null,

            pessoaSelecionada: '',
            grupoSelecionado: '',
            servicoSelecionado: '',
            vendedorSelecionado: '',
            planoSelecionado: '',
            formaSelecionada: '',            

            testes: {},

            modalEmail: false,
            emailBusy: false,
            emailContrato: '',
            email: {
                destinatario: '',
                assunto: '',
                texto: ''
            },
            nomeAnexo: '',
            nomeEmail: '',

            comissionadoSelecionado: '',
            relComissao: {                
                showModal: false,
                showOver: false,
                tableBusy: false,
                intervaloComp: [],
                idComissionado: '',
                relatorio: [],
                comissionado: 'Todos',
                comissionados: [], 
                fields: [
                    {key: 'num_contrato', label: 'Contrato', sortable: false},
                    {key: 'data_contrato', label: 'Data', sortable: true, 
                        formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                    },                
                    {key: 'nome_comissionado', label: 'Comissionado', sortable: true},
                    // {key: 'nome_grupo_comissao', label: 'Grupo', sortable: false},
                    // {key: 'valor_base_contrato', label: 'Valor Base', sortable: false, formatter: value => { 
                    //         return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    //     }
                    // },
                    {key: 'valor_base_comissao', label: 'Base Comissão', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    },
                    {key: 'porc_comissao', label: '% Comissão', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {maximumFractionDigits: 2})+'%'                        
                        }
                    },
                    {key: 'valor_comissao', label: 'Valor Comissão', sortable: false, formatter: value => { 
                            return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                        }
                    }
                ]
            },
            
            modalLoadOp: false,
            editaModelo: false,
            modalAddServico: false,
            modalRemServico: false,
            modalExcluirContrato: false,
            modalImprimeContrato: false,
            modelos: [],
            modelo: '',
            nomeModelo: 'contrato',
            idmodelo: 0,
            idCont: 0,
            impresso: '',
            modeAddServico: 'save',
            modeServico: 'new',
            index: 0,
            pessoas: [],
            gruposContratos: [],
            vendedores: [],
            formas: [],
            planoContas: [],
            servico: {
                id_servico: '',
                descricao: '',
                qtde: 1,
                valor: 0
            },
            servicos: [],
            contrato: {
                id_contrato: '',
                id_pessoa: '',
                id_plano_conta: '',
                id_forma_pagar_receber: '',
                periodicidade: 30,
                data: moment().format('YYYY-MM-DD'),
                primeiro_vencimento: moment().format('YYYY-MM-DD'),
                encerramento: moment().add(6, 'months').format('YYYY-MM-DD'), 
                qtde_parcelas: 6,
                desc_ate_vencto: 0,
                obs: '',
                instrucoes: '',
                obs_fiscais: '',
                itens: [],
                comissoes: [],
                e_auto_faturamento: 'N',
                e_auto_nf: 'N',
                e_auto_boleto: 'N',
                emitir_bol_dias: 5,
            },
            contratos: [],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            moneyItens: {
                prefix: 'R$ ',
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            },
            percent: {
                decimal: ',',
                thousands: '.',                
                suffix: ' %',
                precision: 2,
                masked: false
            },
            mode: 'new',            
            page: 1,
            count: 0,
            limit: 0,   
            parametrosContratos: {
                limitar: false,
                dataInicio: moment().format('yyyy-MM-DD'),
                dataFim: moment().endOf('month').format('yyyy-MM-DD')
            },
            intervaloVenc: [],
            intervaloComp: [],            
            consulta: {
                parcial: '',
                dataIniComp: null,
                dataFimComp: null,
                dataIniVenc: null,
                dataFimVenc: null,
                filial: 0,
                idPlanoConta: 0,
                status: ['A','F'],                
                // e_boleto: 'T',
                opcoes: [
                    { value: 'A', text: 'Ativos' },
                    { value: 'F', text: 'Faturados'},
                    { value: 'E', text: 'Encerrados' },
                    { value: 'C', text: 'Cancelados' }
                ]  
            },
            filiais: [],                       

            show: true,
            showOver: false,
            showOverBaseComiss: false,

            selecteds: [],
            contratosSelecionados: [],
            contratosSelecExcluir: [],
            statusOpcoes: '',
            mensagemOpcoes: '', 
            opcoesRenovacao: {
                e_renovar: false,
                e_data_especifica: false,
                qtde_meses: 6,
                nova_data_encerramento: moment().add(6, 'months').format('YYYY-MM-DD'),
                e_alterar_dia: false,
                dia_vencimento: 5,
                e_alterar_status: false,
                status: 'A',
                e_aplicar_reajuste: false,
                reajuste: 0,
                e_alterar_obs: false,
                e_adicionar_substituir: 'S',
                obs: ''
            },
            atualizados: [],
            naoAtualizados: [],
            naoFaturados: [],
            faturas: [],
            tabFaturas: 0,
            tabFat: 0,
            
            fields: [
                {key: 'selected', label: '', sortable: false},
                {key: 'num_contrato', label: 'Nº Contrato', sortable: true},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'data', label: 'Data do Contrato', sortable: true, 
                    formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                },                
                {key: 'encerramento', label: 'Encerramento', sortable: true, 
                    formatter: value => { 
                        return `${moment(value).format('DD/MM/YYYY')} (${(moment(value).diff(moment(), 'days')+1) < 0 ? 0 : (moment(value).diff(moment(), 'days')+1)} dias)`
                    }
                },
                {key: 'status', label: ''},        
                {key: 'actions', label: ''}
            ],

            fieldsExcluir: [
                {key: 'num_contrato', label: 'Nº Contrato', sortable: true},                                
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'encerramento', label: 'Encerramento', sortable: true, 
                    formatter: value => { 
                        return `${moment(value).format('DD/MM/YYYY')} (${(moment(value).diff(moment(), 'days')+1) < 0 ? 0 : (moment(value).diff(moment(), 'days')+1)} dias)`
                    }
                },
                {key: 'status', label: 'Status', sortable: true,
                    formatter: value => {
                        switch (value) {
                            case 'A':
                                return 'ATIVO'
                            case 'F':
                                return 'FATURADO'
                            case 'C':
                                return 'CANCELADO'                            
                            case 'E':
                                return 'ENCERRADO'                            
                            case 'S':
                                return 'SUSPENSO'                            
                        } 
                    }
                }
            ],

            fieldsFaturas: [
                {key: 'id_conta_receber', label: 'Fatura', sortable: true},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'data_competencia', label: 'Competência', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'vencimento', label: 'Vencimento', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'valor', label: 'Valor', sortable: true, formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    }
                },
                {key: 'descricao', label: 'Descrição', sortable: true},
            ],        
            fieldsNaoFaturados: [
                {key: 'num_contrato', label: 'Contrato', sortable: true},
                {key: 'data', label: 'Data', sortable: true, 
                    formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')                        
                    }
                },
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'motivo', label: 'Motivo', sortable: false},
            ],

            fieldsContratoItens: [
                {key: 'id_servico', label: 'Código', sortable: false},
                {key: 'descricao', label: 'Descrição', sortable: false},
                {key: 'qtde', label: 'Qtde', sortable: false},
                {key: 'unitario', label: 'Unitário', sortable: false,
                    formatter: value => {
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },                
                {key: 'total', label: 'Total', sortable: false,
                    formatter: value => {
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },                
                {key: 'actions', label: ''}
            ],
            fieldsContratoComissoes: [
                {key: 'comissionamento', label: 'Comissionamento', sortable: false},
                {key: 'taxa_valor_comissao', label: 'Taxa/Valor', sortable: false},           
                {key: 'valor_comissao_calculada', label: 'Valor Comissão', sortable: false,
                    formatter: value => {
                        return value ? 'R$ '+ value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : 'R$ 0,00'
                    }
                },         
                {key: 'actions', label: ''}
            ],  

            optionsAutoNFSe: [
                { text: 'Não', value: 'N' },
                { text: 'Ao Faturar', value: 'AF' },
                { text: 'Ao Liquidar', value: 'AR'}
            ],
            optionsAutoBol: [
                { text: 'Não', value: 'N' },
                { text: 'Ao Faturar', value: 'AF'},
                { text: 'Próximo do Vencimento', value: 'PV'}
            ]
            
        }
    },
    methods: {
        // Exemplo de tabela: @tabela[COMISSOES|@taxa-comissao@,@valor-comissao@,@detalhes-comissao@]
        gerarTabelaDinamica() {
            let conteudoHtml = this.impresso;  // Conteúdo do HTML
            let dados = []

            // Regex para encontrar a tag @tabela[]
            const regexTabela = /@tabela\[(.*?)\]/g;

            conteudoHtml = conteudoHtml.replace(regexTabela, (match, p1) => {
                const [nomeArray, colunas] = p1.split('|');

                if (nomeArray === 'COMISSOES') {
                    dados = this.contrato.comissoes; 
                    let campos = []
                    const colunasArray = colunas.split(',');

                    let tabelaHtml = '<table style="border-collapse: collapse; width: 100%;">';
                    tabelaHtml += '<thead><tr>';
                    
                    colunasArray.forEach(coluna => {
                        let nomeColuna = ''
                        
                        switch (coluna) {
                            case '@nome-comissionado@':
                                nomeColuna = 'Nome do Comissionado';
                                campos.push('nome_comissionado');
                                break;
                            case '@taxa-comissao@':
                                nomeColuna = 'Taxa da Comissão';
                                campos.push('taxa_comissao');
                                break;
                            case '@valor-comissao@':
                                nomeColuna = 'Valor Fixo';
                                campos.push('valor_comissao');
                                break;
                            case '@valor-comissao-calculada@':
                                nomeColuna = 'Valor da Comissão';
                                campos.push('valor_comissao_calculada');
                                break;
                            case '@detalhes-comissao@':
                                nomeColuna = 'Detalhes da Comissão';
                                campos.push('detalhes_comissao');
                                break;
                            case '@e-pagto-recebimento@':
                                nomeColuna = 'Pagamento de Recebimento (S/N)';
                                campos.push('e_pagto_recebimento');
                                break;
                            case '@e-porcentagem@':
                                nomeColuna = 'Porcentagem (S/N)';
                                campos.push('e_porcentagem');
                                break;
                            case '@grupo-comissao@':
                                nomeColuna = 'Descrição do Grupo de Comissão';
                                campos.push('grupo_comissao');
                                break;
                            default:
                                nomeColuna = 'Coluna Desconhecida';
                                campos.push('coluna_desconhecida');
                                break;
                        }

                        tabelaHtml += `<th style="border: 1px solid black; padding: 8px;">${nomeColuna}</th>`;
                    });

                    tabelaHtml += '</tr></thead>';
                    tabelaHtml += '<tbody>';
                    dados.forEach(comissao => {
                        tabelaHtml += '<tr>';
                        let cont = 0
                        colunasArray.forEach(() => {   
                            let campo = campos[cont]

                            if (campo === 'taxa_comissao') {
                                comissao[campo] > 0 ? comissao[campo] = comissao[campo] + '%' : comissao[campo] = '--'
                            } else if (campo === 'valor_comissao') {
                                comissao[campo] = 'R$ ' + comissao[campo].toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            } else if (campo === 'valor_comissao_calculada') {
                                comissao[campo] = 'R$ ' + comissao[campo].toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                            }

                            tabelaHtml += `<td style="border: 1px solid black; padding: 8px;">${comissao[campo]}</td>`;
                            cont++
                        });
                        tabelaHtml += '</tr>';
                    });
                    tabelaHtml += '</tbody>';
                    tabelaHtml += '</table>';

                    return tabelaHtml; // Substitui a tag pela tabela gerada
                }
                
                // Caso o array não seja 'comissoes', retorna o match original
                return match;
            });

            // Atualizar o conteúdo com a tabela gerada
            this.impresso = conteudoHtml;
        },

        mascaraComissao(i) {
            if (i=='S') {
                return this.percent
            } else {
                return this.moneyItens
            }
        },
        labelComissao(i) {
            if (i=='S') {
                return 'Percentual'
            } else {
                return 'Valor Fixo'
            }
        },

        label(option){
            return option.text
        },
        value(option){
            return option.value
        },
        atualizarContratos() { 
            
            let dados =  {
                contratos: [ ...this.contratosSelecionados ],
                param: { ...this.opcoesRenovacao }
            }

            axios['post'](`${baseApiUrl}/atualizarcontratos`, dados)
                .then((res) => {
                    this.atualizados = res.data.atualizados
                    this.naoAtualizados = res.data.naoAtualizados 
                     
                    if (this.atualizados.length > 0) {

                        if (this.naoAtualizados.length > 0) {
                            showAlert('Alguns contratos não foram atualizados! <br>Verifique o motivo na aba contratos não atualizados')
                        } else {
                            showSuccess('Contratos atualizados com sucesso')
                        }                        
                    } else {
                        showError('Nenhum contrato atualizado! <br>Verifique o motivo na aba contratos não atualizados')
                    }  
                })
            .catch(err => {
                showError(err)
            })  
        },
        opcoesContratos() {
            this.modalLoadOp = true
        },

        loadFiliais() { 
            const url = `${baseApiUrl}/filiais`    

            axios.get(url)
            .then(res => {
                 this.filiais = res.data.map(filial => {
                    return { ...filial,  value: filial.idfilial, text: `${filial.idfilial} | ${filial.razao_social} (${filial.fantasia})`}
                })

                if (this.modalRelFaturas) {
                    this.filiais.unshift({value: 0, text: 'Todas as Filiais'})
                } else {
                    this.filiais.unshift({value: 0, text: 'TODAS'})
                }
            })
            .catch(err => {
                showError(err)
            })            
        },

        

        onRowSelected(s) { 
            this.selecteds = s
            this.contratosSelecionados = this.selecteds.map(c=> {return c.id_contrato}) 
            this.contratosSelecExcluir = [ ...this.selecteds ]
        },
        selectAllRows() {
            this.$refs.selectableTableContratos.selectAllRows()
        },
        clearSelected() {
            this.$refs.selectableTableContratos.clearSelected()
        },
        alerarStatus(c, sts) {            
            axios['post'](`${baseApiUrl}/contratostatus/${c.id_contrato}`, {status: sts})
            .then(() => {
                c.status = sts
                showSuccess('Status alterado com secesso.')
            })
            .catch(err => {
                showError(err)
            })  
        },
        salvarPdf(){
            var element = document.getElementById('renderPDF');
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.25,0.25,0.25,0.25],
                filename: `${this.nomeModelo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },        

        loadModelos(c) { 
            this.idCont  = c.id_contrato

            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&tipo=cadmodelo&tela=cadcontrato` 

            axios.get(url).then(res => {
                this.emailContrato = c.email
                this.nomeEmail = c.razao_social

                this.modalImprimeContrato = true   
                this.modelos = res.data.data.map(modelo => {
                    return { ...modelo, value: modelo.id_modelo_relatorio, text: `${modelo.id_modelo_relatorio} | ${modelo.descricao}` }
                })
            }) 
            .catch(showError)           
        }, 
        selectModelo() {
            if (this.idmodelo > 0) {
                
                const url = `${baseApiUrl}/modelos/relatorios/${this.idmodelo}`    
    
                axios.get(url).then(res => {
                    this.modelo = res.data.corpo_modelo
                    this.nomeModelo = res.data.descricao
                    this.imprimeContrato(this.idCont)
                }) 
                .catch(err => {
                    showError(err)
                })
            }
        },

        primeiraMaiuscula(str) {
            if (!str) return str; // Verifica se a string é vazia
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        },

        exibirEmail(e) {
            this.modalEmail = true
            this.email.destinatario = e
            const n = this.nomeEmail.split(" ")

            this.nomeAnexo = this.sanitizeString(this.nomeModelo)

            this.email.texto = `<p>Olá, ${this.primeiraMaiuscula(n[0])},</p><p>
Espero que você esteja bem!</p><p>
Gostaria de avisar que há um anexo neste e-mail. Por favor, dê uma olhada e, se precisar de mais informações, estou à disposição para ajudar.</p><p>
Obrigado!</p>`
        },

        async gerarAnexoPdf() {
            const element = document.getElementById('renderPDF');
            const pdfBlob = await html2pdf().from(element).toPdf().get('pdf').output('blob');

            // Converter o Blob em Base64
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    const base64data = reader.result.split(',')[1]; // Remove a parte do cabeçalho
                    resolve(base64data);
                };
                reader.readAsDataURL(pdfBlob);
            });
        },

        sanitizeString(input) {
            // Remove acentos
            const accentMap = {
                'á': 'a', 'à': 'a', 'ã': 'a', 'â': 'a', 'ä': 'a',
                'é': 'e', 'è': 'e', 'ê': 'e', 'ë': 'e',
                'í': 'i', 'ì': 'i', 'î': 'i', 'ï': 'i',
                'ó': 'o', 'ò': 'o', 'õ': 'o', 'ô': 'o', 'ö': 'o',
                'ú': 'u', 'ù': 'u', 'û': 'u', 'ü': 'u',
                'ç': 'c', 'Ç': 'C'
            };

            const removeAccents = (str) => {
                return str.split('').map(char => accentMap[char] || char).join('');
            };

            // Elimina caracteres especiais e espaços
            return removeAccents(input)
                .replace(/[^\w\s]|_/g, '')    // Remove caracteres especiais
                .replace(/\s+/g, '_')        // Substitui espaços por underscores
                .toUpperCase(); 
        },

        async enviarEmail() {  
            this.emailBusy = true;

            try {
                // Gerar o PDF e obter a string Base64
                const pdfBase64 = await this.gerarAnexoPdf();
                const nArq = this.sanitizeString(this.nomeModelo)

                // Preparar os dados do e-mail
                const formData = {
                    destinatario: this.email.destinatario,
                    assunto: this.email.assunto,
                    texto: this.email.texto,
                    pdf: pdfBase64, // Enviar o PDF em Base64
                    nomeArquivo: `${nArq}.pdf`
                };

                // Enviar o e-mail
                await axios.post(`${baseApiUrl}/email`, formData)
                .then(async () => {
                    this.email = {
                        destinatario: '',
                        assunto: '',
                        texto: ''
                    }   

                    this.emailBusy = false   
                    this.modalEmail = false    
                    showSuccess('E-mail enviado com sucesso.')              
                    
                })
                .catch(err => {
                    this.emailBusy = false 
                    showError(err)
                }) 

            } catch (err) {
                this.emailBusy = false; 
                showError(err);
            }           
        },
                
        imprimeContrato(id) {

            const url = `${baseApiUrl}/contratos/${id}`    

            moment.locale('pt')

            axios.get(url).then(res => {  

                this.contrato.comissoes = res.data.comissoes

                try {
                    this.nomeModelo = `${this.nomeModelo} (${res.data.razao_social})`
                    this.impresso =  this.modelo.replace(/@nome-cliente@/g, res.data.razao_social);
                    this.impresso = this.impresso.replace(/@id-contrato@/g, res.data.id_contrato);
                    this.impresso = this.impresso.replace(/@grupo-contrato@/g, res.data.grupo_contrato === null ? '' : res.data.grupo_contrato);
                    this.impresso = this.impresso.replace(/@id-pessoa@/g, res.data.id_pessoa);
                    this.impresso = this.impresso.replace(/@tipo-pessoa@/g, res.data.tipo_pessoa === 'F' ? 'FÍSICA' : 'JURÍDICA');
                    this.impresso = this.impresso.replace(/@cpf@/g, res.data.cpf === null ? '' : res.data.cpf);
                    this.impresso = this.impresso.replace(/@cnpj@/g, res.data.cnpj === null ? '' : res.data.cnpj);
                    this.impresso = this.impresso.replace(/@rg@/g, res.data.rg === null ? '' : res.data.rg); 
                    this.impresso = this.impresso.replace(/@ie@/g, res.data.ie === null ? '' : res.data.ie); 
                    this.impresso = this.impresso.replace(/@endereco@/g, res.data.endereco  === null ? '' : res.data.endereco); 
                    this.impresso = this.impresso.replace(/@numero@/g, res.data.numero === null ? 'S/N' : res.data.numero); 
                    this.impresso = this.impresso.replace(/@bairro@/g, res.data.bairro === null ? '' : res.data.bairro); 
                    this.impresso = this.impresso.replace(/@complemento@/g, res.data.complemento === null ? '' : res.data.complemento);
                    this.impresso = this.impresso.replace(/@uf@/g, res.data.uf === null ? '' : res.data.uf);
                    this.impresso = this.impresso.replace(/@nome-cidade@/g, res.data.nome_cidade === null ? '' : res.data.nome_cidade); 
                    this.impresso = this.impresso.replace(/@cep@/g, res.data.cep === null ? '' : res.data.cep); 
                    this.impresso = this.impresso.replace(/@telefone@/g, res.data.telefone === null ? '' : res.data.telefone);
                    this.impresso = this.impresso.replace(/@celular@/g, res.data.celular === null ? '' : res.data.celular);
                    this.impresso = this.impresso.replace(/@num-contrato@/g, res.data.num_contrato);
                    this.impresso = this.impresso.replace(/@valor-total@/g, res.data.valor_total ? res.data.valor_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@valor-total-extenso@/g, res.data.valor_total ? extenso(res.data.valor_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@data@/g, moment(res.data.data).format('DD/MM/YYYY'));
                    this.impresso = this.impresso.replace(/@data-extenso@/g, moment(res.data.data).format('LL') );
                    this.impresso = this.impresso.replace(/@primeiro-vencimento@/g, moment(res.data.primeiro_vencimento).format('DD/MM/YYYY'));
                    this.impresso = this.impresso.replace(/@primeiro-vencimento-extenso@/g, moment(res.data.primeiro_vencimento).format('LL') );
                    this.impresso = this.impresso.replace(/@encerramento@/g, moment(res.data.encerramento).format('DD/MM/YYYY'));
                    this.impresso = this.impresso.replace(/@encerramento-extenso@/g, moment(res.data.encerramento).format('LL') );
                    this.impresso = this.impresso.replace(/@periodicidade@/g, res.data.periodicidade);
                    this.impresso = this.impresso.replace(/@qtde-parcelas@/g, res.data.qtde_parcelas);
                    this.impresso = this.impresso.replace(/@qtde-parcelas-extenso@/g, extenso(res.data.qtde_parcelas, { number: { gender: 'm' } }));
                    this.impresso = this.impresso.replace(/@valor-parcela@/g, (res.data.valor_total / res.data.qtde_parcelas).toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-parcela-extenso@/g, extenso((res.data.valor_total / res.data.qtde_parcelas).toLocaleString('pt-br', {minimumFractionDigits: 2}), { mode: 'currency' }));
                    this.impresso = this.impresso.replace(/@desc-ate-vencto@/g, res.data.desc_ate_vencto);
                    this.impresso = this.impresso.replace(/@obs@/g, res.data.obs === null ? '' : res.data.obs);
                    this.impresso = this.impresso.replace(/@instrucoes@/g, res.data.instrucoes === null ? '' : res.data.instrucoes);
                    this.impresso = this.impresso.replace(/@obs-fiscais@/g, res.data.obs_fiscais === null ? '' : res.data.obs_fiscais);
                    this.impresso = this.impresso.replace(/@razao-social-filial@/g, res.data.razao_social_filial); 
                    this.impresso = this.impresso.replace(/@tipo-pessoa-filial@/g, res.data.tipo_pessoa_filial === 'F' ? 'FÍSICA' : 'JURÍDICA');
                    this.impresso = this.impresso.replace(/@fantasia-filial@/g, res.data.fantasia_filial === null ? '' : res.data.fantasia_filial); 
                    this.impresso = this.impresso.replace(/@cnpj-cpf-filial@/g, res.data.cnpj_cpf_filial); 
                    this.impresso = this.impresso.replace(/@ie-rg-filial@/g, res.data.ie_rg_filial === null ? '' : res.data.ie_rg_filial); 
                    this.impresso = this.impresso.replace(/@im-filial@/g, res.data.im_filial === null ? '' : res.data.im_filial); 
                    this.impresso = this.impresso.replace(/@telefone-filial@/g, res.data.telefone_filial === null ? '' : res.data.telefone_filial); 
                    this.impresso = this.impresso.replace(/@fax-filial@/g, res.data.fax_filial === null ? '' : res.data.fax_filial); 
                    this.impresso = this.impresso.replace(/@celular-filial@/g, res.data.celular_filial === null ? '' : res.data.celular_filial);                   
                    this.impresso = this.impresso.replace(/@email-filial@/g, res.data.email_filial === null ? '' : res.data.email_filial); 
                    this.impresso = this.impresso.replace(/@endereco-filial@/g, res.data.endereco_filial === null ? '' : res.data.endereco_filial); 
                    this.impresso = this.impresso.replace(/@numero-filial@/g, res.data.numero_filial === null ? 'S/N' : res.data.numero_filial); 
                    this.impresso = this.impresso.replace(/@bairro-filial@/g, res.data.bairro_filial === null ? '' : res.data.bairro_filial); 
                    this.impresso = this.impresso.replace(/@nome-cidade-filial@/g, res.data.nome_cidade_filial === null ? '' : res.data.nome_cidade_filial);
                    this.impresso = this.impresso.replace(/@uf-filial@/g, res.data.uf_filial === null ? '' : res.data.uf_filial);
                    this.impresso = this.impresso.replace(/@complemento-filial@/g, res.data.complemento_filial === null ? '' : res.data.complemento_filial); 
                    this.impresso = this.impresso.replace(/@cep-filial@/g, res.data.cep_filial === null ? '' : res.data.cep_filial);
                    this.impresso = this.impresso.replace(/@data-hoje@/g, moment().format('DD/MM/YYYY') );
                    this.impresso = this.impresso.replace(/@data-hoje-extenso@/g, moment().format('LL') );
                    this.impresso = this.impresso.replace(/@valor-base-contrato@/g, res.data.valor_base_contrato ? res.data.valor_base_contrato.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@valor-base-comissao@/g, res.data.valor_base_comissao ? res.data.valor_base_comissao.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@e-base-descontado-impostos@/g, res.data.e_base_descontado_impostos === 'S' ? 'Descontado Impostos' : 'Com Impostos');

                    this.gerarTabelaDinamica()
                                        
                } catch (error) {
                    throw error
                }                
              
            }) 
            .catch(err => {
                showError(err)
            })
        },   
        
        novoRelatorioComissao() {
            this.loadComissionados()  
            this.comissionadoSelecionado = ''
            this.relComissao.showModal = true
        },

        novoContrato() {
            this.pessoaSelecionada = ''
            this.grupoSelecionado = ''
            this.formaSelecionada = ''
            this.planoSelecionado = ''            
            this.show = false
            this.mode = 'save'
            this.loadPlanoContas(false)
            this.loadFormas()  
            this.loadPessoas()          
            this.loadContratoGrupos()          
        },

        novoGrupoContrato() {
            this.resetGruposContrato()         
            this.modalGrupoContrato = true
        },

        loadGrupoContrato(c) {
            this.contratoGrupo = { ...c }
        },

        loadContratoGrupos() {  
            const url = `${baseApiUrl}/contratosgrupos`

            axios.get(url).then(res => {
                this.gruposContratos = res.data.map(grupo => {
                    return { ...grupo, value: grupo.id_contrato_grupo, text: `| ${grupo.descricao}` }
                })
            })
        },

        salvarGrupoContrato() {
            const method = this.contratoGrupo.id_contrato_grupo ? 'put' : 'post'
            const idGrupo = this.contratoGrupo.id_contrato_grupo ? `/${this.contratoGrupo.id_contrato_grupo}` : '' 

            axios[method](`${baseApiUrl}/contratosgrupos${idGrupo}`, this.contratoGrupo)
            .then(() => {
                this.$toasted.success('Grupo de contrato salvo com sucesso.')
                this.resetGruposContrato()
            })
            .catch(err => {
                showError(err)
            })            
        },

        removeGrupoContrato(c) {
            axios['delete'](`${baseApiUrl}/contratosgrupos/${c.id_contrato_grupo}`)
            .then(() => {
                this.$toasted.success('Grupo de contrato removido com sucesso.')
                this.resetGruposContrato()
            })
            .catch(err => {
                showError(err)
            })  
        },

        resetGruposContrato() {
            this.loadContratoGrupos()
            this.contratoGrupo = {
                id_contrato_grupo: '',
                descricao: ''
            }
        },

        // Comissão
        addComissao() {
            this.resetComissao()
            this.loadComissoes()
            this.modalAddComissao = true
            this.modeComissao = 'new'
        },
        salvarComissao() {
            if (!(this.comissao.id_comissao_faturamento > 0)) {
                if (this.comissaoSelecionada.e_porcentagem === 'S') {
                    if (!this.comissao.taxa_comissao > 0) return this.$toasted.error('A taxa da comissão deve ser maior que zero.') 
                } else {
                    if (!this.comissao.valor_comissao > 0) return this.$toasted.error('O valor da comissão deve ser maior que zero.') 
                }
            }

            if (!(this.comissao.id_pessoa_comissionamento > 0) && !(this.comissao.id_comissao_faturamento > 0)) return this.$toasted.error('Selecione o comissionamento.') 

             
            this.modeComissao === 'edit' ? this.contrato.comissoes.splice(this.index, 1, {...this.comissao}) : this.contrato.comissoes.push(this.comissao)    
            this.resetComissao()     
            this.calculaComissoes(this.contrato.valor_base_comissao, this.contrato.comissoes)                         
            
        },
        enumeraComissoes(){  
            this.contrato.comissoes.forEach((_, i) =>{                
                this.contrato.comissoes[i].seq = i+1
            })
        },
        async calculaComissoes(vlrBase, arrComiss) {
            let comissFatur = []

            if (vlrBase && arrComiss) {
                arrComiss.forEach(i => {
                    if (i.id_comissao_faturamento > 0) {
                        comissFatur.push(i.id_comissao_faturamento)
                    } else {
                        if (i.taxa_comissao > 0) {
                            i.valor_comissao_calculada = (vlrBase * i.taxa_comissao) / 100
                        } else {
                            i.valor_comissao_calculada =  i.valor_comissao
                        }
                    }
                })

                if (comissFatur.length > 0) {
                    let calculaComissFatur = await this.calculaComissFatura(comissFatur, this.contrato.data)

                    this.testes =calculaComissFatur
                }
            }
            this.contrato.comissoes = [...arrComiss]
        },

        loadRelComissoes() {
            this.relComissao.tableBusy = true

            axios.get(`${baseApiUrl}/relcomissoes`, {
                params: {
                    dataInicio: this.relComissao.intervaloComp[0] ? moment(this.relComissao.intervaloComp[0]).format('yyyy-MM-DD') : null,
                    dataFim: this.relComissao.intervaloComp[1] ? moment(this.relComissao.intervaloComp[1]).format('yyyy-MM-DD') : null,
                    idComissionado: this.relComissao.idComissionado
                }
            }).then(res => {
                this.relComissao.relatorio = res.data
                this.relComissao.tableBusy = false
            }).catch(err => {
                this.relComissao.relatorio = []
                this.relComissao.tableBusy = false
                showError(err)
            }) 
        },

        printPage() {
            const printWindow = window.open('', '_blank');
            const content = document.getElementById('rel-comiss-render-pdf').innerHTML;  
            
            let tipoDreImpresso = ''

            switch (this.tipoDre) {
                case 'S':
                    tipoDreImpresso = 'Sintético'
                    break;
                case 'R':
                    tipoDreImpresso = 'Resumido'
                    break;                
                default:
                    tipoDreImpresso = 'Analítico'
                    break;
            }

            const titulo = `DRE_${moment(this.dreDataInicial).format('DD-MM-YYYY')}_ATÉ_${moment(this.dreDataFinal).format('DD-MM-YYYY')}_(${tipoDreImpresso})`

            printWindow.document.write(`
            <html>
                <head>
                    <title>${titulo}</title>
                    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">  
                    <style>
                        .small-text {
                            font-size: 0.75rem;
                        }
                    </style>                  
                </head>
                <body>
                    <div>${content}</div>
                </body>
            </html>
            `);

            printWindow.document.close();

            // Aguarde o carregamento do conteúdo e, em seguida, imprima
            printWindow.onload = function() {
                printWindow.print();
                printWindow.close();                
            };
        },

        async calculaComissFatura(comissoes, dataComp) {
            let comiss = []

            axios.get(`${baseApiUrl}/calcularcomissfaturamento`, {
                params: {
                    idContrato: this.contrato.id_contrato,
                    comissoes,
                    dataComp,
                    baseComissaoAtual: this.contrato.valor_base_comissao > 0 ? this.contrato.valor_base_comissao : this.contrato.total_servicos,
                    totalContratoAtual: this.contrato.total_servicos,
                    baseContratoAtual: this.contrato.valor_base_contrato > 0 ? this.contrato.valor_base_contrato : this.contrato.total_servicos  // VGV
                }
            }).then(res => {
                comiss = res.data
                    
                comiss.forEach(comissao => {
                    const itemIndex = this.contrato.comissoes.findIndex(c => c.id_comissao_faturamento === comissao.id_comissao_faturamento);
                    if (itemIndex !== -1) {
                        // Atualiza o item existente
                        this.$set(this.contrato.comissoes, itemIndex, {
                            ...this.contrato.comissoes[itemIndex],
                            taxa_comissao: comissao.porc_comissao,
                            valor_comissao_calculada: comissao.valor_comissao_calculada
                        });
                    }
                });

            }).catch(err => {
                showError(err)
            }) 

            return comiss
        },
        removeComissao() {            
            this.contrato.comissoes.splice(this.index, 1)
            this.modalExcluirComissao = false
            this.resetComissao()
        },
        loadComissoes() {             
            const url = `${baseApiUrl}/comissoes`    

            axios.get(url)
            .then(res => {
                 this.comissoes = res.data.map(comissao => {
                    return { ...comissao,  
                        value: comissao.id_pessoa_comissionamento > 0 ? comissao.id_pessoa_comissionamento : comissao.id_comissao_faturamento, 
                        text: `${comissao.razao_social} | ${comissao.descricao} (${comissao.e_pagto_recebimento === 'S' ? 'Recebimento' : 'Contratação/Venda'})`
                    }
                })
                this.modalAddComissao = true
            })
            .catch(err => {
                showError(err)
            })            
        },
        async loadComissao(c) {  
            await this.loadComissoes()

            this.modeComissao = 'edit'
            this.comissao = { ...c }                     
            this.index = c.seq - 1
            
            if (this.comissao.id_pessoa_comissionamento > 0) {                
                this.comissaoSelecionada  = {
                    value: this.comissao.id_pessoa_comissionamento, 
                    text: `${this.comissao.comissionamento}`,
                    e_porcentagem: c.taxa_comissao > 0 ? 'S' : 'N',
                    taxa_valor_comissao: c.taxa_comissao > 0 ? c.taxa_comissao : c.valor_comissao,
                    detalhes_comissao: c.detalhes_comissao,
                }
            }            
            if (this.comissao.id_comissao_faturamento > 0) {                
                this.comissaoSelecionada  = {
                    value: this.comissao.id_comissao_faturamento, 
                    text: `${this.comissao.comissionamento}`,
                    e_porcentagem: c.taxa_comissao > 0 ? 'S' : 'N',
                    taxa_valor_comissao: c.taxa_comissao > 0 ? c.taxa_comissao : c.valor_comissao,
                    detalhes_comissao: c.detalhes_comissao,
                }
            }            
                      
            this.modalAddComissao = true
        },
        resetComissao() {
            this.modalAddComissao = false
            this.comissao = {
                id_pessoa_comissionamento: '',
                id_comissao_faturamento: '',
                taxa_comissao: 0,
                valor_comissao: 0,
                detalhes_comissao: '',
                e_porcentagem: 'S'
            }  
            this.comissaoSelecionada = ''
            this.enumeraComissoes()           
        },
        desejaRemoverComissao(s){
            this.index = s.seq - 1
            this.modalExcluirComissao = true
        },

        // Serviços
        addServico() {
            this.resetServico()
            this.loadServicos()
            this.loadVendedores()
            this.modalAddServico = true
            this.modeServico = 'new'
        },
        salvarServico() {
            if (this.servico.qtde < 1) {
                this.$toasted.error('Informe a quantidade do serviço.') 
            } else {
                this.calculaItem()   
                this.modeServico === 'edit' ? this.contrato.itens.splice(this.index, 1, {...this.servico}) : this.contrato.itens.push(this.servico)    
                this.resetServico()                               
            }
        },
        async loadServico(s) {  
            await this.loadServicos()
            this.loadVendedores()

            this.modeServico = 'edit'
            this.servico = { ...s }                     
            this.index = s.seq - 1
            
            if (this.servico.id_servico) {
                
                this.servicoSelecionado  = {
                    value: this.servico.id_servico, 
                    text: `${this.servico.id_servico} | ${this.servico.descricao}`, 
                    descricao: this.servico.descricao,
                    preco: this.servico.unitario
                }
            }

            if (this.servico.id_vendedor) {

                this.vendedorSelecionado = {
                    value: this.servico.id_vendedor, 
                    text: `${this.servico.id_vendedor} | ${this.servico.razao_social}`,
                    razao_social: this.servico.razao_social
                }
            }
            
                      
            this.modalAddServico = true
        },
        enumeraItens(tipo){
            if (tipo !== 'edição') {
                this.contrato.total_servicos = 0
                this.contrato.valor_base_comissao = 0
            }

            this.contrato.itens.forEach((_, i) =>{                
                this.contrato.itens[i].seq = i+1
                this.contrato.itens[i].total = this.contrato.itens[i].qtde * this.contrato.itens[i].unitario

                if (tipo !== 'edição') {
                    this.contrato.total_servicos += this.contrato.itens[i].total
                    this.contrato.valor_base_comissao += this.contrato.itens[i].total
                }
            })
        },
        relatorios() {

        },

        desejaExcluirContratos() {
            if (this.contratosSelecExcluir.length > 0) {

                for (let i = this.contratosSelecExcluir.length -1; i > -1; i-- ) {
                    if (this.contratosSelecExcluir[i].status === 'F') { 
                        this.contratosSelecExcluir.splice(i, 1) 
                    }                
                }

                if (this.contratosSelecExcluir.length <= 0) {
                    showError('Contratos faturados não podem ser excluídos.')
                    this.clearSelected()
                    return
                }

                this.modalExcluirContrato = true   

            } else {
                showError('Ao menos um contrato deve ser selecionado.')
            }
        },

        excluiContratos() {            
            const contr = this.contratosSelecExcluir.map(c=> {return c.id_contrato})  
            
            axios.delete(`${baseApiUrl}/contratos`, {
                params: {
                    contratos: contr
                }
            })
            .then(() => { 
                this.contratosSelecExcluir = []
                this.modalExcluirContrato = false            
                this.loadContratos()  
                showSuccess('Contratos excluídos com sucesso.!')
            }) 
            .catch(err => {
                this.modalExcluirContrato = false  
                showError(err)
            })
        },
        desejaRemover(s){
            this.index = s.seq - 1
            this.modalRemServico = true
        },
        removeServico() {            
            this.contrato.itens.splice(this.index, 1)
            this.modalRemServico = false
            this.resetServico()
        },
        resetServico() {
            this.modalAddServico = false
            this.servico = {
                id_servico: '',
                descricao: '',
                qtde: 1,
                unitario: 0,
                detalhes: ''
            }  
            this.servicoSelecionado = ''
            this.vendedorSelecionado = ''
            this.enumeraItens(null)           
        },
        calculaItem() {
            const qtde = ((!this.servico.qtde) || (this.servico.qtde < 0)) ? 0 : this.servico.qtde
            const unitario = ((!this.servico.unitario) || (this.servico.unitario < 0)) ? 0 : this.servico.unitario
            
            this.servico.total = qtde * unitario  
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadContratos()
        }, 
        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data.map(pessoa => {
                    return { ...pessoa, value: pessoa.idpessoa, text: `| ${pessoa.razao_social}` }
                })
            })
        },
        
        async loadServicos() {  
            const url = `${baseApiUrl}/servicos` 

            await axios.get(url).then(res => {
                this.servicos = res.data.map(servico => {
                    return { ...servico, value: servico.id_servico, text: `${servico.id_servico} | ${servico.descricao}` }
                })
                
            }) 
            .catch(showError)           
        },  
        loadVendedores() {  
            const url = `${baseApiUrl}/vendedores`

            axios.get(url).then(res => {
                this.vendedores = res.data.map(vendedor => {
                    return { ...vendedor, value: vendedor.idpessoa, text: `${vendedor.idpessoa} | ${vendedor.razao_social}` }
                })
            })
        }, 
        onSelect(p) {
            this.contrato.id_pessoa = p.idpessoa
        }, 
        loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma, value: forma.id_forma_pagar_receber, text: `${forma.id_forma_pagar_receber} | ${forma.descricao}` }
                })
            })
        },   
        loadComissionados() {
            const url = `${baseApiUrl}/comissionados`
            axios.get(url).then(res => {
                this.relComissao.comissionados = res.data.map(comissionado => {
                    return { ...comissionado, value: comissionado.idpessoa, text: ` | ${comissionado.comissionado}` }
                })
            })
        },   
        loadPlanoContas(exibeTodas) {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
                
                if(exibeTodas) this.planoContas.unshift({value: 0, text: 'Todas as Receitas' })
            })
        },

        loadContratos() { 
            this.showOverContratos = true
            
            axios.get(`${baseApiUrl}/consulta/cadastros/?tipo=cadcontrato&page=${this.page}`, {
                params: {
                    consulta: this.consulta
                }
            })
            .then(res => {
                this.showOverContratos = false
                this.contratos = res.data.data
                this.contratos.forEach(c=> {
                    const rest = (moment(c.encerramento).diff(moment(), 'days')+1)
                    c.encerramento = moment(c.encerramento).format('DD/MM/yyyy')

                    if (rest < 0) {
                        c.restante = 0
                        c.diaDias = 'dias'
                    }
                    if (rest === 1) {
                        c.restante = 1
                        c.diaDias = 'dia'
                    }
                    if (rest > 1) {
                        c.restante = rest
                        c.diaDias = 'dias'
                    }
                })
                this.count = res.data.count 
                this.limit = res.data.limit
            }) 
            .catch(err => {
                this.showOverContratos = false
                showError(err)
            }) 
        },

        async loadContrato(contrato, mode = 'save') {
            
            this.showOver = true

            const url = `${baseApiUrl}/contratos/${contrato.id_contrato}`    

            await axios.get(url).then(res => {
                
                this.showOver = false
                this.show = false
                
                this.mode = mode
                this.contrato = { ...res.data }
                this.contrato.data = moment(this.contrato.data).format('yyyy-MM-DD')
                this.contrato.encerramento = moment(this.contrato.encerramento).format('yyyy-MM-DD')
                this.contrato.primeiro_vencimento = moment(this.contrato.primeiro_vencimento).format('yyyy-MM-DD')
                this.pessoaSelecionada = {value: this.contrato.id_pessoa, text: `| ${this.contrato.razao_social}`}
                this.grupoSelecionado = this.contrato.id_contrato_grupo > 0 ? {value: this.contrato.id_contrato_grupo, text: `| ${this.contrato.grupo_contrato}`} : ''
                this.formaSelecionada = {value: this.contrato.id_forma_pagar_receber, text: `${this.contrato.id_forma_pagar_receber} | ${this.contrato.descricao_forma}`}
                this.planoSelecionado = {value: this.contrato.id_plano_conta, text: `${this.contrato.id_plano_conta} | ${this.contrato.descricao_plano}`}
                
                this.loadPessoas()
                this.loadContratoGrupos()                
                this.loadPlanoContas(false)
                this.loadFormas()
                this.enumeraItens('edição')
                this.enumeraComissoes()
                // this.calculaComissoes(this.contrato.valor_base_comissao, this.contrato.comissoes)

                
            }) 
            .catch(err => {
                showError(err)
                this.show = true
                this.showOver = false
            })
        },        
        reset() {
            this.mode = 'new'
            
            this.contrato = {
                id_contrato: '',
                id_pessoa: '',
                id_plano_conta: '',
                id_forma_pagar_receber: '',
                periodicidade: 30,
                data: moment().format('YYYY-MM-DD'),
                primeiro_vencimento: moment().format('YYYY-MM-DD'),
                encerramento: moment().add(6, 'months').format('YYYY-MM-DD'), 
                qtde_parcelas: 6,
                desc_ate_vencto: 0,
                obs: '',
                instrucoes: '',
                obs_fiscais: '',
                itens: [],
                comissoes: [],
                e_auto_faturamento: 'N',
                e_auto_nf: 'N',
                e_auto_boleto: 'N',
                emitir_bol_dias: 5, 
            }
            this.pessoaSelecionada = ''
            this.grupoSelecionado = ''
            this.formaSelecionada = ''
            this.planoSelecionado = ''            
            
            this.show = true
        },

        save() {
            const method = this.contrato.id_contrato ? 'put' : 'post'
            const idcontrato = this.contrato.id_contrato ? `/${this.contrato.id_contrato}` : ''     

            axios[method](`${baseApiUrl}/contratos${idcontrato}`, this.contrato)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
                this.loadContratos()
            })
            .catch(showError)            
        },
        
        gerarFaturas() {              
            this.isBusyFat = true 

            this.parametrosContratos.contratos = this.contratosSelecionados             

            axios['post'](`${baseApiUrl}/gerar_faturas`, this.parametrosContratos)
                .then((res) => {
                    this.isBusyFat = false
                    this.faturas = res.data.faturas
                    this.naoFaturados = res.data.naoFaturados 
                    
                     
                    if (this.faturas.length > 0) {

                        if (this.naoFaturados.length > 0) {
                            this.tabFaturas = 1
                            this.tabFat = 1
                            showAlert('Alguns contratos não foram faturados! <br>Verifique o motivo na aba "Erros"')
                        } else {
                            this.tabFaturas = 1
                            this.tabFat = 0
                            showSuccess('Contratos faturados com sucesso')
                        }                        
                    } else {
                        this.tabFaturas = 1
                        this.tabFat = 1 
                        showError('Nenhuma fatura gerada! <br>Verifique o motivo na aba "Erros"')
                    }              
                    
                    this.reset()
                })
            .catch(err => {
                this.isBusyFat = false
                this.tabFaturas = 0
                showError(err)
            })  
        },
        async aditivoContrato(c) {              
            await this.loadContrato(c) 

            delete this.contrato.id_contrato
            this.contrato.id_contrato_adicao = c.id_contrato
            this.contrato.e_aditivo = 'S'
            this.contrato.obs = `Aditivo para o contrato nº ${c.num_contrato}`
            this.contrato.data = moment().format('yyyy-MM-DD')
            this.contrato.primeiro_vencimento = moment().add(c.periodicidade, 'days').format('YYYY-MM-DD')
            this.contrato.encerramento = moment().add((c.periodicidade * c.qtde_parcelas), 'days').format('YYYY-MM-DD')
        },

    },
    mounted() {
        this.loadFiliais(),
        this.loadPlanoContas(true)
    },
    watch: {
        intervaloVenc() {
            this.consulta.dataIniVenc = this.intervaloVenc[0] ? moment(this.intervaloVenc[0]).format('YYYY-MM-DD') : null
            this.consulta.dataFimVenc = this.intervaloVenc[1] ? moment(this.intervaloVenc[1]).format('YYYY-MM-DD') : null
        },
        intervaloComp() {
            this.consulta.dataIniComp = this.intervaloComp[0] ? moment(this.intervaloComp[0]).format('YYYY-MM-DD') : null
            this.consulta.dataFimComp = this.intervaloComp[1] ? moment(this.intervaloComp[1]).format('YYYY-MM-DD') : null
        },
        pessoaSelecionada() {
            if (this.pessoaSelecionada) {
                this.contrato.id_pessoa = this.pessoaSelecionada.value
            } else {
                this.contrato.id_pessoa = ''
            }
        },
        grupoSelecionado() {
            if (this.grupoSelecionado) {
                this.contrato.id_contrato_grupo = this.grupoSelecionado.value
            } else {
                this.contrato.id_contrato_grupo = ''
            }
        },
        formaSelecionada() {
            if (this.formaSelecionada) {
                this.contrato.id_forma_pagar_receber = this.formaSelecionada.value
            } else {
                this.contrato.id_forma_pagar_receber = ''
            }
        },        
        comissionadoSelecionado() {
            if (this.comissionadoSelecionado) {
                this.relComissao.idComissionado = this.comissionadoSelecionado.value
                this.relComissao.comissionado = this.comissionadoSelecionado.comissionado
            } else {
                this.relComissao.idComissionado = ''
                this.relComissao.comissionado = 'Todos'
            }
        },        
        planoSelecionado() {
            if (this.planoSelecionado) {
                this.contrato.id_plano_conta = this.planoSelecionado.value
            } else {
                this.contrato.id_plano_conta = ''
            }
        },
        servicoSelecionado() {
            if (this.servicoSelecionado) {
                this.servico.id_servico = this.servicoSelecionado.value
                this.servico.descricao = this.servicoSelecionado.descricao
                this.servico.unitario = this.servicoSelecionado.unitario || this.servicoSelecionado.preco
            } else {
                this.servico.id_servico = ''
                this.servico.descricao = ''
                this.servico.unitario = 0
            }
        },
        comissaoSelecionada() {
            if (this.comissaoSelecionada) {
                if (this.comissaoSelecionada.id_pessoa_comissionamento > 0) {
                    this.comissao.id_pessoa_comissionamento = this.comissaoSelecionada.value
                }
                if (this.comissaoSelecionada.id_comissao_faturamento > 0) {
                    this.comissao.id_comissao_faturamento = this.comissaoSelecionada.value
                }
                this.comissao.comissionamento = this.comissaoSelecionada.text
                this.comissao.detalhes_comissao = this.comissaoSelecionada.detalhes_comissao
                this.comissao.e_porcentagem = this.comissaoSelecionada.e_porcentagem
                if (this.comissaoSelecionada.e_porcentagem === 'S') {
                    this.comissao.taxa_comissao = this.comissaoSelecionada.taxa_valor_comissao
                    this.comissao.valor_comissao = 0
                } else {
                    this.comissao.valor_comissao = this.comissaoSelecionada.taxa_valor_comissao
                    this.comissao.taxa_comissao = 0
                }
            } else {
                this.comissao.id_pessoa_comissionamento = ''
                this.comissao.id_comissao_faturamento = ''
                this.comissao.comissionamento = ''
                this.comissao.taxa_comissao = 0
                this.comissao.valor_comissao = 0
                this.comissao.detalhes_comissao = ''
                this.comissao.e_porcentagem = 'S'
            }
        },
        vendedorSelecionado() {
            if (this.vendedorSelecionado) {
                this.servico.id_vendedor = this.vendedorSelecionado.value
                this.servico.razao_social = this.vendedorSelecionado.razao_social
            } else {
                this.servico.id_vendedor = ''
                this.servico.razao_social = ''
            }
        },
        page() {
            this.loadContratos()
        },
        idmodelo() {
            this.selectModelo()
        },
        modalImprimeContrato() {
            this.impresso = ''
            this.idmodelo = 0
            this.nomeModelo = 'contrato'
            this.editaModelo = false
        },
        'servico.unitario': function() {
            this.calculaItem()
        },
        'contrato.e_base_descontado_impostos': function() {
            if (this.contrato.e_base_descontado_impostos === 'S') {
                this.showOverBaseComiss = true;
                setTimeout(function() {
                    this.showOverBaseComiss = false;
                }.bind(this), 1000); // Usando bind para garantir o contexto
            } else {
                this.contrato.valor_base_comissao = this.contrato.total_servicos
            }
        },
        'contrato.valor_base_comissao': function() {
            if (this.contrato.valor_base_comissao > 0) {
                // Se já houver um timeout, cancelamos o anterior
                if (this.debounceTimeout) {
                    clearTimeout(this.debounceTimeout)
                }
                // Definimos o novo timeout para 1,5 segundos
                this.debounceTimeout = setTimeout(function() {
                    this.calculaComissoes(this.contrato.valor_base_comissao, this.contrato.comissoes)
                }.bind(this), 1500); // Usando bind para garantir o contexto
            }
        }
    }
    
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .row-altera-contrato {
        margin-bottom: -40px !important;
    }
    .sw-altera-contrato {
        margin-top: 30px !important;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }  

    .gradient-card2 {
        background: linear-gradient(to right, #f0f8ff, #e6f7ff); /* Fundo degradê clarinho */
        border: 1px solid #d1e7dd; /* Borda sutil (opcional) */
        border-radius: 0.25rem; /* Borda arredondada (opcional) */
    }

    .gradient-card {
        margin: 0;
        padding: 0 !important;
        background: linear-gradient(to right, #ffebee, #ff52528c); /* Gradiente em tons de vermelho do Adobe */
        border: 1px solid #d33131c2; /* Borda em vermelho escuro */
        border-radius: 0.25rem; /* Borda arredondada */            
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra leve */
    }

    .containerRoll {
        height: calc(100vh - 200px); /* Ajuste a altura de acordo com a altura do card fixo */
        overflow-y: auto;
        scrollbar-width: none; /* Para navegadores Firefox */
        -ms-overflow-style: none; /* Para Internet Explorer e Edge */
        padding-bottom: 60px;
    }

    .containerRoll::-webkit-scrollbar {
        display: none; /* Para navegadores WebKit (Chrome, Safari) */
    }

    .sticky-card-cont {
        position: sticky;
        top: 0; 
        background-color: #f9f9f9;
        z-index: 5; /* Para garantir que fique acima de outros elementos */
    }

    .div-topo-b {
        background: linear-gradient(to bottom, white, transparent);
        height: 50px;
        margin-top: -16px;

        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
        border-image: linear-gradient(to bottom, rgb(223, 222, 222), transparent) 1;
    }

    .teste {
    color: #186dde; /* primary */
    color: #0acc95; /* secondary */
    color: #7460ee; /* info */
    color: #13DEB9; /* success */
    color: #fc4b6c; /* accent */
    color: #fec90f; /* warning */
    color: #ef5350; /* error */
    color: #f5fcfd; /* lightprimary */
    color: #E8F7FF; /* lightsecondary */
    color: #E6FFFA; /* lightsuccess */
    color: #FDEDE8; /* lighterror */
    color: #FEF5E5; /* lightwarning */
    color: #EBF3FE; /* lightinfo */
    color: #2A3547; /* textPrimary */
    color: #2A3547; /* textSecondary */
    color: #e5eaef; /* borderColor */
    color: #DFE5EF; /* inputBorder */
    color: #ffffff; /* containerBg */
    color: #f4f6f9; /* background */
    color: #f6f9fc; /* hoverColor */
    color: #fff; /* surface */
    color: #fff; /* on-surface-variant */
    color: #F2F6FA; /* grey100 */
    color: #EAEFF4; /* grey200 */
    color: #1d2228; /* muted */
    color: #1d1d1d; /* borderline */
}







</style>