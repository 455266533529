<template>
    <div class="cadastro-orcamentos">       
        
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'"
                @click="remove">
                    <i class="fa fa-trash button-icons"></i>
                    Excluir
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save' && !visualizar" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novoOrcamento">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        :readonly="mode === 'remove'" />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="status"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="O">Orçado</b-form-radio>
                            <b-form-radio value="A">Aprovado</b-form-radio>
                            <b-form-radio value="F">Faturado</b-form-radio>
                            <b-form-radio value="C">Cancelado</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-overlay :show="showOver" rounded="sm">
            <b-card class="cadastros-card-corpo" v-show="!show">
                <b-form>
                    <input id="orcamentos-id" type="hidden" v-model="orcamento.id_servico" />

                    <b-row> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Nº Orçamento" label-for="num-orcamento"> 
                                <b-form-input id="num-orcamento" type="number"
                                    v-model="orcamento.num_orcamento"
                                    readonly/>
                            </b-form-group>
                        </b-col> 

                        <b-col md="4" sm="12">
                            <b-form-group label="Data Orçamento" label-for="data-docto-orcamento">
                                <b-form-input id="data-docto-orcamento" type="date"
                                    v-model="orcamento.data"
                                    readonly/>
                            </b-form-group>
                        </b-col>
                    </b-row> 
                    <b-row style="margin-top: -20px"> 
                        <b-col md="6" sm="12">    
                            <b-form-group label="Pessoa" label-for="orcamento-pessoa">
                                <Autocomplete
                                    id="orcamento-pessoa"
                                    :options="pessoas"
                                    label-key="razao_social"
                                    value-key="idpessoa"
                                    v-model="orcamento.razao_social"
                                    placeholder=""
                                    @shouldSearch="loadPessoas"
                                    @select="onSelect"
                                    :disabled="visualizar"
                                />
                            </b-form-group>
                        </b-col> 

                        <b-col md="6" sm="12">
                            <b-form-group label="Vendedor" label-for="vendedor-orcamento">
                                <b-form-select id="vendedor-orcamento" v-model="orcamento.id_vendedor" :options="vendedores" :disabled="visualizar"/>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <br>

                    <b-card header="Negociação" header-bg-variant="light">  
                        <b-row> 
                            <b-col md="4" sm="12">
                                <b-form-group label="Plano de Contas" label-for="orcamento-plano-conta">
                                    <b-form-select id="orcamento-plano-conta" v-model="orcamento.id_plano_conta" :options="planoContas" :disabled="visualizar"/>
                                </b-form-group>
                            </b-col>  

                            <b-col md="4" sm="12">
                                <b-form-group label="Forma" label-for="orcamento-forma">
                                    <b-form-select id="orcamento-forma" v-model="orcamento.id_forma_pagar_receber" :options="formas" :disabled="visualizar"/>
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="12">
                                <b-form-group label="Condição" label-for="orcamento-cond">
                                    <b-form-select id="orcamento-cond" v-model="orcamento.id_condicao_pagar_receber" :options="condicoes" :disabled="visualizar"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group label="Desc. até o Vencto" label-for="desc-ate-venc-orcamento">
                                    <money id="desc-ate-venc-orcamento" v-model="orcamento.desc_ate_vencto" v-bind="percent" required class="form-control" :disabled="visualizar"/>                                        
                                </b-form-group>
                            </b-col>
                        </b-row>                  
                    </b-card>
                    <br>                    

                    <b-card header="Serviços" header-bg-variant="light">  
                        <b-row> 
                            <div>
                                <b-button variant="primary" @click="addServico" :disabled="visualizar">
                                    <i class="fa fa-plus"></i> Adicionar Serviços
                                </b-button>                            
                            </div>
                        </b-row>

                        <b-modal 
                            hideBvModalEvent
                            v-model="modalAddServico"                        
                            id="modal-tipos" 
                            centered size="xl" 
                            ok-only
                            ok-disabled
                            title="Adicionar serviço"
                        >  
                            
                            <b-row> 
                                <b-col md="5" sm="12">
                                    <b-form-group label="Serviço" label-for="servico-name-input">
                                        <b-form-select id="servico-name-input" v-model="servico.id_servico" :options="servicos" />
                                    </b-form-group>                                                      
                                </b-col>

                                <b-col md="2" sm="12">
                                    <b-form-group label="Qtde" label-for="add-servico-qtde"> 
                                        <b-form-input id="add-servico-qtde" type="number"
                                            v-model="servico.qtde"
                                            :readonly="mode === 'remove'" 
                                            required
                                            @input="calculaServicos()"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col md="2" sm="12">
                                    <b-form-group label="Unitário" label-for="add-servico-unit">  
                                        <money id="add-servico-unit" v-bind="moneyItens" v-model="servico.unitario" class="form-control"/> 
                                    </b-form-group>
                                </b-col>

                                <b-col md="3" sm="12">
                                    <b-form-group label="Total" label-for="add-servico-total">
                                        <money id="add-servico-total" v-model="servico.total" v-bind="moneyItens" readonly class="form-control"/>                                        
                                    </b-form-group>
                                </b-col>

                            </b-row> 

                            <b-row style="margin-top:-25px">  
                                <b-col md="12" sm="12"> 
                                    <b-form-group
                                    label="Detalhes"
                                    label-for="add-servico-det"
                                    class="mb-0"
                                    >
                                        <b-form-textarea
                                            id="add-servico-det"
                                            v-model="servico.detalhes"
                                        ></b-form-textarea>
                                    </b-form-group> 
                                </b-col>                
                            </b-row>                           
                            
                            
                            <template #modal-footer>
                                <div class="w-100">                               

                                    <b-col md="10" sm="10">
                                        <b-button variant="primary" v-if="modeAddServico === 'save'"
                                            @click="salvarServico">Salvar</b-button>
                                        <b-button class="ml-2" @click="resetServico">Cancelar</b-button>
                                    </b-col>
                                </div>
                            </template>                  
                        </b-modal>
                        

                        <b-row  v-if="orcamento.servicos.length > 0">
                            <b-table class="mt-2" hover striped :items="orcamento.servicos" :fields="fieldsOrcamentoServicos" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="warning" @click="loadOrcamentoServItem(data.item)" class="mr-2" v-if="!visualizar">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button variant="danger" @click="desejaRemover(data.item)" v-if="!visualizar">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>

                        </b-row>

                        <b-modal id="modal-remove-servico" title="Deletar Serviço" v-model="modalRemServico"   >
                            <p class="my-4">Deseja realmente excluir este serviço?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeServico()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-servico')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>

                        </b-modal>                
                    </b-card>
                    <br>                    

                    <b-row>  
                        <b-col md="12" sm="12"> 
                            <b-form-group
                            label="Observações"
                            label-for="orcamento-obs"
                            class="mb-0"                            
                            >
                                <b-form-textarea
                                    :readonly="visualizar"
                                    id="orcamento-obs"
                                    v-model="orcamento.obs"
                                ></b-form-textarea>
                            </b-form-group> 
                        </b-col>                
                    </b-row>                   

                    <br>
                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                            <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                            <b-popover target="popover-target-1" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{orcamento.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{orcamento.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{orcamento.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{orcamento.data_alt}}
                            </b-popover>                        
                        </b-col>
                    </b-row>

                </b-form>

            </b-card>

        </b-overlay>   

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'"
                    @click="remove">
                        <i class="fa fa-trash button-icons"></i>
                        Excluir
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save' && !visualizar"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novoOrcamento">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>   

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="orcamentos" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadOrcamento(data.item)" class="mr-2">
                        <i :class="((data.item.status === 'F') || (data.item.status === 'C')) ? 'fa fa-eye' : 'fa fa-pencil' "></i>
                    </b-button>
                    <b-dropdown id="dropdown-right" variant="link" right no-caret style="margin: -15px;" toggle-class="text-decoration-none">
                        <template #button-content>
                            <b-button variant="primary" style="padding-left: 13px; padding-right: 13px">
                                <i class="fa fa-caret-down"></i>
                            </b-button>
                        </template>
                        <b-dropdown-item href="#" @click="faturar(data.item)"><i class="fa fa-gavel mr-1"></i> Faturar</b-dropdown-item>
                        <b-dropdown-item href="#" @click="desejaExcluir(data.item)"><i class="fa fa-trash mr-1"></i> Excluir</b-dropdown-item>
                        <b-dropdown-item href="#" @click="loadModelos(data.item)"><i class="fa fa-print mr-1"></i> Imprimir</b-dropdown-item>
                        <hr>
                        <p style="font-size: 0.9rem; margin: -10px 0 0 15px">Alterar para:</p>
                        <b-dropdown-item href="#" @click="alerarStatus(data.item, 'O')"><div style="color: #8d8dfd">Orçado</div></b-dropdown-item>
                        <b-dropdown-item href="#" @click="alerarStatus(data.item, 'A')"><div style="color: #5dc289">Aprovado</div></b-dropdown-item>
                        <b-dropdown-item href="#" @click="alerarStatus(data.item, 'F')"><div style="color: #ffcc30">Faturado</div></b-dropdown-item>
                        <b-dropdown-item href="#" @click="alerarStatus(data.item, 'C')"><div style="color: #f93b69">Cancelado</div></b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card> 

        <b-modal id="modal-exclui-orc" title="Excluir Orçamento" v-model="modalExcluiOrc"   >
            <p class="my-4">Deseja realmente excluir este orçamento?</p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="remove"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-exclui-orc')"
                    >
                        Não
                    </b-button>
                </div>
            </template>

        </b-modal> 

        <b-modal 
            id="modal-faturar-orcamento" 
            title="Faturar orçamento" 
            v-model="modalFatOrc" 
            size="lg" 
        >            

            <b-card no-body class="p-3">
                <b-form>
                    <input id="contratos-id" type="hidden" v-model="contrato.id_servico" />

                    <b-row>  
                        <b-col md="4" sm="12">
                            <b-form-group label="Data Contrato" label-for="data-docto-contrato">
                                <b-form-input id="data-docto-contrato" type="date"
                                    v-model="contrato.data"
                                    readonly/>
                            </b-form-group>
                        </b-col> 

                        <b-col md="8" sm="12">
                            <b-form-group label="Pessoa" label-for="fat-contrato-pessoa"> 
                                <b-form-input id="fat-contrato-pessoa" type="text"
                                    v-model="contrato.razao_social"
                                    readonly/>
                            </b-form-group>
                        </b-col>                
                    </b-row>

                    <b-card header="Negociação" header-bg-variant="light">  
                        <b-row> 
                            <b-col md="4" sm="12">
                                <b-form-group label="Plano de Contas" label-for="contrato-plano-conta">
                                    <b-form-select id="contrato-plano-conta" v-model="contrato.id_plano_conta" :options="planoContas" />
                                </b-form-group>
                            </b-col>  

                            <b-col md="4" sm="12">
                                <b-form-group label="Forma" label-for="contrato-forma">
                                    <b-form-select id="contrato-forma" v-model="contrato.id_forma_pagar_receber" :options="formas" />
                                </b-form-group>
                            </b-col>

                            <b-col md="4" sm="12">
                                <b-form-group label="Periodicidade" label-for="periodicidade-contrato"> 
                                    <b-form-input id="periodicidade-contrato" type="number"
                                        v-model="contrato.periodicidade"
                                        :readonly="mode === 'remove'" required/>
                                </b-form-group>
                            </b-col> 
                        </b-row>

                        <b-row>
                            <b-col md="3" sm="12">
                                <b-form-group label="Primeiro Vencimento" label-for="pri-vencimento-contrato">
                                    <b-form-input id="pri-venc-contrato" type="date"
                                        v-model="contrato.primeiro_vencimento"
                                        :readonly="mode === 'remove'" required/>
                                </b-form-group>
                            </b-col> 
                            
                            <b-col md="3" sm="12">
                                <b-form-group label="Encerramento" label-for="encerramento-contrato">
                                    <b-form-input id="encerramento-contrato" type="date"
                                        v-model="contrato.encerramento"
                                        :readonly="mode === 'remove'" required/>
                                </b-form-group>
                            </b-col> 
                                                       
                            <b-col md="3" sm="12">
                                <b-form-group label="Qtde. Parcelas" label-for="q-parcelas-contrato"> 
                                    <b-form-input id="q-parcelas-contrato" type="number"
                                        v-model="contrato.qtde_parcelas"
                                        :readonly="mode === 'remove'" required/>
                                </b-form-group>
                            </b-col> 

                            <b-col md="3" sm="12">
                                <b-form-group label="Desc. até o Vencto" label-for="desc-ate-venc-contrato">
                                    <money id="desc-ate-venc-contrato" v-model="contrato.desc_ate_vencto" v-bind="percent" required class="form-control"/>                                        
                                </b-form-group>
                            </b-col>
                        </b-row>                  
                    </b-card>
                    <br> 

                    <b-card class="cadastros-opcoes-row-card">
                        <b-row class="cadastros-opcoes-row" align-h="around">                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="contrato-boleto" v-model="contrato.e_cobranca_boleto" class="mt-3 mb-3" switch>Cobrança via boleto</b-form-checkbox>
                            </b-col>                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="contrato-ult-dia" v-model="contrato.e_vencto_ultimo_dia_mes" class="mt-3 mb-3" switch>Vencto. último dia do mês</b-form-checkbox>
                            </b-col>                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="contrato-conciliado" v-model="contrato.e_conciliado" class="mt-3 mb-3" switch>Conciliado</b-form-checkbox>
                            </b-col>                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="contrato-reter-imposto" v-model="contrato.e_reter_impostos" class="mt-3 mb-3" switch>Reter impostos</b-form-checkbox>
                            </b-col>                            
                            <b-col md="2" sm="12">
                                <b-form-checkbox id="contrato-prepago" v-model="contrato.e_prepago" class="mt-3 mb-3" switch>Pré-pago</b-form-checkbox>
                            </b-col>                            
                        </b-row>
                    </b-card> 
                    <br> 

                    <b-card header="Serviços" header-bg-variant="light">  
                        <b-row> 
                            <div>
                                <b-button variant="primary" @click="addServico">
                                    <i class="fa fa-plus"></i> Adicionar Serviços

                                </b-button>
                            </div>
                        </b-row>  

                        <b-row  v-if="contrato.itens.length > 0" >
                            <b-table class="mt-2" hover striped :items="contrato.itens" :fields="fieldsOrcamentoServicos" small>  
                                <template v-slot:cell(actions)="data">
                                    <b-button variant="warning" @click="loadContratoServItem(data.item)" class="mr-2">
                                        <i class="fa fa-pencil"></i>
                                    </b-button>
                                    <b-button variant="danger" @click="desejaRemover(data.item)">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                </template>
                            </b-table>
                        </b-row>  

                        <b-modal id="modal-remove-servico" title="Deletar Serviço" v-model="modalRemServico"   >
                            <p class="my-4">Deseja realmente excluir este serviço?</p>
                            <template #modal-footer>
                                <div class="w-100">                    
                                    <b-button
                                        variant="primary"
                                        size="md"
                                        class="float-right ml-3"
                                        @click="removeServico()"                            
                                    >
                                        Sim
                                    </b-button>
                                    <b-button
                                        variant="danger"
                                        size="md"
                                        class="float-right"
                                        @click="$bvModal.hide('modal-remove-servico')"
                                    >
                                        Não
                                    </b-button>
                                </div>
                            </template>

                        </b-modal>                
                    </b-card>
                    <br>
                    

                    <b-row>  
                        <b-col md="4" sm="12" lg="4"> 
                            <b-form-group
                            label="Observações"
                            label-for="contrato-obs"
                            class="mb-0"
                            >
                                <b-form-textarea
                                    id="contrato-obs"
                                    v-model="contrato.obs"
                                ></b-form-textarea>
                            </b-form-group> 
                        </b-col>                
                      
                        <b-col md="4" sm="12" lg="4"> 
                            <b-form-group
                            label="Instruções"
                            label-for="contrato-instrucoes"
                            class="mb-0"
                            >
                                <b-form-textarea
                                    id="contrato-instrucoes"
                                    v-model="contrato.instrucoes"
                                ></b-form-textarea>
                            </b-form-group> 
                        </b-col>                
                     
                        <b-col md="4" sm="12" lg="4">  
                            <b-form-group
                            label="Observações Fiscais"
                            label-for="contrato-obs-fiscais"
                            class="mb-0"
                            >
                                <b-form-textarea
                                    id="contrato-obs-fiscais"
                                    v-model="contrato.obs_fiscais"
                                ></b-form-textarea>
                            </b-form-group> 
                        </b-col>                
                    </b-row>
                </b-form>
            </b-card>           
            
            <template #modal-footer>                
                <div class="w-100" > 
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-faturar-orcamento')"
                    >
                        Cancelar
                    </b-button>                    
                    <b-button
                        variant="success"
                        size="md"
                        class="float-right ml-3 mr-3"
                        @click="gravarFatura()"                            
                    >
                        Gerar Contrato
                    </b-button>
                </div>
            </template>

        </b-modal> 

        <b-modal size="lg" id="modal-imprime-orcamento" title="Imprimir Orçamento" v-model="modalImprimeOrcamento">
            <b-row>
                <b-col md="12" sm="12">
                    <b-input-group                    
                        class="mb-3"
                        prepend="Modelo"
                    >
                        <b-form-select id="modelo-impresso" v-model="idmodelo" :options="modelos" />
                        <b-input-group-append>
                            <b-button variant="info" @click="editaModelo = !editaModelo">
                                <i v-if="!editaModelo" class="fa fa-pencil button-icons"></i>
                                <i v-if="editaModelo" class="fa fa-floppy-o button-icons"></i> 
                                {{editaModelo ? 'Salvar' : 'Editar'}}
                            </b-button>
                            <b-button :disabled="editaModelo" variant="info" @click="salvarPdf()">
                                <i class="fa fa-print button-icons"></i> Imprimir
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>

            </b-row>
            <hr>
            
            <div v-if="!editaModelo" id="renderPDF" v-html="impresso" class="ql-editor"></div>
            
            <div>
                <Editor
                    id="editorTiny"
                    v-if="editaModelo"
                    v-model="impresso"
                    api-key="u92kao7z36g1ljp9rh3pzuxs5p659aefwl916zb0kreuvlnr"
                    :init="editorConfig"
                />
            </div>
            
            <template #modal-footer>
                <div class="w-100 h-50">                    
                    
                </div>
            </template>

        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError, showSuccess } from '@/global'
import Autocomplete from '../autocomplete/Autocomplete.vue'
import Editor from '@tinymce/tinymce-vue'
import {VMoney} from 'v-money'
const moment = require('moment')
import axios from 'axios'
import html2pdf from "html2pdf.js"
let extenso = require('extenso')

export default {
    name: 'CadastroOrcamentos',
    components: { Autocomplete, Editor },
    data: function() {
        return {
            selector: '#editorTiny',
            editorConfig: {
                height: 500,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'print', 'preview', 'anchor', 
                    'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'table', 
                    'paste', 'code', 'wordcount', 'textcolor', 'colorpicker' 
                ],
                toolbar: [
                    'undo redo | formatselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | image link table | removeformat | code'
                ].join(' '), 
                content_style: 'body { font-family:Arial, Helvetica, sans-serif; font-size:14px; }',
                table_toolbar: 'tableprops tableinsert rowprops columnprops cellprops',
                table_resizable: true,
                menubar: false,
                toolbar_mode: 'sliding',
                language: 'pt_BR',                
            },

            visualizar: false,
            modalAddServico: false,
            modalRemServico: false,
            modalFatOrc: false,
            editaModelo: false,
            modalExcluiOrc: false,
            modalImprimeOrcamento: false,
            modelos: [],
            modelo: '',
            nomeModelo: 'orcamento',
            idmodelo: 0,
            idOrca: 0,
            impresso: '',
            fatOrcOpcoes: '',
            legBotaoGravar: '',
            modeAddServico: 'save',
            modeServico: 'new',
            index: 0,
            pessoas: [],
            vendedores: [],
            formas: [],
            condicoes: [],
            planoContas: [],
            contrato: {
                itens: []
            },
            servico: {
                id_servico: '',
                descricao: '',
                qtde: 1,
                unitario: 0,
                detalhes: ''
            },
            servicos: [],
            produtos: [],
            orcamento: {
                id_pessoa: '',
                id_vendedor: '',
                servicos: [],
                produtos: []
            },
            orcamentos: [],
            fatOrc: {},
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            moneyItens: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            },
            percent: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                suffix: ' %',
                precision: 2,
                masked: false
            },
            mode: 'new',            
            page: 1,
            count: 0,
            limit: 0,                     
            parcial: '',
            status: 'T',
            ativos: 'S',
            show: true,
            showOver: false,
            
            fields: [
                {key: 'num_orcamento', label: 'Nº Orçamento', sortable: true},
                {key: 'razao_social', label: 'Nome', sortable: true},
                {key: 'data', label: 'Data do Orçamento', sortable: true, 
                    formatter: value => { 
                            return moment(value).format('DD/MM/YYYY')
                        }
                },                
                {key: 'status', label: 'Status', sortable: true, 
                    formatter: value => { 
                        switch (value) {
                        case "O":
                            return 'ORÇADO';
                        case "A":
                            return 'APROVADO';
                        case "F":
                            return 'FATURADO';
                        case "C":
                            return 'CANCELADO';
                        }
                    }
                },         
                {key: 'actions', label: 'Ações'}
            ], 

            fieldsOrcamentoServicos: [
                {key: 'id_servico', label: 'Código', sortable: false},
                {key: 'descricao', label: 'Descrição', sortable: false},
                {key: 'qtde', label: 'Qtde', sortable: false},
                {key: 'unitario', label: 'Unitário', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },               
                {key: 'total', label: 'Total', sortable: false, 
                    formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2})
                    }
                },                
                {key: 'actions', label: ''}
            ], 
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        alerarStatus(o, sts) {            
            axios['post'](`${baseApiUrl}/orcamentosstatus/${o.id_orcamento}`, {status: sts})
            .then(() => {
                o.status = sts
                showSuccess('Status alterado com secesso.')
            })
            .catch(err => {
                showError(err)
            })  
        },
        salvarPdf(){
            var element = document.getElementById('renderPDF');
            var pagebreak = { mode: 'avoid-all' };

            // Gerar o PDF.
            html2pdf().from(element).set({
                margin:[0.25,0.25,0.25,0.25],
                filename: `${this.nomeModelo}.pdf`,
                pagebreak: pagebreak,
                jsPDF: {orientation: 'portrait', unit: 'in', format: 'a4', compressPDF: true}
            })
            .toPdf()
            .get('pdf').then(function (pdf) {
                    var totalPages = pdf.internal.getNumberOfPages();

                    for (let i = 1; i <= totalPages; i++) {
                        pdf.setPage(i);
                        pdf.setFontSize(8);
                        pdf.setTextColor(75);
                        pdf.text('Página ' + i + ' de ' + totalPages, ((pdf.internal.pageSize.getWidth()/2)-0.4) , (pdf.internal.pageSize.getHeight()-0.2));
                    }
            })
            .save();
        },
        loadModelos(c) { 
            this.idOrca  = c.id_orcamento

            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&tipo=cadmodelo&tela=cadorcamento` 

            axios.get(url).then(res => {
                this.modalImprimeOrcamento = true   
                this.modelos = res.data.data.map(modelo => {
                    return { ...modelo, value: modelo.id_modelo_relatorio, text: `${modelo.id_modelo_relatorio} | ${modelo.descricao}` }
                })
            }) 
            .catch(showError)           
        }, 
        selectModelo() {
            if (this.idmodelo > 0) {
                
                const url = `${baseApiUrl}/modelos/relatorios/${this.idmodelo}`    
    
                axios.get(url).then(res => {
                    this.modelo = res.data.corpo_modelo
                    this.nomeModelo = res.data.descricao
                    this.imprimeOrcamento(this.idOrca)
                }) 
                .catch(err => {
                    showError(err)
                })
            }
        },
        imprimeOrcamento(id) {

            const url = `${baseApiUrl}/orcamentos/${id}`    

            moment.locale('pt')

            axios.get(url).then(res => {  

                try {
                    const sVT = res.data.servicos_total ? res.data.servicos_total : 0
                    const pVT = res.data.produtos_total ? res.data.produtos_total : 0
                    const sVTL = res.data.servicos_total_liquido ? res.data.servicos_total_liquido : 0
                    const pVTL = res.data.produtos_total_liquido ? res.data.produtos_total_liquido : 0

                    const valorTotal = sVT + pVT
                    const valorTotalLiq = sVTL + pVTL

                    this.nomeModelo = `${this.nomeModelo} (${res.data.razao_social})`
                    this.impresso =  this.modelo.replace(/@nome-cliente@/g, res.data.razao_social);
                    this.impresso = this.impresso.replace(/@id-pessoa@/g, res.data.id_pessoa);
                    this.impresso = this.impresso.replace(/@nome-vendedor@/g, res.data.razao_social_vendedor);
                    this.impresso = this.impresso.replace(/@id-vendedor@/g, res.data.id_vendedor);
                    this.impresso = this.impresso.replace(/@tipo-pessoa@/g, res.data.tipo_pessoa === 'F' ? 'FÍSICA' : 'JURÍDICA');
                    this.impresso = this.impresso.replace(/@cpf@/g, res.data.cpf === null ? '' : res.data.cpf);
                    this.impresso = this.impresso.replace(/@cnpj@/g, res.data.cnpj === null ? '' : res.data.cnpj);
                    this.impresso = this.impresso.replace(/@rg@/g, res.data.rg === null ? '' : res.data.rg); 
                    this.impresso = this.impresso.replace(/@ie@/g, res.data.ie === null ? '' : res.data.ie); 
                    this.impresso = this.impresso.replace(/@endereco@/g, res.data.endereco  === null ? '' : res.data.endereco); 
                    this.impresso = this.impresso.replace(/@numero@/g, res.data.numero === null ? 'S/N' : res.data.numero); 
                    this.impresso = this.impresso.replace(/@bairro@/g, res.data.bairro === null ? '' : res.data.bairro); 
                    this.impresso = this.impresso.replace(/@complemento@/g, res.data.complemento === null ? '' : res.data.complemento);
                    this.impresso = this.impresso.replace(/@uf@/g, res.data.uf === null ? '' : res.data.uf);
                    this.impresso = this.impresso.replace(/@nome-cidade@/g, res.data.nome_cidade === null ? '' : res.data.nome_cidade); 
                    this.impresso = this.impresso.replace(/@cep@/g, res.data.cep === null ? '' : res.data.cep); 
                    this.impresso = this.impresso.replace(/@telefone@/g, res.data.telefone === null ? '' : res.data.telefone);
                    this.impresso = this.impresso.replace(/@celular@/g, res.data.celular === null ? '' : res.data.celular);

                    this.impresso = this.impresso.replace(/@id-forma-recebimento@/g, res.data.celular === null ? '' : res.data.id_forma_pagar_receber);
                    this.impresso = this.impresso.replace(/@desc-forma-recebimento@/g, res.data.celular === null ? '' : res.data.desc_forma_recebimento);
                    this.impresso = this.impresso.replace(/@id-cond-recebimento@/g, res.data.celular === null ? '' : res.data.id_condicao_pagar_receber);
                    this.impresso = this.impresso.replace(/@desc-cond-recebimento@/g, res.data.celular === null ? '' : res.data.desc_forma_recebimento);
                    
                    this.impresso = this.impresso.replace(/@produtos-desconto@/g, res.data.produtos_desconto ? res.data.produtos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-desconto-extenso@/g, res.data.produtos_desconto ? extenso(res.data.produtos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-acrescimo@/g, res.data.produtos_acrescimo ? res.data.produtos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-acrescimo-extenso@/g, res.data.produtos_acrescimo ? extenso(res.data.produtos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-total@/g, res.data.produtos_total ? res.data.produtos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-total-extenso@/g, res.data.produtos_total ? extenso(res.data.produtos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');              
                    this.impresso = this.impresso.replace(/@produtos-total-liquido@/g, res.data.produtos_total_liquido ? res.data.produtos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@produtos-total-liquido-extenso@/g, res.data.produtos_total_liquido ? extenso(res.data.produtos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    
                    this.impresso = this.impresso.replace(/@servicos-desconto@/g, res.data.servicos_desconto ? res.data.servicos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-desconto-extenso@/g, res.data.servicos_desconto ? extenso(res.data.servicos_desconto.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-acrescimo@/g, res.data.servicos_acrescimo ? res.data.servicos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-acrescimo-extenso@/g, res.data.servicos_acrescimo ? extenso(res.data.servicos_acrescimo.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-total@/g, res.data.servicos_total ? res.data.servicos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-total-extenso@/g, res.data.servicos_total ? extenso(res.data.servicos_total.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');              
                    this.impresso = this.impresso.replace(/@servicos-total-liquido@/g, res.data.servicos_total_liquido ? res.data.servicos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) : '0,00');
                    this.impresso = this.impresso.replace(/@servicos-total-liquido-extenso@/g, res.data.servicos_total_liquido ? extenso(res.data.servicos_total_liquido.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }) : '0,00');
              
                    this.impresso = this.impresso.replace(/@num-orcamento@/g, res.data.num_orcamento);
                    this.impresso = this.impresso.replace(/@valor-total@/g, valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-total-extenso@/g, extenso(valorTotal.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }));

                    this.impresso = this.impresso.replace(/@valor-total-liquido@/g, valorTotalLiq.toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-total-liquido-extenso@/g, extenso(valorTotalLiq.toLocaleString('pt-br', {minimumFractionDigits: 2}) , { mode: 'currency' }));

                    this.impresso = this.impresso.replace(/@data@/g, moment(res.data.data).format('DD/MM/YYYY'));
                    this.impresso = this.impresso.replace(/@data-extenso@/g, moment(res.data.data).format('LL') );
                    this.impresso = this.impresso.replace(/@qtde-parcelas@/g, res.data.qtde_parcelas);
                    this.impresso = this.impresso.replace(/@qtde-parcelas-extenso@/g, extenso(res.data.qtd_parcelas, { number: { gender: 'm' } }));
                    this.impresso = this.impresso.replace(/@valor-parcela@/g, (valorTotalLiq/ res.data.qtd_parcelas).toLocaleString('pt-br', {minimumFractionDigits: 2}));
                    this.impresso = this.impresso.replace(/@valor-parcela-extenso@/g, extenso((valorTotalLiq / res.data.qtd_parcelas), { mode: 'currency' }));
                    this.impresso = this.impresso.replace(/@desc-ate-vencto@/g, res.data.desc_ate_vencto);
                    this.impresso = this.impresso.replace(/@obs@/g, res.data.obs === null ? '' : res.data.obs);
                    this.impresso = this.impresso.replace(/@status@/g, res.data.status === null ? '' : res.data.status);
                   
                    this.impresso = this.impresso.replace(/@razao-social-filial@/g, res.data.razao_social_filial); 
                    this.impresso = this.impresso.replace(/@tipo-pessoa-filial@/g, res.data.tipo_pessoa_filial === 'F' ? 'FÍSICA' : 'JURÍDICA');
                    this.impresso = this.impresso.replace(/@fantasia-filial@/g, res.data.fantasia_filial === null ? '' : res.data.fantasia_filial); 
                    this.impresso = this.impresso.replace(/@cnpj-cpf-filial@/g, res.data.cnpj_cpf_filial); 
                    this.impresso = this.impresso.replace(/@ie-rg-filial@/g, res.data.ie_rg_filial === null ? '' : res.data.ie_rg_filial); 
                    this.impresso = this.impresso.replace(/@im-filial@/g, res.data.im_filial === null ? '' : res.data.im_filial); 
                    this.impresso = this.impresso.replace(/@telefone-filial@/g, res.data.telefone_filial === null ? '' : res.data.telefone_filial); 
                    this.impresso = this.impresso.replace(/@fax-filial@/g, res.data.fax_filial === null ? '' : res.data.fax_filial); 
                    this.impresso = this.impresso.replace(/@celular-filial@/g, res.data.celular_filial === null ? '' : res.data.celular_filial);                   
                    this.impresso = this.impresso.replace(/@email-filial@/g, res.data.email_filial === null ? '' : res.data.email_filial); 
                    this.impresso = this.impresso.replace(/@endereco-filial@/g, res.data.endereco_filial === null ? '' : res.data.endereco_filial); 
                    this.impresso = this.impresso.replace(/@numero-filial@/g, res.data.numero_filial === null ? 'S/N' : res.data.numero_filial); 
                    this.impresso = this.impresso.replace(/@bairro-filial@/g, res.data.bairro_filial === null ? '' : res.data.bairro_filial); 
                    this.impresso = this.impresso.replace(/@nome-cidade-filial@/g, res.data.nome_cidade_filial === null ? '' : res.data.nome_cidade_filial);
                    this.impresso = this.impresso.replace(/@uf-filial@/g, res.data.uf_filial === null ? '' : res.data.uf_filial);
                    this.impresso = this.impresso.replace(/@complemento-filial@/g, res.data.complemento_filial === null ? '' : res.data.complemento_filial); 
                    this.impresso = this.impresso.replace(/@cep-filial@/g, res.data.cep_filial === null ? '' : res.data.cep_filial);
                    this.impresso = this.impresso.replace(/@data-hoje@/g, moment().format('DD/MM/YYYY') );
                    this.impresso = this.impresso.replace(/@data-hoje-extenso@/g, moment().format('LL') ); 
                    
                } catch (error) {
                    throw error
                }                
              
            }) 
            .catch(err => {
                showError(err)
            })
        },
        novoOrcamento() {
            this.reset()
            this.mode = 'save'
            this.loadPlanoContas()
            this.loadFormas() 
            this.loadCondicoes() 
            this.loadVendedores()           
        },
        addServico() {
            this.loadServicos()
            this.modalAddServico = true            
            this.modeServico = 'new'
        },
        salvarServico() {
            if (this.servico.qtde < 1) {
                this.$toasted.error('Informe a quantidade do serviço.') 
            } else {
                const s = this.servicos.find(obj => {
                    return obj.id_servico === this.servico.id_servico;
                });
                this.servico.descricao = s.descricao
                
                this.modeServico === 'edit' ? this.orcamento.servicos.splice(this.index, 1, {...this.servico}) : this.orcamento.servicos.push({...this.servico})  
                this.resetServico()             
            }
        },
        loadOrcamentoServItem(cServItem) {  
            this.modeServico = 'edit'
            this.servico = { ...cServItem }                     
            this.index = cServItem.seq - 1
                      
            this.modalAddServico = true
        },
        enumeraServicos(){
            this.orcamento.servicos_total_liquido = 0                    

            this.orcamento.servicos.forEach((_, i) =>{                
                this.orcamento.servicos[i].seq = i+1
                const total = this.orcamento.servicos[i].total
                this.orcamento.total_servicos = this.orcamento.total_servicos + total
            })
        },
        desejaRemover(s){
            this.index = Number(s.seq) - 1
            this.modalRemServico = true
        },
        removeServico() {            
            this.orcamento.servicos.splice(Number(this.index), 1)
            this.modalRemServico = false
            this.resetServico()
        },
        resetServico() { 
            this.enumeraServicos() 
            
            this.servico = {}
            this.servico = {
                id_servico: '',
                descricao: '',
                qtde: 1,
                unitario: 0,
                detalhes: ''
            } 
            this.modalAddServico = false 
        },
        onSelectServico(s) { 
            this.servico.id_servico = s.id_servico
        },
        calculaServicos() {
            const qtde = ((!this.servico.qtde) || (this.servico.qtde < 0)) ? 0 : this.servico.qtde
            const unitario = ((!this.servico.unitario) || (this.servico.unitario < 0)) ? 0 : this.servico.unitario
            
            this.servico.total = qtde * unitario           
        },
        faturar(f){
            const url = `${baseApiUrl}/orcamentos/${f.id_orcamento}`
            axios.get(url).then(async res => {

                this.orcarmento = { ...res.data } 
                this.loadCondicoes()
                this.loadFormas()
                this.loadPlanoContas()
                this.faturarParaContrato({ ...res.data } )

                if ((this.orcarmento.status === 'O') || (this.orcarmento.status === 'A') ) {
                    
                    this.modalFatOrc = true               
                }
                else {
                    showError('Este orçamento já está faturado!')
                }
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })            
        },
        gravarFatura() {                
            this.contrato.e_cobranca_boleto == true ? this.contrato.e_cobranca_boleto='S' : this.contrato.e_cobranca_boleto='N'            
            this.contrato.e_vencto_ultimo_dia_mes == true ? this.contrato.e_vencto_ultimo_dia_mes='S' : this.contrato.e_vencto_ultimo_dia_mes='N'            
            this.contrato.e_conciliado == true ? this.contrato.e_conciliado='S' : this.contrato.e_conciliado='N'            
            this.contrato.e_reter_impostos == true ? this.contrato.e_reter_impostos='S' : this.contrato.e_reter_impostos='N'            
            this.contrato.e_prepago == true ? this.contrato.e_prepago='S' : this.contrato.e_prepago='N'            

            axios['post'](`${baseApiUrl}/contratos`, this.contrato)
            .then(() => {

                this.$toasted.global.defaultSuccess()

                this.modalFatOrc = false  
                
                this.contrato = {
                    id_contrato: '',
                    id_pessoa: '',
                    id_plano_conta: '',
                    id_forma_pagar_receber: '',
                    periodicidade: 30,
                    data: '',
                    primeiro_vencimento: '',
                    encerramento: '', 
                    qtde_parcelas: 0,
                    desc_ate_vencto: 0,
                    obs: '',
                    instrucoes: '',
                    obs_fiscais: '',
                    id_orcamento: null,
                    itens: [] 
                }

                this.loadOrcamentos()
            })
            .catch(showError)  

        },

        faturarParaContrato(o){             
            const entrada = o.possui_entrada
            const interv = Number(o.intervalo)
            const qParc = Number(o.qtd_parcelas)

            this.contrato = {
                razao_social: o.razao_social,
                id_pessoa: o.id_pessoa,
                id_plano_conta: o.id_plano_conta,
                id_forma_pagar_receber: o.id_forma_pagar_receber,
                id_orcamento: o.id_orcamento,
                periodicidade: o.intervalo,
                data: moment().format('yyyy-MM-DD'),
                primeiro_vencimento: entrada === 'S' ? moment().format('yyyy-MM-DD') : moment().add(interv, 'days').format('yyyy-MM-DD'),
                encerramento: entrada === 'S' ? moment().add(qParc, 'months').format('yyyy-MM-DD') : moment().add((interv * qParc), 'days').format('yyyy-MM-DD'),  
                qtde_parcelas: o.qtd_parcelas,
                desc_ate_vencto: o.desc_ate_vencto,
                e_prepago: entrada === 'S' ? 'S' :'N',
                obs: `Orçamento Nº: ${o.num_orcamento} - ${o.obs}`,
                instrucoes: '',
                obs_fiscais: '',
                itens: []
            }

            this.contrato.itens = o.servicos.map((contratoItem) =>{
                return {
                    id_servico: contratoItem.id_servico, 
                    descricao: contratoItem.descricao,                                
                    id_vendedor: o.id_vendedor,                               
                    qtde: contratoItem.qtde,
                    unitario: contratoItem.unitario,
                    detalhes: contratoItem.detalhes,
                    custo: contratoItem.custo
                }
            })  

            this.contrato.itens.forEach(i=> {
                i.total = i.qtde * i.unitario
            })  

            
                        
        },
        faturarParaPedido(){                        
            const url = `${baseApiUrl}/orcamentos/${this.orcamento.id_orcamento}`
            axios.get(url).then(res => { 
                this.orcamento = { ...res.data }

                const entrada = this.orcamento.possui_entrada
                const interv = Number(this.orcamento.intervalo)
                const qParc = Number(this.orcamento.qtd_parcelas)

                this.contrato = {
                    razao_social: this.orcamento.razao_social,
                    id_pessoa: this.orcamento.id_pessoa,
                    id_plano_conta: this.orcamento.id_plano_conta,
                    id_forma_pagar_receber: this.orcamento.id_forma_pagar_receber,
                    periodicidade: this.orcamento.intervalo,
                    data: moment().format('yyyy-MM-DD'),
                    primeiro_vencimento: entrada === 'S' ? moment().format('yyyy-MM-DD') : moment().add(interv, 'days').format('yyyy-MM-DD'),
                    encerramento: entrada === 'S' ? moment().add(qParc, 'months').format('yyyy-MM-DD') : moment().add((interv * qParc), 'days').format('yyyy-MM-DD'),  
                    qtde_parcelas: this.orcamento.qtd_parcelas,
                    desc_ate_vencto: this.orcamento.desc_ate_vencto,
                    e_prepago: entrada === 'S' ? 'S' :'N',
                    obs: `Orçamento Nº: ${this.orcamento.num_orcamento} - ${this.orcamento.obs}`,
                    instrucoes: '',
                    obs_fiscais: '',
                }

                this.contrato.itens = this.orcamento.servicos.map((contratoItem) =>{
                    return {
                        id_servico: contratoItem.id_servico,                               
                        id_vendedor: this.orcamento.id_vendedor,                               
                        qtde: contratoItem.qtde,
                        unitario: contratoItem.unitario,
                        detalhes: contratoItem.detalhes,
                        custo: contratoItem.custo
                    }
                })  

                this.contrato.itens.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })  

                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()

                this.orcamento.servicos.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })

                this.modalFatOrc = true
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })            
        },
        faturarParaVenda(){                        
            const url = `${baseApiUrl}/orcamentos/${this.orcamento.id_orcamento}`
            axios.get(url).then(res => { 
                this.orcamento = { ...res.data }

                const entrada = this.orcamento.possui_entrada
                const interv = Number(this.orcamento.intervalo)
                const qParc = Number(this.orcamento.qtd_parcelas)

                this.contrato = {
                    razao_social: this.orcamento.razao_social,
                    id_pessoa: this.orcamento.id_pessoa,
                    id_plano_conta: this.orcamento.id_plano_conta,
                    id_forma_pagar_receber: this.orcamento.id_forma_pagar_receber,
                    periodicidade: this.orcamento.intervalo,
                    data: moment().format('yyyy-MM-DD'),
                    primeiro_vencimento: entrada === 'S' ? moment().format('yyyy-MM-DD') : moment().add(interv, 'days').format('yyyy-MM-DD'),
                    encerramento: entrada === 'S' ? moment().add(qParc, 'months').format('yyyy-MM-DD') : moment().add((interv * qParc), 'days').format('yyyy-MM-DD'),  
                    qtde_parcelas: this.orcamento.qtd_parcelas,
                    desc_ate_vencto: this.orcamento.desc_ate_vencto,
                    e_prepago: entrada === 'S' ? 'S' :'N',
                    obs: `Orçamento Nº: ${this.orcamento.num_orcamento} - ${this.orcamento.obs}`,
                    instrucoes: '',
                    obs_fiscais: '',
                }

                this.contrato.itens = this.orcamento.servicos.map((contratoItem) =>{
                    return {
                        id_servico: contratoItem.id_servico,                               
                        id_vendedor: this.orcamento.id_vendedor,                               
                        qtde: contratoItem.qtde,
                        unitario: contratoItem.unitario,
                        detalhes: contratoItem.detalhes,
                        custo: contratoItem.custo
                    }
                })  

                this.contrato.itens.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })  

                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()

                this.orcamento.servicos.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })

                this.modalFatOrc = true
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })            
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadOrcamentos()
        }, 
        loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            axios.get(url).then(res => {
                this.pessoas = res.data
            })
        },
        loadServicos() {  
            const url = `${baseApiUrl}/servicos` 
            axios.get(url).then(res => {
                this.servicos = res.data.map(servico => {
                    return { ...servico, value: servico.id_servico, text: `${servico.id_servico} | ${servico.descricao}` }
                })
            })          
        },  

        loadVendedores() {  
            const url = `${baseApiUrl}/vendedores`

            axios.get(url).then(res => {
                this.vendedores = res.data.map(vendedor => {
                    return { ...vendedor, value: vendedor.idpessoa, text: `${vendedor.idpessoa} | ${vendedor.razao_social}` }
                })
            })
        }, 

        onSelect(p) {
            this.orcamento.id_pessoa = p.idpessoa
        }, 
        loadFormas() {
            const url = `${baseApiUrl}/formas_pagar_receber`
            axios.get(url).then(res => {
                this.formas = res.data.map(forma => {
                    return { ...forma, value: forma.id_forma_pagar_receber, text: `${forma.id_forma_pagar_receber} | ${forma.descricao}` }
                })
            })
        }, 
        loadCondicoes() {
            const url = `${baseApiUrl}/condicoes_pagar_receber`
            axios.get(url).then(res => {
                this.condicoes = res.data.map(condicao => {
                    return { ...condicao, value: condicao.id_condicao_pagar_receber, text: `${condicao.id_condicao_pagar_receber} | ${condicao.descricao}` }
                })
            })
        }, 
        loadPlanoContas() {
            const url = `${baseApiUrl}/plano_contas/?tipo=R`
            axios.get(url).then(res => {
                this.planoContas = res.data.map(planoConta => {
                    return { ...planoConta, value: planoConta.id_plano_conta, text: `${planoConta.id_plano_conta} | ${planoConta.descricao}` }
                })
            })
        },  
        loadOrcamentos() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&status=${this.status}&parcial=${this.parcial}&tipo=cadorcamento` 

            axios.get(url).then(res => {
                this.orcamentos = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit

                this.loadVendedores()  
            }) 
            .catch(err => {
                showError(err)
            })          
        },
        loadOrcamento(orcamento, mode = 'save') {
            this.show = !this.show 
            this.showOver = !this.showOver 

            const url = `${baseApiUrl}/orcamentos/${orcamento.id_orcamento}`    

            axios.get(url).then(res => {
                
                this.showOver = !this.showOver 
                
                this.mode = mode
                this.orcamento = { ...res.data }
                this.orcamento.data = moment(this.orcamento.data).format('yyyy-MM-DD') 
                
                if (this.orcamento.status === 'F' || this.orcamento.status === 'C') {
                    this.visualizar = true
                } else {
                    this.visualizar = false
                }

                this.loadPlanoContas()
                this.loadFormas()
                this.loadCondicoes()
                this.enumeraServicos()

                this.orcamento.servicos.forEach(i=> {
                    i.total = i.qtde * i.unitario
                })
            }) 
            .catch(err => {
                showError(err)
                this.show = !this.show 
                this.showOver = !this.showOver 
            })
        },
        reset() {
            this.mode = 'new'
            
            this.orcamento = {
                id_orcamento: '',
                id_pessoa: '',
                id_vendedor: '',
                id_plano_conta: '',
                id_forma_pagar_receber: '',
                id_condicao_pagar_receber: '',
                periodicidade: 30,
                data: moment().format('YYYY-MM-DD'),                
                desc_ate_vencto: 0,
                obs: '',
                servicos: [],
                produtos: []
            }
            this.visualizar = false
            this.show = !this.show
        },

        save() {
            const method = this.orcamento.id_orcamento ? 'put' : 'post'
            const idorcamento = this.orcamento.id_orcamento ? `/${this.orcamento.id_orcamento}` : '' 
            
            axios[method](`${baseApiUrl}/orcamentos${idorcamento}`, this.orcamento)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
                this.loadOrcamentos()
            })
            .catch(err => {
                showError(err)
            })           
        },
        
        desejaExcluir(o) {
            this.modalExcluiOrc = true
            this.orcamento.id_orcamento = o.id_orcamento
        },

        remove() {
            axios.delete(`${baseApiUrl}/orcamentos/${this.orcamento.id_orcamento}`)
                .then(() => {
                    showSuccess('Orçamento excluído com sucesso.')
                    this.orcamento.id_orcamento = ''
                    this.modalExcluiOrc = false
                    this.loadOrcamentos()
                })
            .catch(err => {
                showError(err)
                this.modalExcluiOrc = false
            }) 
        },   
    
    },
    watch: {
        page() {
            this.loadOrcamentos()
        },
        fatOrcOpcoes() {
            switch (this.fatOrcOpcoes) {
                case 'C' :
                    this.faturarParaContrato()
                    this.legBotaoGravar = 'Gravar Contrato'
                    break
                case 'P' : 
                    this.faturarParaPedido()
                    this.legBotaoGravar = 'Gravar Pedido'
                    break
                case 'V' :
                    this.faturarParaVenda()
                    this.legBotaoGravar = 'Gravar Venda'
                    break
            }
        },
        modalFatOrc() {
            if(this.modalFatOrc === false) {this.fatOrcOpcoes = ''}
        },
        idmodelo() {
            this.selectModelo()
        },
        modalImprimeOrcamento() {
            this.impresso = ''
            this.idmodelo = 0
            this.nomeModelo = 'orcamento'
            this.editaModelo = false
        },
        'servico.unitario': function() {
            this.calculaServicos()
        }
        
    },
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .opcoes-faturar {
        display: flex;
        align-items: center;
        width: 200px;
        padding: 25px;
        background-color: beige;
    }

    .opcoes-faturar:hover {
        background-color: rgb(197, 197, 197);
    }

    .opcoes-faturar-f {
        width: 30%;
    }

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    
    



</style>