<template>    
    <div id="wrapper" >          
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>            
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="!showRelato"
                @click="showRelato = !showRelato">
                    <i class="fa fa-arrow-left button-icons"></i>
                    Voltar
            </b-button>   
            <b-button variant="primary" class="botoesAcao" v-if="(mode === 'new') && (showRelato)"
                @click="showRelato = !showRelato">
                    <i class="fa fa-file-text-o button-icons"></i>
                    Relatórios
            </b-button>  
        </div>  

        <div v-if="showRelato" class="containerRoll"> 
            <b-card class="cadastros-card-pesquisa sticky-card-notas-em" v-if="show">
                <b-row>
                    <b-col md="4" sm="12">
                        <b-form-group>
                            Localizar
                            <b-form-input id="localizar" type="search"
                                :formatter="upperCase"
                                v-model="parcial"
                                :readonly="mode === 'remove'"
                                placeholder="Informe os dados da pesquisa..." />
                        </b-form-group>
                    </b-col> 

                    <b-col md="3" sm="12">
                        <b-form-group>
                            Data inicial
                            <b-form-input id="data-inicial" type="date"
                                v-model="dataInicial"
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group>
                            Data final
                            <b-form-input id="data-final" type="date"
                                v-model="dataFinal"
                                :readonly="mode === 'remove'" />
                        </b-form-group>
                    </b-col>
                    <b-col md="2" sm="12" class="d-flex justify-content-end">
                        <b-button variant="info" @click="novaConsulta">
                            <i class="fa fa-search button-icons"></i> Consultar
                        </b-button>
                    </b-col>
                </b-row>
            </b-card>  

           

            <b-card v-show="!show" style="margin-top: 10px">
                    <div class="acoesRodape">                 
                    <b-button variant="secondary " class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')"
                        @click="reset">
                            <i class="fa fa-times button-icons"></i>
                            Cancelar
                    </b-button>                    
                    <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                        @click="save">
                            <i class="fa fa-check button-icons"></i>
                            Salvar
                    </b-button>   
                </div>
            </b-card> 

            <b-card class="cadastros-card-corpo" v-if="show"> 
                <b-table 
                    class="tabelaConsulta"
                    sort-icon-left
                    :busy="isBusy"
                    hover 
                    small
                    :items="notas" 
                    :fields="fieldsConsulta"
                    responsive
                    @row-clicked="toggleDetails"
                >  
                    <template #table-busy>
                        <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Aguarde...</strong>
                        </div>
                    </template>

                    <template #cell(situacao)="data">
                        <div  class="d-flex justify-content-end">
                            <span :style="`color: ${corStatus(data.item.situacao)}; font-weight: 600;`">{{data.item.situacao}}</span>                  
                        </div>
                    </template>

                    <template #row-details="row">
                        <b-card no-body class="p-2">
                            <b-row>
                                <b-col md="8" sm="12">
                                    <p style="margin-bottom: -10px"><strong>Eventos</strong></p>
                                </b-col>
                                <b-col md="4" sm="12" class="d-flex justify-content-end">
                                    <b-button variant="primary" @click="loadNota(row.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Visualização/Download PDF'">
                                        <i class="fa fa-file-code-o"></i>
                                    </b-button>
                                    <b-button variant="primary" @click="verificarStatus(row.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Verificar Status'">
                                        <i class="fa fa-refresh"></i>
                                    </b-button>
                                    <b-button v-if="false" variant="info" @click="reenviar(row.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Reenviar'">
                                        <i class="fa fa-arrow-up"></i>
                                    </b-button>
                                    <b-button :disabled="(row.item.situacao !== 'GERADA') && (row.item.situacao !== 'REJEITADO')" variant="danger" @click="excluirNfe(row.item)" class="mr-2" v-b-tooltip.hover.bottomleft="'Excluir NF'">
                                        <i class="fa fa-trash"></i>
                                    </b-button>
                                    <b-button variant="success" @click="nfseJson = !nfseJson" class="mr-2" v-b-tooltip.hover.bottomleft="'Código/Status'">
                                        <i v-if="!nfseJson" class="fa fa-code"></i> 
                                        <i v-else class="fa fa-history"></i> 
                                    </b-button>
                                </b-col>
                            </b-row>
                            
                            <hr>
                            <div v-if="nfseJson">
                                <b-form-textarea
                                    id="textareaJsonNFSE"
                                    v-model="row.item.corpo_json"
                                    rows="12"
                                    max-rows="20"
                                ></b-form-textarea>
                            </div>
                            <b-table v-else :busy="isBusyEventos"  borderless sort-icon-left small :items="row.item.eventos" :fields="fieldsEventos"></b-table> 
                        </b-card>
                       
                    </template>
                </b-table>
                <hr>

                <b-pagination 
                    v-if="notas.length > 0"
                    align="center" 
                    size="md" 
                    v-model="page"  
                    :total-rows="count" 
                    :per-page="limit" 
                    first-number 
                    last-number>
                </b-pagination>  
                <span v-else style="color: rgb(114, 114, 114)">Faça uma consulta para exibir os resultados...</span>              
            </b-card>

           

            <b-modal 
                hideBvModalEvent
                v-model="modalLoadNf"                        
                id="modal-load-nf" 
                centered size="xl" 
                ok-only
                ok-disabled
                title="Visualização da Nota Fiscal"
            >  
                
                
                <b-card style="width: 100%">
                    <p>Nº NF {{ nota.num_nota }} </p>               
                    <p>Nº RPS {{ nota.num_rps }}</p>  
                    <b-button @click="downloadPdf"><i class="fa fa-arrow-down mr-2" aria-hidden="true"></i>Download PDF</b-button>
                </b-card> 
                <br>
 
                <b-card style="width: 100%">
                    <vue-pdf-embed 
                    :source="nfPdf" />
                </b-card>                           
                
                
                <template #modal-footer>
                    <div class="w-100"> 
                    </div>
                </template>                  
            </b-modal>
        </div>

        <b-card class="cadastros-card-corpo" v-if="!showRelato">
            <b-row>
                <b-col b-col md="6" sm="12">
                    <b-card border-variant="dark" header-tag="header">
                        <template #header>
                            <h5 class="mb-0">Notas por período</h5>
                        </template>

                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group label="Data inicial" label-for="notas-data-inicial">
                                    <b-form-input id="notas-data-inicial" type="date" v-model="vDataInicial" required/>
                                </b-form-group>
                            </b-col>

                            <b-col md="6" sm="12">
                                <b-form-group label="Data final" label-for="notas-data-final">
                                    <b-form-input id="notas-data-final" type="date" v-model="vDataFinal" required/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col align="right">
                                <b-button  variant="primary" style="color: white" @click="relatorio">Gerar relatório</b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <!-- MODAL RELATÓRIO DE notas --> 
                    <b-modal size="huge" scrollable ref="relatorio-modal" hide-footer>                            
                        <template #modal-header="{ close }" >
                            <b-row align-v="center" style="padding-left: 20px">
                                <b-col md="11" sm="11">
                                    <b-button variant="primary" style="color: white" 
                                        @click="()=>{gerarPdf = gerarPdf + 1 }">
                                            <i class="fa fa-print" ></i>
                                            Imprimir
                                    </b-button>
                                </b-col>
                                <b-col md="1" sm="1">
                                    <b-button size="sm" variant="outline-default" @click="close()">
                                        <i class="fa fa-times" style="font-size: 22px"></i>
                                    </b-button>
                                </b-col>
                            </b-row>
                        </template>

                        <RelatorioNotas :itens="relatorioNotas" :gerarPdf="gerarPdf" :config="configRel"/>

                    </b-modal>

                </b-col>
            </b-row> 
            
        </b-card>
        
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
// import Autocomplete from '@/components/autocomplete/Autocomplete.vue'
import RelatorioNotas from '@/components/notasEmitidas/report/RelatorioNotas'
import axios from 'axios'
const moment = require('moment')
import {VMoney} from 'v-money'
//import VuePdfEmbed from 'vue-pdf-embed'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import { showSuccess } from '../../global'

export default {
    name: 'ConsultaNotas', 
    components: {
        //Autocomplete,
        RelatorioNotas,
        VuePdfEmbed
    },
    data: function() {
        return {
            teste: '',
            vDataInicial: moment().add(-1, 'months').format('YYYY-MM-DD'),
            vDataFinal: moment().format('YYYY-MM-DD'),
            nfseJson: false,
            relatorioNotas: [],
            gerarPdf: 0,
            modalLoadNf: false,
            configRel: {
                headers: {},
                param: {}
            },
            index: -1, 
            page: 1,
            count: 0,
            limit: 0,           
            mode: 'new',
            modeItens: 'save',
            venda: {
                id_pessoa: '',
                data_venda: moment().format('YYYY-MM-DD')
            },
            gerarFatura: 1,
            resetFatura: 1,
            carregaParcelas: 1,
            nota: {},
            notas: [],
            eventos: [],
            isBusyEventos: false,
            isBusy: false,
            nfPdf: '',
            notaItem: {
                idproduto: '',
                qtde: 1,
                unitario: 0,
                desconto: 0,
                acrescimo: 0
            },
            emissao: {},
            notasItens: [],
            locateNota: '',
            show: true,
            showRelato: true,            
            dataInicial: moment().add(-1, 'months').format('YYYY-MM-DD'),
            dataFinal: moment().format('YYYY-MM-DD'),
            parcial: '',
            money: {
                decimal: ',',
                thousands: '.',
                //prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            moneyItens: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            },
            fieldsConsulta: [
                {key: 'data_emissao', label: 'Data', sortable: true,
                formatter: value => { 
                        return moment(value).format('DD/MM/YYYY')
                    }
                },
                {key: 'num_nota', label: 'Nº Nota', sortable: true},
                {key: 'num_rps', label: 'Nº RPS', sortable: true},
                {key: 'nome_tomador', label: 'Cliente', sortable: true},
                {key: 'valor_nota', label: 'Valor', sortable: true, formatter: value => { 
                        return value ? value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'}) : 0                        
                    }
                },
                {key: 'situacao', label: '', sortable: false},
            ],  
            fieldsEventos: [
                //{key: 'seq', label: 'Seq', sortable: true},
                //{key: 'id_nfse_emitida', label: 'VENDA', sortable: true},
                {key: 'data_cad', label: 'Data', sortable: true,
                formatter: value => { 
                        return moment(value).format('DD/MM/YYYY HH:mm:ss')
                    }
                },
                {key: 'situacao', label: 'Situação', sortable: false},
                {key: 'mensagem', label: 'Mensagem', sortable: false},
                {key: 'protocolo', label: 'Protocolo', sortable: false},
            ]          
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        corStatus(status) {
            switch (status) {
                case 'GERADA': return '#ffb30f';
                case 'PROCESSANDO': return '#ffb30f';
                case 'ENVIADO': return '#4747f1';
                case 'CONCLUIDO': return '#48b376';
                case 'REJEITADO': return '#d6345d';
                case 'CANCELADO': return '#d6345d';
                case 'SUBSTITUIDO': return '#d6345d';
                default: return '#ffb30f'; 
            }
        },

        async toggleDetails(item) {            
            
            if (item["_showDetails"]) {
                item["_showDetails"] = false
                this.$set(item, "_rowVariant", 'light')
            }
            else {
                
                const url = `${baseApiUrl}/gerarnfse/eventos/${item.id_nfse_emitida}` 

                axios.get(url).then(res => {
                    item.eventos = res.data
                    
                    this.notas.forEach(it => {
                        this.$set(it, "_showDetails", false)
                        this.$set(it, "_rowVariant", 'light')
                    })

                    this.$set(item, "_showDetails", true)
                    this.$set(item, "_rowVariant", 'warning')
                }) 
                .catch(err => {
                    showError(err)
                    item.eventos = []
                })                 
            }
        },
        relatorio: async function() {

            this.configRel.headers = [
                { text: 'Nº Venda', value: 'id_nfse_emitida', align: 'left' },
                { text: 'Data', value: 'data_venda', align: 'left' },
                { text: 'Cliente', value: 'razao_social', align: 'left' },
                { text: 'Produtos', value: 'total_itens', align: 'left' },
                { text: 'Desconto', value: 'desconto', align: 'left' },
                { text: 'Acréscimo', value: 'acrescimo', align: 'left' },
                { text: 'Total', value: 'total_liquido', align: 'right' },
            ]
            this.configRel.param = {  
                titulo: 'notas por período',
                subtitulo: `Período: ${moment(this.vDataInicial).format('DD/MM/YYYY')} à ${moment(this.vDataFinal).format('DD/MM/YYYY')}`,
                arquivo: 'notas-por-periodo',
                datainicial: this.vDataInicial,
                datafinal: this.vDataFinal,
                //agrupador: 'AGRUPADOR',
                ordenar: '',
                
                page: 1,
                limit: 10,
                //order: 'asc'
            }           

            const url = `${baseApiUrl}/notas/relatorios/periodo/?datainicial=${this.configRel.param.datainicial}&datafinal=${this.configRel.param.datafinal}`    

            await axios.get(url).then(res => {
                this.relatorioNotas = res.data
            }).then(() => {               
                this.$refs['relatorio-modal'].show()
            }).catch(err => {
                showError(err)
            })           
            
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },       
        novaConsulta() {
            this.page = 1 
            this.locateNotas()
        },         
        locateNotas() {
            this.isBusy = true
            const url = `${baseApiUrl}/gerarnfse/consulta/?page=${this.page}&dataInicio=${this.dataInicial}&dataFim=${this.dataFinal}&parcial=${this.parcial}` 
            axios.get(url).then(res => { 
                this.notas = res.data.data                
                this.count = res.data.count 
                this.limit = res.data.limit  
                this.isBusy = false              
            }) 
            .catch(err => {
                showError(err)
                this.notas = []
                this.isBusy = false
            })
        },
        reset() {
            this.mode = 'new'
            this.venda = {
                idpessoa: '',
                id_pessoa: '',
                data_venda: moment().format('YYYY-MM-DD'),
            }
            this.notasItens = []
            this.locateNotas()
            this.resetItem()
            this.show = !this.show

            this.resetFatura += 1
           // setTimeout(() => {  this.resetFatura += 1 }, 100)
        },        
        remove() {
            const id_nfse_emitida = this.nota.id_nfse_emitida
            axios.delete(`${baseApiUrl}/notas/${id_nfse_emitida}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                })
                .catch(showError)
        },
        verificarStatus(item) {
            this.isBusyEventos = true

            axios.get(`${baseApiUrl}/gerarnfse/verificar`, {
                params: {
                    idsNFSe: [item.id_nfse_emitida],
                    consultaIndividual: 'S'
                }
            })
            .then(async res => {
                item.eventos = [ ...res.data ]
                this.teste = res.data

                this.isBusyEventos = false
            }) 
            .catch(err => {
                showError(err)
                this.isBusyEventos = false
            })
        },
        reenviar(item) {
            const url = `${baseApiUrl}/gerarnfse/verificar/${item.id_nfse_emitida}`

            axios.get(url).then(async res => {
                item.eventos = res.data
            }) 
            .catch(err => {
                showError(err)
            })
        },  

        excluirNfe(item) {
            const url = `${baseApiUrl}/gerarnfse/consulta/${item.id_nfse_emitida}`

            axios.delete(url).then(async () => {
                showSuccess('NFS-e excluída com sucesso.')
                this.locateNotas()
            }) 
            .catch(err => {
                showError(err)
            })
        },  
        
        loadNota(nf) {  
            const url = `${baseApiUrl}/gerarnfse/consulta/${nf.id_nfse_emitida}`

            axios.get(url).then(async res => {
                this.nota = res.data.data
                this.emissao = {
                    ambiente: res.data.ambiente, 
                    token: res.data.token, 
                    url: res.data.url 
                }

                this.modalLoadNf = true
                
                axios.defaults.headers.common['x-api-key'] = res.data.token                
                await axios({
                    url: `${this.nota.url_pdf}`,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then(async (r) => {                    
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    this.nfPdf = url
                })
                .catch(err => {
                showError(err)
            }) 
            }) 
            .catch(err => {
                showError(err)
            })           
        },
        downloadPdf() { 
            axios.defaults.headers.common['x-api-key'] = this.emissao.token   
            axios({
                url: `${this.nota.url_pdf}`,
                method: 'GET',
                responseType: 'blob',
            })
            .then(async (r) => {                
                const url = window.URL.createObjectURL(new Blob([r.data]))
                const link = document.createElement('a');                
                link.href = url
                link.setAttribute('download', `NFSe_NUM_${this.nota.num_nota || this.nota.num_rps}.pdf`);
                document.body.appendChild(link);
                link.click();
            })
            .catch(err => {
                showError(err) 
            })        
        },
        async loadEventos(nf) {  
            const url = `${baseApiUrl}/gerarnfse/eventos/${nf.id_nfse_emitida}` 

            let eventos = []

            await axios.get(url).then(res => {
                eventos = res.data
            }) 
            .catch(err => {
                showError(err)
                 eventos = []
            })  
            
            return eventos
        },
        loadNotaItem(notaItem, modeItens = 'save') {            
            this.modeItens = modeItens
            this.notaItem = { ...notaItem }                     
            this.index = this.notaItem.seq - 1
            this.notaItem.idproduto = notaItem.descricao
        },
    },
    watch: {
        page() {
            this.locateNotas() 
        }
    }
}
</script>

<style>

.totais-venda{
    margin-bottom: 10px;
    padding: 0;
    height: 115px; 
    color: white;
}

.form-control-lg {
    margin: -10px 0 0 -18px;
    /* margin-left: -50px; */

    height: calc(1.5em + .75rem + 2px);
    font-size: 2rem;
    color: white;
    line-height: 1.5;
}

.button-icons {
    margin-right: 5px;
}

.btn-excluir {
    width: 100%;
    margin-bottom: 10px
}

.tabelaConsulta tr {
    cursor: pointer;
}

.containerRoll {
    height: calc(100vh - 120px); /* Ajuste a altura de acordo com a altura do card fixo */
    overflow-y: auto;
    scrollbar-width: none; /* Para navegadores Firefox */
    -ms-overflow-style: none; /* Para Internet Explorer e Edge */
    padding-bottom: 40px;
}

.containerRoll::-webkit-scrollbar {
    display: none; /* Para navegadores WebKit (Chrome, Safari) */
}

.sticky-card-notas-em {
    position: sticky;
    top: 0; 
    background-color: #f9f9f9;
    z-index: 5; /* Para garantir que fique acima de outros elementos */
}



</style>