<template>
    <div class="cadastro-empresas">
        <div class="acoesTopo">             
            <b-button variant="secondary " class="botoesAcao" v-if="mode === 'save'"
                @click="reset">
                    <i class="fa fa-times button-icons"></i>
                    Cancelar
            </b-button>
            <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" 
                @click="save">
                    <i class="fa fa-check button-icons"></i>
                    Salvar
            </b-button>            
            <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'"
                @click="novaEmpresa">
                    <i class="fa fa-plus button-icons"></i>
                    Novo
            </b-button>   
        </div>

        <b-card class="cadastros-card-pesquisa" v-if="show">
            <b-row align-v="center">
                <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                    <b-form-input id="localizar" type="search"
                        :formatter="upperCase"
                        v-model="parcial"
                        placeholder="Informe os dados da pesquisa..."
                        />                     
                </b-col> 
                <b-col md="4" sm="12" class="radio-consulta">
                    <b-form-group  v-slot="{ ariaDescribedby }">
                        <b-form-radio-group 
                            id="radio-slots"
                            v-model="ativos"
                            :aria-describedby="ariaDescribedby"
                            name="radio-options-slots"
                        >
                            <b-form-radio value="S">Ativos</b-form-radio>
                            <b-form-radio value="N">Inativos</b-form-radio>
                            <b-form-radio value="T">Todos</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </b-col>
                <b-col md="3" sm="12" class="d-flex justify-content-end">
                    <b-button variant="info" @click="novaConsulta">
                        <i class="fa fa-search button-icons"></i> Consultar
                    </b-button>
                </b-col>
            </b-row>
        </b-card>        

        <b-card class="cadastros-card-corpo" v-show="!show">
            <b-form>
                <input id="empresa-id" type="hidden" v-model="empresa.idempresa" />

                <h4>Dados da Empresa</h4>
                <hr>
                <b-row>                       
                    <b-col md="6" sm="12">
                        <b-form-group label="Nome da Empresa" label-for="empresa-nome"> 
                            <b-form-input id="empresa-nome" type="text"
                                :formatter="upperCase"
                                v-model="empresa.nome_empresa" required/>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12">
                        <b-form-group label="Tipo" label-for="tipo-pessoa">
                            <b-form-select id="tipo-pessoa" v-model="empresa.tipo" :options="optionsTipo"/>
                        </b-form-group>
                    </b-col>
                    <b-col md="3" sm="12" v-show="empresa.tipo === 'F'">
                        <b-form-group label="CPF" label-for="empresa-cpf-cad"> 
                            <b-form-input id="empresa-cpf-cad" type="text"  
                                v-mask="mascaras.cpf"
                                v-model="empresa.cpf"
                                />
                        </b-form-group>
                    </b-col> 
                    <b-col md="3" sm="12" v-show="empresa.tipo === 'J'">
                        <b-form-group label="CNPJ" label-for="empresa-cnpj-cad"> 
                            <b-form-input id="empresa-cnpj-cad" type="text"
                                
                                v-mask="mascaras.cnpj"
                                v-model="empresa.cnpj" />
                        </b-form-group>
                    </b-col>  
                </b-row>
                <br>
                
                <b-alert v-if="!edit" show variant="warning" class="alert-div">            
                    <span>Atenção: Os campos referentes as informações de filial e de usuário só serão exibidos aqui no momento de cadastro de novas empresas, 
                        para edição deverá ser utilizado suas respectivas telas! </span>          
                </b-alert>

                <div v-if="!edit">
                    <h4>Dados da Filial</h4>
                    <hr>
                    <b-row>                 
                        <b-col md="6" sm="12" v-show="empresa.tipo === 'F'">
                            <b-form-group label="Nome" label-for="empresa-nome-cad"> 
                                <b-form-input id="empresa-nome-cad" type="text"
                                    v-model="filial.razao_social"
                                    />
                            </b-form-group>
                        </b-col> 
                        <b-col md="6" sm="12" v-show="empresa.tipo === 'J'">
                            <b-form-group label="Razão social" label-for="empresa-razao-cad"> 
                                <b-form-input id="empresa-razao-cad" type="text"
                                    v-model="filial.razao_social" 
                                    />
                            </b-form-group>
                        </b-col> 
                        <b-col md="6" sm="12" v-show="empresa.tipo === 'F'">
                            <b-form-group label="Apelido" label-for="empresa-apelido-cad"> 
                                <b-form-input id="empresa-apelido-cad" type="text"
                                    v-model="filial.fantasia"
                                    />
                            </b-form-group>
                        </b-col> 
                        <b-col md="6" sm="12" v-show="empresa.tipo === 'J'">
                            <b-form-group label="Fantasia" label-for="empresa-fantasia-cad"> 
                                <b-form-input id="empresa-fantasia-cad" type="text"
                                    v-model="filial.fantasia" 
                                    />
                            </b-form-group>
                        </b-col>               
                    </b-row>

                    <b-row>
                        <b-col md="3" sm="12">
                            <b-form-group label="CEP" label-for="filial-cep"> 
                                <b-input-group  id="filial-cep" class="mt-2">
                                    <b-form-input  type="text"
                                        v-mask="mascaras.cep"
                                        :formatter="upperCase"
                                        v-model="filial.cep" />
                                        <b-input-group-append>
                                            <b-button variant="success" @click="loadCep(filial.cep)"><i class="fa fa-refresh atualizar"></i></b-button>
                                        </b-input-group-append>
                                </b-input-group>
                            </b-form-group>
                        </b-col> 
                        <b-col md="6" sm="12">
                            <b-form-group label="Endereço" label-for="filial-endereco"> 
                                <b-form-input id="filial-endereco" type="text"
                                    :formatter="upperCase"
                                    v-model="filial.endereco" required/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Nº." label-for="filial-numero"> 
                                <b-form-input id="filial-numemo" type="text"
                                    :formatter="upperCase"
                                    v-model="filial.numero" />
                            </b-form-group>
                        </b-col>  
                    </b-row>

                    <b-row> 
                        <b-col md="4" sm="12">
                            <b-form-group label="Bairro" label-for="filial-bairro"> 
                                <b-form-input id="filial-bairro" type="text"
                                    :formatter="upperCase"
                                    v-model="filial.bairro"/>
                            </b-form-group>
                        </b-col> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Complemento" label-for="filial-complemento"> 
                                <b-form-input id="filial-complemento" type="text"
                                    :formatter="upperCase"
                                    v-model="filial.complemento" required/>
                            </b-form-group>
                        </b-col>
                        <b-col md="5" sm="12">
                            <b-form-group label="Cidade" label-for="filial-cidade">
                                    <Autocomplete
                                        id="filial-cidade"
                                        :options="cidades"
                                        label-key="nome"
                                        value-key="id_cidade"
                                        v-model="filial.idcidade"
                                        placeholder=""
                                        @shouldSearch="loadCidades"
                                        @select="onSelect"
                                    />
                            </b-form-group>                        
                        </b-col> 
                    </b-row>

                    <b-row> 
                        <b-col md="3" sm="12">
                            <b-form-group label="Celular" label-for="filial-celular"> 
                                <b-form-input id="filial-celular" type="text"
                                    v-mask="mascaras.celular"
                                    v-model="filial.celular" />
                            </b-form-group>
                        </b-col> 

                        <b-col md="3" sm="12">
                            <b-form-group label="Telefone" label-for="filial-telefone"> 
                                <b-form-input id="filial-telefone" type="text"
                                    v-mask="mascaras.telefone"
                                    v-model="filial.telefone" />
                            </b-form-group>
                        </b-col> 
                        
                        <b-col md="3" sm="12">
                            <b-form-group label="Fax" label-for="filial-fax"> 
                                <b-form-input id="filial-fax" type="text"
                                    v-mask="mascaras.telefone"
                                    v-model="filial.fax" />
                            </b-form-group>
                        </b-col>  
                    </b-row>
                    <br>

                    <h4>Dados do Usuário</h4>
                    <hr>
                    <b-row>                 
                        <b-col md="6" sm="12">
                            <b-form-group label="Nome Usuário" label-for="nome-usuario"> 
                                <b-form-input id="nome-usuario" type="text"
                                    :formatter="upperCase"
                                    v-model="usuario.nome" required />
                            </b-form-group>
                        </b-col>             
                        <b-col md="6" sm="12">
                            <b-form-group label="E-mail usuário" label-for="email-usuario"> 
                                <b-form-input id="email-usuario" type="text"
                                    :formatter="lowerCase"
                                    v-model="usuario.email" required />
                            </b-form-group>
                        </b-col>             
                    </b-row>
                </div>

                <b-card class="cadastros-opcoes-row-card">
                    <b-row class="cadastros-opcoes-row" align-h="around">                        
                        <b-col md="3" sm="12">
                            <b-form-checkbox 
                                id="empresa-ativo" 
                                value="S" 
                                unchecked-value="N" 
                                v-show="mode === 'save'" 
                                v-model="empresa.ativo" 
                                class="mt-3 mb-3" 
                                switch
                            >
                                Ativo
                            </b-form-checkbox>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-checkbox 
                                id="empresa-bpo" 
                                value="S" 
                                unchecked-value="N" 
                                v-show="mode === 'save'" 
                                v-model="empresa.e_bpo" 
                                class="mt-3 mb-3" 
                                switch
                            >
                                É empresa de BPO?
                            </b-form-checkbox>
                        </b-col>

                        <b-col md="3" sm="12">
                            <b-form-checkbox 
                                id="empresa-licenca" 
                                value="S" 
                                unchecked-value="N" 
                                v-show="mode === 'save'" 
                                v-model="empresa.e_licenca_ativa" 
                                class="mt-3 mb-3" 
                                switch
                            >
                                Licença ativa?
                            </b-form-checkbox>
                            
                        </b-col>                        
                    </b-row>

                    <b-row v-if="empresa.e_bpo ==='S'">
                        <b-col md="5" sm="12">
                            <b-form-group label="Número de Clientes BPO" label-for="email-num-usu"> 
                                <b-form-input 
                                    id="email-num-usu" 
                                    type="number"
                                    v-model="empresa.bpo_num_empresas" 
                                    required 
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="12" sm="12" lg="12">

                            <b-row>
                                <b-col md="12" sm="12" lg="12">
                                    <h5><strong>Empresas cliente deste BPO</strong></h5>
                                </b-col>
                            </b-row>
    
                            <b-row>
                                <b-col md="5" sm="12" lg="5">
                                    <vue-single-select
                                        id="cliente-name-input"                            
                                        placeholder="Procurar"
                                        v-model="empresaSelecionada"
                                        :options="empresasBPO"
                                        option-label="text"
                                        option-key="value"
                                        :get-option-description="label"
                                        :get-option-value="value"
                                    ></vue-single-select>
                                </b-col>
    
                                <b-col md="4" sm="12" lg="4">
                                    <b-button class="ml-2" size="sm" variant="outline-primary" @click="addEmpresaBPO">
                                        <i class="fa fa-plus mr-1"></i> Adicionar
                                    </b-button>
                                </b-col>
                            </b-row>
    
                            <b-row>
                                <b-col md="12" sm="12" lg="12">                                
                                    <b-table v-if="empresa.empresasBPO.length > 0" class="mt-2" hover striped :items="empresa.empresasBPO" :fields="fieldsEmpresasBPO" borderless small>  
                                        <template v-slot:cell(actions)="data">
                                            <b-button size="sm" variant="danger" @click="desejaRemoverCliBPO(data.index)">
                                                <i class="fa fa-trash"></i>
                                            </b-button>
                                        </template>
                                    </b-table>
                                    <h6 v-else>Nenhum cliente adicionado...</h6>
                                </b-col>

                                empresa.empresasBPO {{ empresa.empresasBPO }}
                            </b-row>
                        </b-col>


                    </b-row>


                </b-card>

                <br>
                <b-row>
                    <b-col md="12" sm="12" class="d-flex justify-content-end">                        

                        <i id="popover-target-1"  class="fa fa-exclamation-circle info-alteracoes"></i>

                        <b-popover target="popover-target-1" triggers="hover" placement="top">
                            <template #title>Alterações</template>
                            <div v-if="empresa.usu_cad">
                                <b>Usuário Cadastro:</b> {{empresa.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{empresa.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{empresa.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{empresa.data_alt}}
                            </div>
                            <div v-else>
                                <p>Informações não disponíveis!</p>
                            </div>
                        </b-popover>                        
                    </b-col>
                </b-row>
            </b-form>
        </b-card> 

        <b-card v-show="!show" style="margin-top: 10px">
                <div class="acoesRodape">                 
                <b-button variant="secondary " class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="reset">
                        <i class="fa fa-times button-icons"></i>
                        Cancelar
                </b-button>
                <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'"
                    @click="save">
                        <i class="fa fa-check button-icons"></i>
                        Salvar
                </b-button>            
                <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'"
                    @click="novaEmpresa">
                        <i class="fa fa-plus button-icons"></i>
                        Novo
                </b-button>   
            </div>
        </b-card>      

        <b-card class="cadastros-card-corpo" v-show="show">
            <b-table hover striped :items="empresas" :fields="fields">  
                <template v-slot:cell(actions)="data">
                    <b-button variant="warning" @click="loadEmpresa(data.item)" class="mr-2">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                </template>
            </b-table>
            <hr>
            <b-pagination align="center" size="md" v-model="page"  :total-rows="count" :per-page="limit" first-number last-number></b-pagination>
        </b-card>  

        <b-modal id="modal-exclui-cli-bpo" title="Excluir Contratos" v-model="modalExcluirClienteBPO">
            <p >
                Deseja realmente <strong>Excluir</strong> o Cliente do BPO selecionado? <br>
            </p>
            <template #modal-footer>
                <div class="w-100">                    
                    <b-button
                        variant="primary"
                        size="md"
                        class="float-right ml-3"
                        @click="removeCliBPO()"                            
                    >
                        Sim
                    </b-button>
                    <b-button
                        variant="danger"
                        size="md"
                        class="float-right"
                        @click="$bvModal.hide('modal-exclui-cli-bpo')"
                    >
                        Não
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import Autocomplete from '@/components/autocomplete/Autocomplete.vue'
const moment = require('moment')
import axios from 'axios'
import VueSingleSelect from "vue-single-select";

export default {
    name: 'CadastroProdutos',
    components: { Autocomplete, VueSingleSelect },
    data: function() {
        return {
            teste:{},

            empresasBPO: [],
            empresaSelecionada: '',
            modalExcluirClienteBPO: false,

            edit: false,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                //suffix: ' #',
                precision: 2,
                masked: false
            },
            mascaras: {
                masked: true,
                cpf: '###.###.###-##',
                cnpj: '##.###.###/####-##',
                telefone: '(##) ####-####',
                celular: '(##) # ####-####',
                cep: '#####-###'
            },
            mode: 'new',
            empresa: {
                cpf: '',
                cnpj: '',
                tipo: 'F',            
                ativo: 'S',
                e_bpo: 'N',
                e_licenca_ativa: 'S',
                bpo_num_empresas: 0,
                empresasBPO: []
            },
            empresas: [],
            cidades: [],
            filial:{
                idcidade: '',
            },
            usuario: {
                nome: '',
                email: ''
            },
            page: 1,
            count: 0,
            limit: 0,
            parcial: '',
            ativos: 'S',
            show: true,
            fields: [
                {key: 'idempresa', label: 'Código', sortable: true},
                {key: 'nome_empresa', label: 'Empresa', sortable: true},
                {key: 'cnpj_cpf_principal', label: 'CPF/CNPJ', sortable: true},
                {key: 'tipo', label: 'Tipo', sortable: false, formatter: value => value ==='F' ? 'Física' : 'Jurídica'},
                {key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value ==='S' ? 'Sim' : 'Não'},
                {key: 'actions', label: 'Ações'}
            ],
            fieldsEmpresasBPO: [
                {key: 'razao_social', label: 'Empresa', sortable: true},
                {key: 'e_aceito_cliente', label: 'Aceito pelo Cliente', sortable: true},
                {key: 'actions', label: 'Ações'}
            ],
            optionsTipo: [
                { value: 'F', text: 'Pessoa Física' },
                { value: 'J', text: 'Pessoa Jurídica'}
            ]       
        }
    }, 
    methods: {
        desejaRemoverCliBPO(i){
            this.index = i
            this.modalExcluirClienteBPO = true
        },
        removeCliBPO() {            
            this.empresa.empresasBPO.splice(this.index, 1)
            this.modalExcluirClienteBPO = false
            this.empresaSelecionada = ''
        },
        addEmpresaBPO() {
            if (!(this.empresaSelecionada.value > 0)) {
                this.$toasted.error('Selecione uma empresa.') 
            } else {
                this.empresa.empresasBPO.push({
                    id_filial_cliente_bpo: this.empresaSelecionada.value,
                    razao_social: this.empresaSelecionada.razao_social,
                    e_aceito_cliente: 'N'
                }) 
                
                this.empresaSelecionada = ''
            }
        },
        loadEmpresasBPO() {             
            axios.get(`${baseApiUrl}/filiaisbpo`, {
                params: {
                    idEmpresa: this.empresa.idempresa
                }
            }).then(res => {
                this.empresasBPO = res.data.map(emp => {
                    return { ...emp, value: emp.idfilial, text: ` | ${emp.razao_social} (${emp.cnpj_cpf})` }
                })                
            }) 
            .catch(showError)           
        },
        novaEmpresa() {
            this.reset()
            this.loadEmpresasBPO()
            this.mode = 'save'
        },
        loadCidades() {  
            const url = `${baseApiUrl}/cidades`

            axios.get(url).then(res => {
                this.cidades = res.data
            })
        },  
        loadCep(cep) {
            let str = cep.replace("-", "").replace(".", "");

            if (str.length === 8) {
                axios.get(`https://viacep.com.br/ws/${str}/json/`)
                    .then(res => {
                        this.teste = res.data
                        this.filial.endereco = res.data.logradouro
                        this.filial.bairro = res.data.bairro
                        this.filial.complemento = res.data.complemento
                        
                        //Localizar a cidade
                        const url = `${baseApiUrl}/cidades/estado/${res.data.uf}/?nome=${res.data.localidade}`    

                        axios.get(url).then(resp => {
                            this.filial.id_cidade = resp.data.id_cidade
                            this.filial.idcidade = resp.data.nome
                        }).catch(err => {
                            showError(err)
                        })
                    })
                    .catch(error => {
                        showError(error)
                    })
            }
        },      
        onSelect(cidade) {
            this.filial.id_cidade = cidade.id_cidade
        },
        limpaCpfCnpj() {
            this.empresa.cnpj_cpf_principal = ''
        },
        upperCase(value) {
            return value.toUpperCase()
        },
        lowerCase(value) {
            return value.toLowerCase()
        },
        novaConsulta() {
            this.page = 1 
            this.loadEmpresas()
        },
        loadEmpresas() {  
            const url = `${baseApiUrl}/consulta/cadastros/?page=${this.page}&ativos=${this.ativos}&parcial=${this.parcial}&tipo=cadempresa`            

            axios.get(url).then(res => {
                this.empresas = res.data.data
                this.count = res.data.count 
                this.limit = res.data.limit
            })
            .catch(showError)           
        },
        reset() {
            this.mode = 'new'

            this.empresa = {
                tipo: 'F',  
                cpf: '',
                cnpj: '',              
                ativo: 'S',
                e_bpo: 'N',
                e_licenca_ativa: 'S',
                bpo_num_empresas: 0,
                empresasBPO: []
            }

            this.empresaSelecionada = ''

            this.filial = {
                id_cidade: '',
                idcidade: '',
                tipo_fj: 'F'
            }
            this.usuario = {
                nome: '',
                email: ''
            }
            
            this.loadEmpresas()
            this.show = !this.show
            this.edit = false
        },
        save() {
            const method = this.empresa.idempresa ? 'put' : 'post'
            const idEmp = this.empresa.idempresa ? `/${this.empresa.idempresa}` : ''
            
            let empresa = { ...this.empresa }
            let usuario = { ...this.usuario }
            let filial = { ...this.filial }

            if(this.empresa.tipo == 'F') {
                empresa.cnpj_cpf_principal = empresa.cpf
                filial.cnpj_cpf = empresa.cpf
            } else {
                empresa.cnpj_cpf_principal = empresa.cnpj
                filial.cnpj_cpf = empresa.cnpj
            }

            filial.tipo_fj = empresa.tipo
            
            let cadEmpresa = {
                empresa: empresa,
                filial: filial,
                usuario: usuario
            }
            
            axios[method](`${baseApiUrl}/empresas${idEmp}`, cadEmpresa)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
            })
            .catch(showError)
        },

        loadEmpresa(empresa, mode = 'save') {
            const url = `${baseApiUrl}/empresas/${empresa.idempresa}`    

            axios.get(url).then(res => {
                this.loadEmpresasBPO()
                this.mode = mode
                this.empresa = { ...res.data }
                this.empresa.tipo == 'F' ? this.empresa.cpf = empresa.cnpj_cpf_principal : this.empresa.cnpj = empresa.cnpj_cpf_principal
                this.empresa.data_cad = moment(empresa.data_cad).format('DD/MM/YYYY HH:mm:ss')
                this.empresa.data_alt = moment(empresa.data_alt).format('DD/MM/YYYY HH:mm:ss')
                this.show = !this.show
                this.edit = true

            }) 
            .catch(showError)            
        }
    },
    watch: {
        page() {
            this.loadEmpresas()
        },
        // empresaSelecionada() {
        //     if (this.empresaSelecionada) {
        //         this.servico.id_empresa_bpo = this.empresaSelecionada.id_empresa_bpo
        //         this.servico.id_filial_cliente_bpo = this.empresaSelecionada.id_filial_cliente_bpo
        //     } else {
        //         this.servico.id_empresa_bpo = ''
        //         this.servico.id_filial_cliente_bpo = ''
        //     }
        // },
    },
    mounted() {
        this.loadEmpresas()
    }
}
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.7rem;
    }  

    .router-link-cad {       
        align-items: center;
        text-decoration: none;
    }

    .router-link-cad i {
        color: rgb(16, 93, 165);
        transition: all 0.4s ease;
        margin-right: 5px;
        font-size: 1.5rem;
    }

    .router-link-cad i:hover {
        color: rgb(6, 55, 100);
        text-decoration: none;
    }

    .router-link-cad .links_name {
        font-family: 'Poppins', sans-serif;  
    }

    .router-link-cad i {
        height: 50px;
        min-width: 50px;
        border-radius: 12px;
        line-height: 50px;  
        text-align: center;      
    }    

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-button-novo {
        width: 100%;
    }

    .cadastro-total-card {
        display: flex;
        align-items: center;
        height: 37px;
        padding: 5px 10px 5px 10px;
        border: 2px solid rgb(29, 175, 90);
        background: rgb(29, 175, 90);
        border-radius: 60px;
        color: cornsilk;
        text-align: center;
        font-family: 'Poppins', sans-serif; 
        line-height: 0.9rem;
        font-size: 0.9rem; 
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .cadastros-opcoes-row-card {       
        padding: 0;   
    }

    .cadastros-opcoes-row {
        margin: -20px 0 -20px -20px;        
        padding: 0 0 0 -10;
    }

    .info-alteracoes {        
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }
    .info-alteracoes:hover {        
        color: rgb(19, 141, 255);
    }

    .radio-consulta {
        padding-top: 15px;
    }

    



</style>