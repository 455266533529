<template>
    <div class="comissoes-pages">
        <PageTitle icon="fa fa-percent" main="Comissões"
            sub="Parâmetros para comissões" />
        
        <CadastroComissoes />   
    </div>
</template>

<script>
import PageTitle from '../template/PageTitle'
import CadastroComissoes from './CadastroComissoes.vue'

export default {
    name: 'ComissoesPages',
    components: { PageTitle, CadastroComissoes }
}
</script>

<style>

</style>
