<template>
    <div class="cadastro-grupos-comissao">

        <b-tabs content-class="mt-3">
            <div class="div-topo-b">
            </div>

            <!-- *********** ABA: Grupo de Comissão *********** -->
            <b-tab title="Grupo de Comissão" active style="padding: 0 20px 0 20px">
                <div class="acoesTopo acoesTopo-bol">             
                    <b-button variant="secondary" class="botoesAcao" v-if="(mode === 'save') || (mode === 'remove')" @click="reset" v-b-tooltip.hover.bottomleft="'Cancelar'">
                        <i class="fa fa-times button-icons"></i>
                    </b-button>
                    <b-button variant="danger" class="botoesAcao" v-if="mode === 'remove'" @click="remove" v-b-tooltip.hover.bottomleft="'Excluir Grupo'">
                        <i class="fa fa-trash button-icons"></i>
                    </b-button>
                    <b-button variant="success" class="botoesAcao" v-if="mode === 'save'" @click="save" v-b-tooltip.hover.bottomleft="'Salvar Grupo'">
                        <i class="fa fa-check button-icons"></i>
                    </b-button>            
                    <b-button variant="primary" class="botoesAcao" v-if="mode === 'new'" @click="novoGrupo" v-b-tooltip.hover.bottomleft="'Novo Grupo'">
                        <i class="fa fa-plus button-icons"></i>
                    </b-button>   
                </div>

                <b-card class="cadastros-card-pesquisa" v-if="show">
                    <b-row align-v="center">
                        <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                            <b-form-input id="localizar" type="search"
                                v-model="parcial"
                                placeholder="Informe parte da descrição..." />                     
                        </b-col> 
                        <b-col md="3" sm="12" class="d-flex justify-content-end">
                            <b-button variant="info" @click="novaConsulta">
                                <i class="fa fa-search button-icons"></i> Consultar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>

                <b-overlay :show="showOver" rounded="sm">
                    <b-form v-show="!show">
                        <input id="grupo-id" type="hidden" v-model="grupo.id_pessoa_comissionamento_grupo" />

                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-group label="Descrição do Grupo de Comissão" label-for="grupo-descricao">
                                    <b-form-input id="grupo-descricao" type="text" v-model="grupo.descricao" required :readonly="mode === 'remove'" placeholder="Informe a descrição do grupo de comissão..." />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="6" sm="12">
                                <b-form-checkbox 
                                    value="S" 
                                    unchecked-value="N" 
                                    id="pessoa-ativo" 
                                    checked 
                                    v-model="grupo.ativo" 
                                    class="mt-3 mb-3" 
                                    switch
                                >Ativo</b-form-checkbox>                               
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col md="12" sm="12" class="d-flex justify-content-end">
                                <i id="popover-target-1" class="fa fa-exclamation-circle info-alteracoes"></i>
                                <b-popover target="popover-target-1" triggers="hover" placement="top">
                                    <template #title>Alterações</template>
                                    <b>Usuário Cadastro:</b> {{grupo.usu_cad}} <br>
                                    <b>Data de Cadastro:</b> {{grupo.data_cad}} <br>
                                    <b>Usuário Alteração:</b> {{grupo.usu_alt}} <br>
                                    <b>Data de Alteração:</b> {{grupo.data_alt}}
                                </b-popover>                        
                            </b-col>
                        </b-row>
                    </b-form>
                </b-overlay> 

                <b-card v-show="!show" style="margin-top: 10px">
                    <div class="acoesRodape">                 
                        <b-button variant="secondary" class="botoesAcaoRodape" v-if="(mode === 'save') || (mode === 'remove')" @click="reset">
                            <i class="fa fa-times button-icons"></i> Cancelar
                        </b-button>
                        <b-button variant="danger" class="botoesAcaoRodape" v-if="mode === 'remove'" @click="remove">
                            <i class="fa fa-trash button-icons"></i> Excluir
                        </b-button>
                        <b-button variant="success" class="botoesAcaoRodape" v-if="mode === 'save'" @click="save">
                            <i class="fa fa-check button-icons"></i> Salvar
                        </b-button>            
                        <b-button variant="primary" class="botoesAcaoRodape" v-if="mode === 'new'" @click="novoGrupo">
                            <i class="fa fa-plus button-icons"></i> Novo
                        </b-button>   
                    </div>
                </b-card>   

                
                <b-table hover striped :items="grupos" :fields="fields" small v-show="show">
                    <template v-slot:cell(actions)="data">
                        <b-button size="sm" variant="warning" @click="loadGrupo(data.item)" class="mr-2">
                            <i class="fa fa-pencil"></i>
                        </b-button>
                        <b-button size="sm" variant="danger" @click="loadGrupo(data.item, 'remove')">
                            <i class="fa fa-trash"></i>
                        </b-button>
                    </template>
                </b-table>
                <hr>
                <b-pagination align="center" size="md" v-model="page" :total-rows="count" :per-page="limit" first-number last-number></b-pagination>   
            </b-tab>

            <!-- *********** ABA: Comissão por Faturamento *********** -->
            <b-tab title="Comissão por Faturamento" active style="padding: 0 20px 0 20px">
                <div class="acoesTopo acoesTopo-bol">             
                    <b-button variant="secondary" class="botoesAcao" v-if="(modeComissao === 'save') || (mode === 'remove')" @click="resetComissao" v-b-tooltip.hover.bottomleft="'Cancelar'">
                        <i class="fa fa-times button-icons"></i>
                    </b-button>
                    <b-button variant="danger" class="botoesAcao" v-if="modeComissao === 'remove'" @click="removeComissao" v-b-tooltip.hover.bottomleft="'Excluir Comissão'">
                        <i class="fa fa-trash button-icons"></i>
                    </b-button>
                    <b-button variant="success" class="botoesAcao" v-if="modeComissao === 'save'" @click="saveComissao" v-b-tooltip.hover.bottomleft="'Salvar Comissão'">
                        <i class="fa fa-check button-icons"></i>
                    </b-button>            
                    <b-button variant="primary" class="botoesAcao" v-if="modeComissao === 'new'" @click="novaComissao" v-b-tooltip.hover.bottomleft="'Nova Comissão'">
                        <i class="fa fa-plus button-icons"></i>
                    </b-button>
                </div>

                <b-card class="cadastros-card-pesquisa" v-if="showComissao">
                    <b-row align-v="center">
                        <b-col md="5" sm="12" class="cadastros-pesquisa-coluna"> 
                            <vue-single-select
                                id="comissao-name-input"                            
                                placeholder="Procurar"
                                v-model="pessoaSelecionada"
                                :options="pessoas"
                                option-label="text"
                                option-key="value"
                                :get-option-description="label"
                                :get-option-value="value"
                            ></vue-single-select>                   
                        </b-col> 
                        <b-col md="3" sm="12" class="d-flex justify-content-end">
                            <b-button variant="info" @click="loadComissoes">
                                <i class="fa fa-search button-icons"></i> Consultar
                            </b-button>
                        </b-col>
                    </b-row>
                </b-card>
                <b-overlay :show="showOverComissao" rounded="sm">
                    <div v-show="showComissao">
                        <b-table hover striped :items="comissoes" :fields="fields" small>
                            <template v-slot:cell(actions)="data">
                                <b-button size="sm" variant="warning" @click="loadComissao(data.item)" class="mr-2">
                                    <i class="fa fa-pencil"></i>
                                </b-button>
                                <b-button size="sm" variant="danger" @click="desejaRemoverComissao(data.item)">
                                    <i class="fa fa-trash"></i>
                                </b-button>
                            </template>
                        </b-table>
                        <hr>
                        <b-pagination  align="center" size="md" v-model="pageComissoes" :total-rows="count" :per-page="limitComissoes" first-number last-number></b-pagination>
                    </div>
                </b-overlay>

                <div v-show="!showComissao">
                    <input id="grupo-idd" type="hidden" v-model="comissao.id_comissao_faturamento" />

                    <b-row>
                        <b-col md="6" sm="12">
                            <b-form-group label="Descrição" label-for="comissao-descricao">
                                <b-form-input id="comissao-descricao" type="text" v-model="comissao.descricao" required :readonly="mode === 'remove'" placeholder="Informe a descrição da comissão..." />
                            </b-form-group>
                        </b-col>
                        <b-col md="6" sm="12">
                            <b-form-group label="Tipo de Faturamento" label-for="comissao-tipo" style="margin-top: 5px">
                                <b-form-checkbox-group
                                    id="comissao-tipo"
                                    v-model="comissao.e_faturamento_individual"
                                    :options="[
                                        { value: 'S', text: 'Individual' },
                                        { value: 'N', text: 'Geral'}
                                    ]"
                                    name="chk-status"
                                ></b-form-checkbox-group> 
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3" sm="12">
                            <b-form-group label="Comissionado" label-for="comissao-comissionado">
                                <vue-single-select
                                    id="comissao-comissionado"                            
                                    placeholder="Procurar"
                                    v-model="comissionadoSelecionado"
                                    :options="pessoas"
                                    option-label="text"
                                    option-key="value"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                ></vue-single-select>   
                            </b-form-group> 
                        </b-col>
                        <b-col md="3" sm="12">
                            <b-form-group label="Filial" label-for="comissao-filial">
                                <vue-single-select
                                    id="comissao-filial"                            
                                    placeholder="Procurar"
                                    v-model="filialSelecionada"
                                    :options="filiais"
                                    option-label="text"
                                    option-key="value"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                ></vue-single-select>   
                            </b-form-group> 
                        </b-col>
                        <b-col md="3" sm="12">
                            <b-form-group label="Grupo de Contrato" label-for="comissao-grupo-contrato">
                                <vue-single-select
                                    id="comissao-grupo-contrato"                            
                                    placeholder="Procurar"
                                    v-model="grupoSelecionado"
                                    :options="gruposContratos"
                                    option-label="text"
                                    option-key="value"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                ></vue-single-select>   
                            </b-form-group> 
                        </b-col>
                        <b-col md="3" sm="12">
                            <b-form-group label="Grupo de Comissão" label-for="comissao-grupo">
                                <vue-single-select
                                    id="comissao-grupo"                            
                                    placeholder="Procurar"
                                    v-model="grupoComissSelecionado"
                                    :options="gruposComissoesFaturamento"
                                    option-label="text"
                                    option-key="value"
                                    :get-option-description="label"
                                    :get-option-value="value"
                                ></vue-single-select>   
                            </b-form-group> 
                        </b-col>
                    </b-row>  

                    <b-row>
                        <b-col md="4" sm="12">
                            <b-form-checkbox 
                                value="S" 
                                unchecked-value="N" 
                                id="grupo-ativo-comissao" 
                                checked 
                                v-model="comissao.ativo" 
                                class="mt-5 mb-3" switch
                            >Ativo</b-form-checkbox>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-checkbox 
                                value="S" 
                                unchecked-value="N" 
                                id="grupo-ativo-comissao" 
                                checked 
                                v-model="comissao.e_pagto_recebimento" 
                                class="mt-5 mb-3" switch
                            >Pagar comissão no recebimento</b-form-checkbox>
                        </b-col>

                        <b-col md="4" sm="12">
                            <b-form-checkbox 
                                value="S" 
                                unchecked-value="N" 
                                id="grupo-ativo-comissao" 
                                checked 
                                v-model="comissao.e_base_comiss_personalizada" 
                                class="mt-5 mb-3" switch
                            >Considerar Valor Base das Comissões</b-form-checkbox>
                        </b-col>
                    </b-row> 

                    <b-row>
                        <b-col md="8" sm="12">
                            <h5><strong>Faixas de Faturamento</strong></h5>

                            <b-row class="cadastros-opcoes-row pl-3" align-h="around">
                                <b-col md="12" sm="12" style="display: flex; flex-wrap: wrap;">
                                    <b-form-group class="mr-2" label="Valor" label-for="add-valor-faixa" style="width: 33%">
                                        <money id="add-valor-faixal" v-model="faixa.faturamento_valor" v-bind="moneyItens" class="form-control"/>   
                                    </b-form-group>

                                    <b-form-group class="mr-2" label="% Comissão" label-for="add-valor-faixa" style="width: 20%">
                                        <money id="add-valor-faixal" v-model="faixa.porc_comissao" v-bind="percent" class="form-control"/>   
                                    </b-form-group>
                                    
                                    <b-button class="ml-2" size="sm" variant="outline-primary" @click="addFaixa" style="height: 38px; margin-top: 32px;">
                                        <i class="fa fa-plus mr-1"></i> Adicionar
                                    </b-button>
                                    
                                </b-col>                          
                            </b-row>

                            <b-row class="cadastros-opcoes-row pl-3">                             
                                <b-col md="8" sm="12">
                                    <b-table 
                                        v-if="comissao.faixas.length > 0" 
                                        class="mt-2" 
                                        hover striped 
                                        :items="comissao.faixas" 
                                        :fields="fieldsComissoesFaixas" 
                                        borderless 
                                        small
                                        sort-icon-left
                                    >  
                                        <template v-slot:cell(actions)="data">
                                            <div class="d-flex justify-content-end mr-2" >
                                                <i class="fa fa-times botao-excluir" @click="removerFaixa(data.item)"></i>
                                            </div>
                                        </template>
                                    </b-table>
                                    <h6 v-else>Nenhuma comissão adicionada...</h6>
                                </b-col>                          
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col md="12" sm="12" class="d-flex justify-content-end">
                            <i id="popover-target-2" class="fa fa-exclamation-circle info-alteracoes"></i>
                            <b-popover target="popover-target-2" triggers="hover" placement="top">
                                <template #title>Alterações</template>
                                <b>Usuário Cadastro:</b> {{grupo.usu_cad}} <br>
                                <b>Data de Cadastro:</b> {{grupo.data_cad}} <br>
                                <b>Usuário Alteração:</b> {{grupo.usu_alt}} <br>
                                <b>Data de Alteração:</b> {{grupo.data_alt}}
                            </b-popover>                        
                        </b-col>
                    </b-row>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { baseApiUrl, showError } from '@/global'
import VueSingleSelect from "vue-single-select";
import { VMoney } from 'v-money'
import axios from 'axios'

export default {
    name: 'CadastroGruposComissao',
    components: {
        VueSingleSelect
    },
    data() {
        return {
            idPessoa: '',

            //Comissão
            pessoaSelecionada: '',
            grupoComissSelecionado: '',
            comissionadoSelecionado: '',
            grupoSelecionado: '',
            filialSelecionada: '',
            
            pessoas: [],
            gruposContratos: [],
            filiais: [],
            comissoes: [],
            gruposComissoesFaturamento: [],

            comissao: {
                id_comissao_faturamento: '',
                id_pessoa: '',
                id_filial: '',
                id_contrato_grupo: '',
                faturamento_valor: 0,
                porc_comissao: 0,
                ativo: 'S',
                e_pagto_recebimento: 'N',
                e_faturamento_individual: 'S',
                faixas: []
            },
            faixa: {
                faturamento_valor: 0,
                porc_comissao: 0
            },
            
            showComissao: true,
            showOverComissao: false,

            pageComissoes: 1,
            limitComissoes: 0,
            countComissoes: 0,

            moneyItens: {
                prefix: 'R$ ',
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false
            },
            percent: {
                decimal: ',',
                thousands: '.',                
                suffix: ' %',
                precision: 2,
                masked: false
            },
            
            //Grupo de Comissão
            mode: 'new',
            modeComissao: 'new',
            grupo: {
                id_pessoa_comissionamento_grupo: '',
                descricao: '',
                ativo: 'S',
            },
            grupos: [],
            page: 1,            
            count: 0,
            limit: 0,
            parcial: '',
            show: true,
            showOver: false,
            fields: [
                { key: 'descricao_comissao', label: 'Descrição', sortable: true },
                { key: 'ativo', label: 'Ativo', sortable: false, formatter: value => value === 'S' ? 'Sim' : 'Não' },
                { key: 'actions', label: '' }
            ],
            fieldsComissoesFaixas: [
                {key: 'faturamento_valor', label: 'Valor', sortable: true, formatter: value => { 
                        return value.toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits: 2, currency: 'BRL'})                        
                    }
                },
                {key: 'porc_comissao', label: '% Comissão', sortable: true, formatter: value => { 
                        return value.toLocaleString('pt-br', {maximumFractionDigits: 2})+' %'                        
                    }
                },
                { key: 'actions', label: '', sortable: false }
            ]
        }
    },
    directives: {
        money: VMoney
    },
    methods: {
        // Grupos de Comissão
        async loadGruposComissoes() {
            const url = `${baseApiUrl}/comissoesgrupos`

            await axios.get(url).then(res => {
                this.gruposComissoesFaturamento = res.data.map(grupo => {
                    return { ...grupo, value: grupo.id_pessoa_comissionamento_grupo, text: `| ${grupo.descricao}` }
                })
            })
        },
        novoGrupo() {
            this.reset()
            this.mode = 'save'
        },
        novaConsulta() {
            this.page = 1
            this.loadGrupos()
        },
        loadGrupos() {
            const url = `${baseApiUrl}/comissoesgruposconsulta/?page=${this.page}&parcial=${this.parcial}`

            axios.get(url).then(res => {
                this.grupos = res.data.data
                this.count = res.data.count
                this.limit = res.data.limit
            }).catch(showError)
        },
        reset() {
            this.mode = 'new'
            this.grupo = {
                id_pessoa_comissionamento_grupo: '',
                descricao: '',
                ativo: 'S'
            }
            
            this.show = !this.show
        },
        save() {
            const method = this.grupo.id_pessoa_comissionamento_grupo ? 'put' : 'post'
            const idgrupo = this.grupo.id_pessoa_comissionamento_grupo ? `/${this.grupo.id_pessoa_comissionamento_grupo}` : ''

            axios[method](`${baseApiUrl}/comissoesgrupos${idgrupo}`, this.grupo)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.reset()
                this.loadGrupos()
            })
            .catch(showError)
        },
        remove() {
            const idgrupo = this.grupo.id_pessoa_comissionamento_grupo
            axios.delete(`${baseApiUrl}/comissoesgrupos/${idgrupo}`)
                .then(() => {
                    this.$toasted.global.defaultSuccess()
                    this.reset()
                    this.loadGrupos()
                })
                .catch(showError)
        },
        loadGrupo(grupo, mode = 'save') {
            this.show = !this.show
            this.showOver = !this.showOver

            const url = `${baseApiUrl}/comissoesgrupos/${grupo.id_pessoa_comissionamento_grupo}`

            axios.get(url).then(res => {
                this.showOver = !this.showOver
                this.mode = mode
                this.grupo = { ...res.data }
            }).catch(err => {
                showError(err)
                this.show = !this.show
                this.showOver = !this.showOver
            })
        },
        
        // Comissão Faturamento
        addFaixa() {
            if (!this.faixa.faturamento_valor > 0) return this.$toasted.error('O valor da faixa deve ser maior que zero.')
            if (!this.faixa.porc_comissao > 0) return this.$toasted.error('A porcentagem da faixa deve ser maior que zero.')
            this.comissao.faixas.push({ ...this.faixa })
            this.faixa = {
                faturamento_valor: 0,
                porc_comissao: 0
            }
        },
        removerFaixa(faixa) {
            this.comissao.faixas = this.comissao.faixas.filter(f => f !== faixa)
        },
        novaComissao() {
            this.loadPessoas() 
            this.loadContratoGrupos()
            this.loadGruposComissoes()
            this.loadFiliais()
            this.resetComissao()
            this.comissionadoSelecionado = ''
            this.filialSelecionada = ''
            this.grupoSelecionado = ''
            this.modeComissao = 'save'
            this.showComissao = !this.showComissao
        },
        resetComissao() {
            this.modeComissao = 'new'
            this.comissao = {
                id_comissao_faturamento: '',
                id_pessoa: '',
                id_filial: '',
                id_contrato_grupo: '',
                faturamento_valor: 0,
                porc_comissao: 0,
                ativo: 'S',
                e_pagto_recebimento: 'N',
                e_faturamento_individual: 'S',
                faixas: []
            }
            this.showComissao = true
            this.pessoaSelecionada = ''
            this.grupoComissSelecionado = ''
            this.comissionadoSelecionado = ''
            this.filialSelecionada = ''
            this.grupoSelecionado = ''
        },
        saveComissao() {
            const method = this.comissao.id_comissao_faturamento ? 'put' : 'post'
            const idcomissao = this.comissao.id_comissao_faturamento ? `/${this.comissao.id_comissao_faturamento}` : ''

            axios[method](`${baseApiUrl}/comissoesfaturamento${idcomissao}`, this.comissao)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.resetComissao()
                this.loadComissoes()
            }).catch(err => {
                showError(err)
            })
        },
        loadComissao(comissao) {            
            this.showOverComissao = true
            const url = `${baseApiUrl}/comissoesfaturamento/${comissao.id_comissao_faturamento}`
            axios.get(url).then(async res => { 
                await this.loadPessoas() 
                await this.loadContratoGrupos()
                await this.loadGruposComissoes()
                await this.loadFiliais()

                this.comissao = { ...res.data }          
                this.comissao.id_pessoa ? this.comissionadoSelecionado = {value: this.comissao.id_pessoa, text: `| ${this.comissao.comissionado}`} : ''
                this.comissao.id_filial ? this.filialSelecionada = {value: this.comissao.id_filial, text: `| ${this.comissao.filial}`} : ''
                this.comissao.id_contrato_grupo ? this.grupoSelecionado = {value: this.comissao.id_contrato_grupo, text: `| ${this.comissao.contrato_grupo}`} : ''
                this.comissao.id_pessoa_comissionamento_grupo ? this.grupoComissSelecionado = {value: this.comissao.id_pessoa_comissionamento_grupo, text: `| ${this.comissao.comissao_grupo}`} : ''            
                
                this.showOverComissao = false
                this.showComissao = false
                this.modeComissao = 'save'
            }).catch(err => {
                alert('chegou 003')
                showError(err)
                this.showOverComissao = false
            })
        },
        desejaRemoverComissao(comissao) {
            this.$bvModal.msgBoxConfirm('Deseja realmente excluir a comissão?', {
                title: 'Excluir Comissão',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'Sim',
                cancelTitle: 'Não',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
            .then(value => {
                if (value) {
                    this.removeComissao(comissao.id_comissao_faturamento)
                }
            })
        },
        removeComissao(id) {
            axios.delete(`${baseApiUrl}/comissoesfaturamento/${id}`)
            .then(() => {
                this.$toasted.global.defaultSuccess()
                this.loadComissoes()
            }).catch(err => {
                showError(err)
            })
        },        
        loadComissoes() {
            this.pageComissoes = 1
            const url = `${baseApiUrl}/comissfaturconsulta/?idPessoa=${this.idPessoa}&page=${this.pageComissoes}`

            axios.get(url).then(res => {
                this.comissoes = res.data.data
                this.countComissoes = res.data.count
                this.limitComissoes = res.data.limit
            }).catch(err => {
                showError(err)
            })
        },
        
        // Outros
        async loadContratoGrupos() {
            const url = `${baseApiUrl}/contratosgrupos`

            await axios.get(url).then(res => {
                this.gruposContratos = res.data.map(grupo => {
                    return { ...grupo, value: grupo.id_contrato_grupo, text: `| ${grupo.descricao}` }
                })
            })
        },
        async loadFiliais() {
            const url = `${baseApiUrl}/filiais`

            await axios.get(url).then(res => {
                this.filiais = res.data.map(filial => {
                    return { ...filial, value: filial.idfilial, text: `| ${filial.fantasia}` }
                })
            })
        },
        async loadPessoas() {  
            const url = `${baseApiUrl}/pessoas`

            await axios.get(url).then(res => {
                this.pessoas = res.data.map(pessoa => {
                    return { ...pessoa, value: pessoa.idpessoa, text: `| ${pessoa.razao_social}` }
                })
            })
        }, 
    },
    watch: {
        page() {
            this.loadGrupos()
        },
        pageComissoes() {
            this.loadComissoes()
        },
        pessoaSelecionada() {
            if (this.pessoaSelecionada) {
                this.idPessoa = this.pessoaSelecionada.value
            } else {
                this.idPessoa = ''
            }
        },
        comissionadoSelecionado() {
            if (this.comissionadoSelecionado) {
                this.comissao.id_pessoa = this.comissionadoSelecionado.value
            } else {
                this.comissao.id_pessoa = ''
            }
        },
        filialSelecionada() {
            if (this.filialSelecionada) {
                this.comissao.id_filial = this.filialSelecionada.value
            } else {
                this.comissao.id_filial = ''
            }
        },
        grupoSelecionado() {
            if (this.grupoSelecionado) {
                this.comissao.id_contrato_grupo = this.grupoSelecionado.value
            } else {
                this.comissao.id_contrato_grupo = ''
            }
        },
        grupoComissSelecionado() {
            if (this.grupoComissSelecionado) {
                this.comissao.id_pessoa_comissionamento_grupo = this.grupoComissSelecionado.value
            } else {
                this.comissao.id_pessoa_comissionamento_grupo = ''
            }
        }
    },
    mounted() {
        this.loadGrupos()
        this.loadPessoas()
    }
}
</script>

<style scoped>
    .cadastros-title h1 {
        display: inline-flex;
        align-items: center;
        margin: 0px;
        font-size: 1.9rem;
    }

    .cadastros-card-pesquisa {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        padding: 0 10px 0 10px;
    }

    .cadastros-pesquisa-coluna {
        margin: 4px 0 4px 0;
    }

    .cadastros-card-corpo {
        margin-top: 10px;
        padding: 0 10px 0 10px;
    }

    .info-alteracoes {
        color: rgb(170, 170, 170);
        font-size: 2rem;
        transition: all 0.4s ease;
    }

    .info-alteracoes:hover {
        color: rgb(19, 141, 236);
    }

    .acoesTopo-bol {
        margin-top: -80px !important;
        margin-bottom: 80px !important;
    }

    .botao-excluir {
        color: #f93b69 !important;
        cursor: pointer;
        font-size: 2rem; 
    }

    .botao-excluir:hover {
        color: #d6345d !important;
    }
</style>
