import Vue from 'vue'
import VueRouter from 'vue-router'


import Home from '@/components/home/Home'

// Facebook Login
// import EditAccount from '@/components/home/EditAccount'
// import Login from '@/components/home/facebookLogin/Login'
// import { authGuard } from '../components/facebook/_helpers';

import AdminPages from '@/components/admin/AdminPages'
import ConsultasPages from '@/components/consultas/ConsultasPages'
import ProdutosPages from '@/components/produtos/ProdutosPages'
import ServicosPages from '@/components/servicos/ServicosPages'
import FunilDeVendasPages from '@/components/funilDeVendas/FunilDeVendasPages'
import ModelosPages from '@/components/modelosRelatorios/ModelosPages'
import NotasFiscaisPages from '@/components/notasFiscais/NotasFiscaisPages'
import FluxoCaixaPages from '@/components/fluxoCaixa/FluxoCaixaPages'
import ProjecaoFinanceiraPages from '@/components/projecaoFinanceira/ProjecaoFinanceiraPages'
import DrePages from '@/components/dre/DrePages'
import TributacoesPages from '@/components/tributacoes/TributacoesPages'
import UnidadesPages from '@/components/produtos/unidades/UnidadesPages'
import GruposFiscaisPages from '@/components/produtos/gruposFiscais/GruposFiscaisPages'
import PessoasPages from '@/components/pessoas/PessoasPages'
import VendasPages from '@/components/vendas/VendasPages'
import NotasEmitidasPages from '@/components/notasEmitidas/NotasEmitidasPages'
import ConsultoriasPages from '@/components/consultorias/ConsultoriaPages'
import OrcamentosPages from '@/components/orcamentos/OrcamentosPages'
import ContratosPages from '@/components/contratos/contratos/ContratosPages'
import GerarFaturasPages from '@/components/contratos/gerarFaturas/GerarFaturasPages'
import GerarNotasPages from '@/components/contratos/gerarNotas/GerarNotasPages'
import Auth from '@/components/auth/Auth'
import BancosPages from '@/components/financeiro/bancos/BancosPages'
import BoletosPages from '@/components/financeiro/boletos/BoletosPages'
import ConciliacaoBancariaPages from '@/components/financeiro/conciliacaoBancaria/ConciliacaoBancariaPages'
import ContasBancariasPages from '@/components/financeiro/contasBancarias/ContasBancariasPages'
import PlanoContasPages from '@/components/financeiro/planoDeContas/PlanoContasPages'
import FormasCondicoesPages from '@/components/financeiro/formasCondicoes/FormasCondicoesPages'
import FaturasReceberPages from '@/components/financeiro/faturasReceber/FaturasReceberPages'
import FinanceiroPages from '@/components/financeiro/FinanceiroPages'
import CidadesPages from '@/components/pessoas/cidades/CidadesPages'
import CidadesRelatorio from '@/components/pessoas/cidades/CidadesRelatorio'
import GruposPages from '@/components/produtos/grupos/GruposPages'
import LancamentosPages from '@/components/lancamentos/LancamentosPages'
import FaturasPagarPages from '@/components/financeiro/faturasPagar/FaturasPagarPages'
import EstadosPages from '@/components/pessoas/estados/EstadosPages'
import PaisesPages from '@/components/pessoas/paises/PaisesPages'
import CaixaPages from '@/components/financeiro/caixa/CaixaPages'
import CartoesPages from '@/components/financeiro/cartoes/CartoesPages'
import EmpresasPages from '@/components/empresas/EmpresasPages'
import ConfigPages from '@/components/configuracoes/ConfigPages'
import FerramentasPages from '@/components/ferramentas/FerramentasPages'
import LembretesPages from '@/components/lembretes/LembretesPages'
import LogsPages from '@/components/logs/LogsPages'
import ComissoesPages from '@/components/comissoes/ComissoesPages'

import { userKey } from '@/global'

Vue.use(VueRouter)

const routes = [{
    name: 'home',
    path: '/',
    component: Home,
    //beforeEnter: authGuard
}, 
// { 
//     name: 'editAccount',
//     path: '/edit/:id', 
//     component: EditAccount, 
//     beforeEnter: authGuard 
// },
// { 
//     Name: 'login',
//     path: '/login', 
//     component: Login 
// },
{
    name: 'adminPages',
    path: '/admin',
    component: AdminPages,
    meta: { requiresSuperv: true }
}, 
{
    name: 'consultasPages',
    path: '/consultas',
    component: ConsultasPages,
    //meta: { requiresSuperv: true }
}, 
{
    name: 'configPages',
    path: '/config',
    component: ConfigPages,
}, 
{
    name: 'ferramentasPages',
    path: '/ferramentas',
    component: FerramentasPages,
}, 
{
    name: 'lembretesPages',
    path: '/lembretes',
    component: LembretesPages,
}, 
{
    name: 'logsPages',
    path: '/logs',
    component: LogsPages,
}, 
{
    name: 'comissoesPages',
    path: '/pessoas/comissoes',
    component: ComissoesPages,
}, 
{
    name: 'empresasPages',
    path: '/empresas',
    component: EmpresasPages,
    meta: { requiresAdmin: true }
}, {
    name: 'produtosPages',
    path: '/produtos',
    component: ProdutosPages,
    //meta: { requiresAdmin: true }/produtos/grupos
}, {
    name: 'servicosPages',
    path: '/servicos',
    component: ServicosPages,
    //meta: { requiresAdmin: true }/produtos/grupos
}, {
    name: 'funilDeVendasPages',
    path: '/funil',
    component: FunilDeVendasPages,
    //meta: { requiresAdmin: true }/produtos/grupos
}, {
    name: 'modelosPages',
    path: '/modelos',
    component: ModelosPages,
}, {
    name: 'notasFiscaisPages',
    path: '/notasfiscais',
    component: NotasFiscaisPages,
}, {
    name: 'fluxoCaixaPages',
    path: '/fluxodecaixa',
    component: FluxoCaixaPages,
}, {
    name: 'projecaoFinanceiraPages',
    path: '/projecao',
    component: ProjecaoFinanceiraPages,
}, {
    name: 'drePages',
    path: '/dre',
    component: DrePages,
}, {
    name: 'tributacoesPages',
    path: '/tributacoes',
    component: TributacoesPages,
}, {
    name: 'gruposPages',
    path: '/produtos/grupos',
    component: GruposPages,
    //meta: { requiresAdmin: true }/produtos/grupos
}, {
    name: 'pessoasPages',
    path: '/pessoas',
    component: PessoasPages,
    //meta: { requiresAdmin: true }
}, {
    name: 'vendasPages',
    path: '/vendas',
    component: VendasPages,
    //meta: { requiresAdmin: true }
}, {
    name: 'notasEmitidasPages',
    path: '/notasEmitidas',
    component: NotasEmitidasPages,
}, {
    name: 'consultoriasPages',
    path: '/projetos',
    component: ConsultoriasPages,
}, {
    name: 'contratosPages',
    path: '/contratos/contratos',
    component: ContratosPages,
    //meta: { requiresAdmin: true }
}, {
    name: 'orcamentosPages',
    path: '/orcamentos',
    component: OrcamentosPages,
    //meta: { requiresAdmin: true }
}, {
    name: 'gerarFaturasPages',
    path: '/contratos/gerarFaturas',
    component: GerarFaturasPages,
    //meta: { requiresAdmin: true }
}, {
    name: 'gerarNotasPages',
    path: '/contratos/gerarNotas',
    component: GerarNotasPages,
    //meta: { requiresAdmin: true }
}, {
    name: 'auth',
    path: '/auth',
    component: Auth
}, {
    name: 'bancosPages',
    path: '/financeiro/bancos',
    component: BancosPages
}, {
    name: 'boletosPages',
    path: '/financeiro/boletos',
    component: BoletosPages
}, {
    name: 'planoContasPages',
    path: '/financeiro/planoDeContas',
    component: PlanoContasPages
}, {
    name: 'formasCondicoesPages',
    path: '/financeiro/formasCondicoes',
    component: FormasCondicoesPages
}, {
    name: 'faturasReceberPages',
    path: '/financeiro/faturasReceber',
    component: FaturasReceberPages
}, {
    name: 'financeiroPages',
    path: '/financeiro',
    component: FinanceiroPages
}, {
    name: 'cidadesPages',
    path: '/pessoas/cidades',
    component: CidadesPages
}, {
    name: 'CidadesRelatorio',
    path: '/pessoas/cidades/relatorio',
    component: CidadesRelatorio
}, {
    name: 'lancamentosPages',
    path: '/lancamentos',
    component: LancamentosPages
}, {
    name: 'faturasPagarPages',
    path: '/financeiro/faturasPagar',
    component: FaturasPagarPages
}, {
    name: 'estadosPages',
    path: '/pessoas/estados',
    component: EstadosPages
}, {
    name: 'paisesPages',
    path: '/pessoas/paises',
    component: PaisesPages
}, {
    name: 'contasBancariasPages',
    path: '/financeiro/contasBancarias',
    component: ContasBancariasPages
}, {
    name: 'conciliacaoBancariaPages',
    path: '/financeiro/conciliacaoBancaria',
    component: ConciliacaoBancariaPages
}, {
    name: 'gruposFiscaisPages',
    path: '/produtos/gruposFiscais',
    component: GruposFiscaisPages
}, {
    name: 'unidadesPages',
    path: '/produtos/unidades',
    component: UnidadesPages
}, {
    name: 'caixaPages',
    path: '/financeiro/caixa',
    component: CaixaPages
}, {
    name: 'cartoes',
    path: '/financeiro/cartoes',
    component: CartoesPages
},
// caso contrário, redirecione pra Home
{ path: '*', redirect: '/' }
]

 const router = new VueRouter({
    mode: 'history',
    //linkActiveClass: 'active',
    routes
}) 

router.beforeEach((to, from, next) => {
    const json = localStorage.getItem(userKey)

    if(to.matched.some(record => record.meta.requiresAdmin)) {
        const user = JSON.parse(json)
        user && user.admin === 'S' ? next() : next({ path: '/' })
    } else {
        if(to.matched.some(record => record.meta.requiresSuperv)) {
            const user = JSON.parse(json)
            user && user.superv === 'S' ? next() : next({ path: '/' })
        } else {
            next()
        }
    }

})

export default router